import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: '12px',
    margin: '16px 0',
  },
  successful: {
    fontSize: '16px',
    lineHeight: '16px;',
    display: 'flex',
    alignItems: 'center',
    color: '#176D38',
    justifyContent: 'flex-end',
  },
  failed: {
    color: '#C9201D',
    fontSize: '16px',
    lineHeight: '16px;',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  statusIcon: {
    fontSize: '16px',
    margin: 6,
  },
  avatar: {
    color: '#212121',
    fontSize: '20px',
    backgroundColor: 'yellow',
    marginRight: 16,
  },
  primaryInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
  },
  secondaryInfo: {
    width: '25%',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
});
