import React from 'react';
import { StackProps } from './interface';
import { StyledChild, StyledContainer } from './styles';

export const Stack = ({
  orientation,
  children,
  spacing,
  fullWidth,
  align,
}: StackProps): JSX.Element | null => {
  return (
    <StyledContainer orientation={orientation} align={align}>
      {React.Children.map(children, (child, index) => {
        const childrenLength = Array.isArray(children) ? children.length : 0;
        const hasOnlyOneChild = childrenLength === 1;
        const isNotLastChild = index !== childrenLength - 1;
        return (
          <StyledChild
            orientation={orientation}
            fullwidth={Array.isArray(fullWidth) && fullWidth.includes(index) ? 1 : 0}
            spacing={!hasOnlyOneChild && isNotLastChild ? spacing : undefined}
          >
            {child}
          </StyledChild>
        );
      })}
    </StyledContainer>
  );
};
