import {
  AllSupportedCountryKeys,
  allSupportedCountries,
  sortCountryListAlphabetically,
} from 'country-utils';

import { IGroup } from 'interfaces/IGroup';
import { IUserMetadata } from 'interfaces/IUserMetadata';
import { useMemo } from 'react';
import { useUserMetadata } from 'admin-portal-shared-services';

/**
 * Accesses the user info metadata to retrieve its groups and
 * check if has global manager group
 * @returns boolean
 */

export function useIsGlobalManager(): boolean {
  const { data } = useUserMetadata();

  return data?.authorization?.scopes?.includes('IdentityMetadata.Authorization.Write') || false;
}

export function useLoggedUser(): IUserMetadata | undefined {
  const { data } = useUserMetadata();
  const loggedUser: unknown = useMemo(() => data, [data]);
  return loggedUser as IUserMetadata;
}

export function useUserGroups(): IGroup[] | undefined {
  const groups = useLoggedUser()?.authorization.groups;
  return useMemo(
    () =>
      groups?.reduce((acc: IGroup[], group: string) => {
        const [name, app] = group.split('.').reverse();
        if (app?.toUpperCase() !== 'TIER') {
          acc.push({ name, app });
        }
        return acc;
      }, []),
    [groups]
  );
}

function filterUnsupportedCountries(countries: string[]): AllSupportedCountryKeys[] {
  const allSupportedCountryCodes = Object.keys(allSupportedCountries);

  return countries.filter((countryCode) =>
    allSupportedCountryCodes.includes(countryCode)
  ) as AllSupportedCountryKeys[];
}

export function useUserSupportedCountries(): string[] | undefined {
  const userSupportedCountries = useLoggedUser()?.supportedCountries || [];

  const supportedCountries = useIsGlobalManager()
    ? (Object.keys(allSupportedCountries) as AllSupportedCountryKeys[])
    : filterUnsupportedCountries(userSupportedCountries);

  const memoSupportedCountries: string[] | undefined = useMemo(
    () => sortCountryListAlphabetically([...new Set([...supportedCountries])]),
    [supportedCountries]
  );
  return memoSupportedCountries;
}
