import styled from 'styled-components';

export const AppName = styled.p`
  color: rgba(0, 0, 0, 0.55);
  font: 400 16px/24px 'Work Sans', sans-serif;
  margin: 32px 32px 32px 114px;
`;

export const Panel = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  margin-top: 24px;
  max-width: 100%;
  padding: 32px 40px 48px 40px;
  margin: 32px auto;
  display: flex;
  flex-direction: column;

  & > label:not(:first-child) {
    margin-top: 24px;
  }
`;

export const Box = styled.div`
  max-width: 632px;
  margin: 0 auto;
`;

export const Title = styled.h1`
  width: 100%;
  color: rgba(0, 0, 0, 0.9);
  font: 600 32px/40px 'Barlow', sans-serif;
  text-align: center;
`;

export const Step = styled.h2`
  height: 24px;
  color: rgba(0, 0, 0, 0.9);
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

export const SubTitle = styled.h2`
  height: 24px;
  color: rgba(0, 0, 0, 0.9);
  font-family: 'Work Sans';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 400;
  margin-top: 8px;
`;

interface IButtonProps {
  dark?: boolean;
}

export const StepButton = styled.button<IButtonProps>`
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 24px;
  background-color: ${(props) => (props.dark ? 'black' : 'white')};
  color: ${(props) => (props.dark ? 'rgba(255,255,255,0.95)' : 'black')};
  font-family: 'Work Sans';
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding: 12px 24px;
  cursor: pointer;
  &:disabled {
    background-color: #f2f2f2;
    border: none;
    color: rgba(0, 0, 0, 0.55);
  }
`;

export const BoxStepButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
`;

export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  right: 32px;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  & svg {
    color: rgba(0, 0, 0, 0.9);
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8;
  }
`;
