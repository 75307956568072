import { useAuthenticationService as authService } from 'admin-portal-shared-services';
import axios from 'axios';
import { v4 } from 'uuid';
import { getToken } from '../../helpers';
import { getApiHost } from '../host/HostService';

export interface IAccount {
  data: {
    name: string;
    accountId: string;
    customerAccountId: string;
  };
}

export class AccountService {
  // eslint-disable-next-line class-methods-use-this
  async getAccount(country: string, customerAccountId: string): Promise<IAccount> {
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const params = {
      accountId: customerAccountId,
      page: 0,
      pageSize: 1,
    };

    const { data } = await authService()
      .enhancedAxios(axios.create())
      .get(`${getApiHost()}/user-mfe/accounts/details`, { headers, params });
    return data;
  }
}
