import { MessageMap } from './types';

export const es419: MessageMap = {
  adminForceInvitationPage_registrationForm_inputEmail_validatingMessage: 'Validando...',
  adminForceInvitationPage_registrationForm_stepper_title: 'Agregar nuevos usuarios de Force',
  adminForceInvitationPage_registrationForm_submitButton_error:
    'Se produjo un error con su solicitud. Por favor, inténtelo de nuevo más tarde.',
  adminForceInvitationPage_registrationForm_textField_managerDoNotExistError:
    'Este usuario administrador no existe',
  adminForceInvitationPage_resultPage_invite_failed: 'Invitación fallida',
  adminForceInvitationPage_resultPage_invite_success: 'Invitado',
  adminForceInvitationPage_resultPage_managedByLinked_failed: 'Gerente no agregado',
  adminForceInvitationPage_resultPage_managedByLinked_success: 'Gerente agregado',
  adminInvitationForcePage_chipsField_firstDescription: 'Vendor ID',
  adminInvitationPage_chipsField_firstDescription: 'IDs de la compañía',
  adminInvitationPage_chipsField_firstDescriptionOptional: 'opcional',
  adminInvitationPage_chipsField_secondDescription: 'Utiliza comas para separar valores.',
  adminInvitationPage_feedbackPage_accountId: 'ID de cuenta',
  adminInvitationPage_feedbackPage_addNewUserButton: 'Agregar nuevo usuario',
  adminInvitationPage_feedbackPage_addedSuccessfuly: 'Usuario agregado con éxito',
  adminInvitationPage_feedbackPage_associatedAccount:
    'Agregamos la cuenta seleccionada al usuario.',
  adminInvitationPage_feedbackPage_finishButton: 'Entendido',
  adminInvitationPage_feedbackPage_invitedUser:
    'Enviamos un e-mail al usuario, invitándolo a registrarse y comenzar a utilizar la plataforma BEES.',
  adminInvitationPage_modalErrorInviteUser_actionButton: 'Volver a intentar',
  adminInvitationPage_modalErrorInviteUser_cancelButton: 'Cancelar',
  adminInvitationPage_modalErrorInviteUser_closeButton: 'Ok',
  adminInvitationPage_registrationForm_backButton: 'Volver',
  adminInvitationPage_registrationForm_bdrId_errorMessage: 'Ingresar un BDR ID válido',
  adminInvitationPage_registrationForm_bdrId_placeholder: 'BDR ID',
  adminInvitationPage_registrationForm_card_title: 'Características del grupo',
  adminInvitationPage_registrationForm_country_inputLabel: 'País',
  adminInvitationPage_registrationForm_country_inputPlaceholder: 'Seleccionar una opción',
  adminInvitationPage_registrationForm_finishButton: 'Enviar',
  adminInvitationPage_registrationForm_inputEmail_errorMessage:
    'Ingresar un e-mail válido (ej: marcus@gmail.com)',
  adminInvitationPage_registrationForm_inputEmail_placeholder: 'Email',
  adminInvitationPage_registrationForm_inputLastName_errorMessage: 'Ingresa un apellido',
  adminInvitationPage_registrationForm_inputLastName_placeholder: 'Apellido',
  adminInvitationPage_registrationForm_inputName_errorMessage: 'Ingresa un nombre',
  adminInvitationPage_registrationForm_inputName_placeholder: 'Nombre',
  adminInvitationPage_registrationForm_managedBy_placeholder: 'Administrado por',
  adminInvitationPage_registrationForm_permissionGroup_inputLabel: 'Permisos de los grupos',
  adminInvitationPage_registrationForm_permissionGroup_inputPlaceholder: 'Seleccionar una opción',
  adminInvitationPage_registrationForm_processingButton: 'Procesando...',
  adminInvitationPage_registrationForm_removeButton: 'Eliminar',
  adminInvitationPage_registrationForm_routeId_inputLabel: 'Route ID',
  adminInvitationPage_registrationForm_routeId_inputPlaceholder: 'Route ID (opcional)',
  adminInvitationPage_registrationForm_secondSectionTitle: 'Personas',
  adminInvitationPage_registrationForm_stepper_subtitle:
    'Ingresa la información abajo para agregar a los nuevos usuarios.',
  adminInvitationPage_registrationForm_stepper_title: 'Agregar nuevos administradores',
  adminInvitationPage_registrationForm_supportedCountries_inputLabel: 'Países habilitados',
  adminInvitationPage_registrationForm_supportedCountries_inputPlaceholder:
    'Seleccionar una opción',
  adminInvitationPage_registrationForm_textField_userAlreadyExistError:
    'Este usuario administrador ya existe.',
  adminInvitationPage_registrationForm_userType_card_title: 'Seleccione tipo de usuario',
  adminInvitationPage_registrationForm_userType_inputLabel: 'Tipo de usuario',
  adminInvitationPage_registrationForm_validateEmail_errorMessage:
    'Ingresar un e-mail válido (ej: marcus@gmail.com)',
  adminInvitationPage_registrationForm_validateRouteId_errorMessage: 'Ingresar un Route ID válido',
  adminInvitationPage_registrationForm_validateTextFields_errorMessageAllowOnlyLetters:
    'Este campo solo acepta letras.',
  adminInvitationPage_registrationForm_vendorId_inputLabel: 'Vendor ID',
  adminInvitationPage_registrationForm_vendorId_inputPlaceholder: 'Vendor ID',
  adminInvitationPage_registrationForm_vendorId_invalidVendorIdError:
    'Ingrese un Vendor ID válido. El Vendor ID debe tener 36 caracteres.',
  adminInvitationPage_registrationForm_vendorId_valueAlreadyExistError:
    'Este vendor ID ya ha sido agregado.',
  adminInvitationPage_resultPage_addNewUserButton: 'Agregar nuevo usuario',
  adminInvitationPage_resultPage_associated: 'Associated',
  adminInvitationPage_resultPage_emailSent:
    'Enviamos un e-mail a cada persona agregada, invitándola a registrarse y comenzar a utilizar las plataformas BEES.',
  adminInvitationPage_resultPage_errorMessage: 'Algunas invitaciones no pudieron enviarse.',
  adminInvitationPage_resultPage_failed: 'Falló',
  adminInvitationPage_resultPage_finishButton: 'Ok, done',
  adminInvitationPage_resultPage_infoSection_companiesTitle: 'IDs de la compañía',
  adminInvitationPage_resultPage_infoSection_countryTitle: 'País',
  adminInvitationPage_resultPage_infoSection_deliveryCenterIdsTitle: 'Delivery center IDs',
  adminInvitationPage_resultPage_infoSection_logisticOperatorIdsTitle: 'Logistic operator IDs',
  adminInvitationPage_resultPage_infoSection_permissionGroupsTitle: 'Permisos de los grupos',
  adminInvitationPage_resultPage_infoSection_supportedCountriesTitle: 'Países habilitados',
  adminInvitationPage_resultPage_invited: 'Invitado',
  adminInvitationPage_resultPage_partial: 'Partial',
  adminInvitationPage_resultPage_successMessage:
    'Los usuarios se agregaron y los e-mails de invitación se han enviado exitosamente.',
  appName: 'BEES User Management',
  customerInvitationPage_backButton: 'Volver',
  customerInvitationPage_emailFromAdminUser: "Admin users can't be invited",
  customerInvitationPage_emailIsRequired: 'Email address is required',
  customerInvitationPage_emailStep_inputLabel: 'Email',
  customerInvitationPage_emailStep_subtitle: 'Primero, ingresar el e-mail del usuario.',
  customerInvitationPage_errorModal_duplicatedInsertError_subtitle:
    "This email is already registered and can't be invited again.",
  customerInvitationPage_errorModal_duplicatedInsertError_title: 'You can’t add this user',
  customerInvitationPage_errorModal_generalError_subtitle:
    'Estamos experimentando una inestabilidad temporaria. Por favor, vuelve a intentarlo.',
  customerInvitationPage_errorModal_generalError_title: 'El usuario no puede ser agregado',
  customerInvitationPage_existUserStep_subtitle:
    'Encontramos un usuario con esta dirección de e-mail. Puedes agregar una cuenta para este usuario.',
  customerInvitationPage_finishButton: 'Enviar',
  customerInvitationPage_invalidEmailFormat: 'Ingresar un e-mail válido (ej: marcus@gmail.com)',
  customerInvitationPage_nameStep_inputLabel: 'Nombre',
  customerInvitationPage_nameStep_inputPlaceholder: 'New User',
  customerInvitationPage_nameStep_subtitle:
    'Ingresa la siguiente información para pre-registrar un nuevo usuario.',
  customerInvitationPage_nextButton: 'Próximo',
  customerInvitationPage_processingButton: 'Procesando...',
  customerInvitationPage_selectedAccount_middleTitle: 'Agregar a',
  customerInvitationPage_selectedAccount_subtitle: 'ID de cuenta {accountId}',
  customerInvitationPage_steps: 'Paso {step} de 2',
  customerInvitationPage_title: 'Agregar nuevo cliente',
  discardChangesModal_confirmButton: 'Sí, cerrar',
  discardChangesModal_keepEditingButton: 'No, continuar editando',
  discardChangesModal_subtitle:
    'Toda la información ingresada se perderá y esta acción no podrá deshacerse.',
  discardChangesModal_title: '¿Estás seguro/a que deseas cerrar?',
  invitationPage_administratorCard_subtitle: 'Manejo de usuarios, productos, rutinas y más.',
  invitationPage_administratorCard_title: 'Administrador',
  invitationPage_step: '1er paso',
  invitationPage_subtitle: 'Selecciona una categoría de usuario para continuar.',
  invitationPage_title: 'Agregar nuevo usuario',
  test_missing: 'Solo la opción en español disponible',
  test_translate: 'Traducción',
};
