import React, { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from '../../../components/Modal/Modal';
import * as Styled from '../../../components/Modal/styles';

interface IModalErrorInviteUser {
  openModal: boolean;
  subtitle: string;
  title: string;
  canAct: boolean;
  handleButtonCloseModal: () => void;
  handleTryAgain(event: MouseEvent<HTMLButtonElement>): void;
  handleCancel(event: MouseEvent<HTMLButtonElement>): void;
}

export function ModalErrorInviteUser({
  openModal,
  handleButtonCloseModal,
  handleTryAgain,
  handleCancel,
  title,
  subtitle,
  canAct,
}: IModalErrorInviteUser): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <Modal
      modalTitle={title}
      isModalOpen={openModal}
      handleButtonCloseModal={handleButtonCloseModal}
    >
      <Styled.ModalSubtitle>{subtitle}</Styled.ModalSubtitle>
      {canAct ? (
        <Styled.ModalFooter>
          <Styled.ModalButton
            type="button"
            variant="outline"
            onClick={handleCancel}
            data-testid="close modal error"
          >
            {formatMessage({ id: 'adminInvitationPage_modalErrorInviteUser_cancelButton' })}
          </Styled.ModalButton>
          <Styled.ModalButton onClick={handleTryAgain}>
            {formatMessage({ id: 'adminInvitationPage_modalErrorInviteUser_actionButton' })}
          </Styled.ModalButton>
        </Styled.ModalFooter>
      ) : (
        <Styled.ModalFooter>
          <Styled.ModalButton onClick={handleCancel}>
            {formatMessage({ id: 'adminInvitationPage_modalErrorInviteUser_closeButton' })}
          </Styled.ModalButton>
        </Styled.ModalFooter>
      )}
    </Modal>
  );
}
