import { X } from '@admin-portal-shared-components/icons';
import { Form, Formik } from 'formik';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AuditLogsCustomerAssociationToAccountParams,
  AuditLogsCustomerInvitationParams,
  AuditLogsUserTypes,
} from 'services/AuditLogs/types';
import * as yup from 'yup';
import { ScreenName1 } from '../../../../analytics';
import { SegmentService } from '../../../helpers';
import {
  postAuditLogsCustomerAssociationToAccount,
  postAuditLogsCustomerInvitation,
} from '../../../services/AuditLogs/AuditLogsService';
import { InvitationService } from '../../../services/Invitation/InvitationService';
import { emailRegex } from '../../../utils/validations';
import { InvitationSuccessfullySent } from '../../Feedbacks/InvitationSuccessfullySent/InvitationSuccessfullySent';
import { ModalDiscardChanges } from '../../Feedbacks/ModalDiscardChanges/ModalDiscardChanges';
import { ModalErrorInviteUser } from '../../Feedbacks/ModalErrorInviteUser/ModalErrorInviteUser';
import * as Styled from './Styled';
import { EmailStep } from './components/EmailStep/EmailStep';
import { NameStep } from './components/NameStep/NameStep';
import { ExistUserStep } from './components/UserExistStep/ExistUserStep';

interface ICustomerInvitationData {
  email: string;
  name: string;
  country: string;
  accountId: string;
  customerAccountId: string;
  accountName: string;
  vendorAccountId: string;
  vendorId: string;
}

interface IModalProps {
  title: string;
  subtitle: string;
  canAct: boolean;
}
interface IModalDiscardChanges {
  isOpen: boolean;
  cancelButton: boolean;
}

const invitationService = new InvitationService();

export const CustomerInvitationPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const location = useLocation();
  const searchQuery: string = location.search;

  const [step, setStep] = useState<number>(1);
  const [formError, setFormError] = useState<string | undefined>();
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [btnText, setBtnText] = useState<string>(
    formatMessage({ id: 'customerInvitationPage_nextButton' })
  );

  const [modalProps, setModalProps] = useState<IModalProps>({
    title: '',
    subtitle: '',
    canAct: false,
  });
  const [userAlreadyExists, setUserAlreadyExists] = useState<boolean>(false);
  const [modalDiscardChanges, setModalDiscardChanges] = useState<IModalDiscardChanges>({
    isOpen: false,
    cancelButton: false,
  });
  const [modalErrorInviteUser, setModalErrorInviteUser] = useState<boolean>(false);
  const [inviteCreatedWithSuccess, setInviteCreatedWithSuccess] = useState({
    existUser: userAlreadyExists,
    userId: false,
  });

  const initialValues = {
    email: '',
    country: '',
    managedBy: '',
    accountId: '',
    customerAccountId: '',
    accountName: '',
    name: '',
    vendorAccountId: '',
    vendorId: '',
  };

  const [invitationData, setInvitationData] = useState<ICustomerInvitationData>(initialValues);

  const getAccountParams = useCallback(() => {
    const urlParamsAccount = new URLSearchParams(decodeURIComponent(searchQuery));
    const { accountId, country, customerAccountId, pocName, vendorAccountId, vendorId } =
      Object.fromEntries(urlParamsAccount);

    setInvitationData((previousData) => ({
      ...previousData,
      country,
      accountId,
      vendorAccountId,
      vendorId,
      customerAccountId,
      accountName: pocName,
    }));
  }, [searchQuery]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAccountParams(), []);

  const isValidEmail = useCallback(async (): Promise<boolean> => {
    if (!invitationData.country || !invitationData.email) return false;

    if (!emailRegex.test(invitationData.email)) return false;

    const isAdmin = await invitationService.adminUserAlreadyExists({
      country: invitationData.country,
      value: invitationData.email,
    });

    return typeof isAdmin === 'undefined' ? false : !isAdmin;
  }, [invitationData.country, invitationData.email]);

  const invitationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required(formatMessage({ id: 'customerInvitationPage_emailIsRequired' }))
          .email(formatMessage({ id: 'customerInvitationPage_invalidEmailFormat' }))
          .test({
            test: isValidEmail,
            message: formatMessage({ id: 'customerInvitationPage_emailFromAdminUser' }),
          }),
      }),
    [isValidEmail, formatMessage]
  );

  const validateSchema = useCallback(async () => {
    await invitationSchema
      .validate({ email: invitationData.email })
      .then(() => {
        setFormError(undefined);
        setNextDisabled(false);
      })
      .catch((err: yup.ValidationError) => {
        setFormError(err.message);
        setNextDisabled(true);
      });
  }, [invitationData.email, invitationSchema]);

  useEffect(() => {
    validateSchema();
  }, [invitationData.email, validateSchema]);

  const handleOnBlur = async () => validateSchema();

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.currentTarget.value;
    const data = { ...invitationData, email };
    setInvitationData(data);
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setInvitationData({ ...invitationData, name: event.currentTarget.value });
  };

  const handleSubmitToNextStep = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setNextDisabled(true);

    if (step === 1) {
      const customerUser = await invitationService.getCustomerUser({
        country: invitationData.country,
        value: invitationData.email,
      });

      if (customerUser) {
        setUserAlreadyExists(true);
        setInvitationData({ ...invitationData, name: customerUser.displayName });
      } else {
        setUserAlreadyExists(false);
        setInvitationData({ ...invitationData, name: '' });
      }

      setNextDisabled(false);
      setStep(2);
      setBtnText(formatMessage({ id: 'customerInvitationPage_finishButton' }));
    }
    if (step === 2) sendInvitation();
  };

  const handleClickBack = () => {
    if (step === 2) {
      setStep(1);
      setBtnText(formatMessage({ id: 'customerInvitationPage_nextButton' }));
    }
    if (step === 1) handleCancelInvitation(true);
  };

  const sendInvitation = async () => {
    setNextDisabled(true);
    setBtnText(formatMessage({ id: 'customerInvitationPage_processingButton' }));

    if (!invitationData.name) setInvitationData({ ...invitationData, name: 'New User' });

    await invitationService
      .sendCustomerInvitation(invitationData)
      .then((response) => {
        const { userId } = response.data;

        if (userId) {
          setInviteCreatedWithSuccess({
            existUser: userAlreadyExists,
            userId: true,
          });
        }

        SegmentService.customerInvitationSubmitted({
          account_country: invitationData.country,
          account_id: invitationData.customerAccountId,
          account_name: invitationData.accountName,
          new_user_email: invitationData.email,
          screen_name: ScreenName1.CustomerUserInvitation,
        });
        if (userAlreadyExists) {
          const auditLogsParams = getCustomerAssociationAuditLogsParams({
            accountId: invitationData.accountId,
            country: invitationData.country,
            userId,
          });
          postAuditLogsCustomerAssociationToAccount(auditLogsParams);
        } else {
          const auditLogsParams = getCustomerInviationAuditLogsParams({
            accountId: invitationData.accountId,
            country: invitationData.country,
            userId,
          });
          postAuditLogsCustomerInvitation(auditLogsParams);
        }
      })
      .catch((error) => {
        const errorCode = error?.response?.data?.data?.code;
        const duplicatedInsertError = 'identity-metadata-service.insert-duplicate-key';
        if (errorCode === duplicatedInsertError) {
          setModalProps({
            canAct: false,
            title: formatMessage({
              id: 'customerInvitationPage_errorModal_duplicatedInsertError_title',
            }),
            subtitle: formatMessage({
              id: 'customerInvitationPage_errorModal_duplicatedInsertError_subtitle',
            }),
          });
        } else {
          setModalProps({
            canAct: true,
            title: formatMessage({
              id: 'customerInvitationPage_errorModal_generalError_title',
            }),
            subtitle: formatMessage({
              id: 'customerInvitationPage_errorModal_generalError_subtitle',
            }),
          });
        }
        setModalErrorInviteUser(true);
      })
      .finally(() => {
        setBtnText(formatMessage({ id: 'customerInvitationPage_finishButton' }));
        setNextDisabled(false);
      });
  };

  const handleInvitationCancel = () => {
    history.goBack();
    SegmentService.customerUserInvitationCanceled(modalDiscardChanges.cancelButton);
  };

  const handleNewInviteUser = () => {
    setStep(1);
    setBtnText(formatMessage({ id: 'customerInvitationPage_nextButton' }));
    setInvitationData({ ...invitationData, email: '', name: '' });
    setInviteCreatedWithSuccess({
      existUser: false,
      userId: false,
    });

    SegmentService.customerInvitationRestarted();
  };

  const handleCancelInvitation = (isCancelButton: boolean): void => {
    setModalDiscardChanges({
      isOpen: true,
      cancelButton: isCancelButton,
    });
  };

  const handleFinishedInviteUser = () => {
    history.goBack();
    SegmentService.customerInvitationFinished();
  };

  const getCustomerInviationAuditLogsParams = ({
    accountId,
    userId,
    country,
  }: {
    accountId: string;
    userId: string;
    country: string;
  }): AuditLogsCustomerInvitationParams => ({
    metadata: {
      wasApprovedBySystem: true,
      country,
      userType: 'CUSTOMER' as AuditLogsUserTypes,
      accountId: {
        added: [accountId],
      },
    },
    userId,
  });

  const getCustomerAssociationAuditLogsParams = ({
    accountId,
    country,
    userId,
  }: {
    accountId: string;
    userId: string;
    country: string;
  }): AuditLogsCustomerAssociationToAccountParams => ({
    metadata: {
      wasApprovedBySystem: true,
      userType: 'CUSTOMER' as AuditLogsUserTypes,
      country,
      accountId: {
        added: [accountId],
      },
    },
    userId,
  });

  return (
    <>
      <Styled.AppName>{formatMessage({ id: 'appName' })}</Styled.AppName>

      {!inviteCreatedWithSuccess.userId && (
        <Styled.CloseButton
          onClick={() => handleCancelInvitation(false)}
          data-testid="customer workflow"
          aria-label="close invitation"
        >
          <X size="large" />
        </Styled.CloseButton>
      )}

      {!inviteCreatedWithSuccess.userId && (
        <Styled.Box>
          <Styled.Step data-testid="step">
            {formatMessage({ id: 'customerInvitationPage_steps' }, { step, steps: 2 })}
          </Styled.Step>
          <Styled.Title>{formatMessage({ id: 'customerInvitationPage_title' })}</Styled.Title>
          <Formik
            initialValues={{
              email: '',
              country: '',
              accountId: '',
              customerAccountId: '',
              accountName: '',
              name: '',
            }}
            onSubmit={sendInvitation}
            validationSchema={invitationSchema}
          >
            {() => (
              <Form autoComplete="off" onSubmit={handleSubmitToNextStep}>
                {step === 1 && (
                  <EmailStep
                    email={invitationData.email}
                    onHandleChangeEmail={handleChangeEmail}
                    onHandleBlurEmail={handleOnBlur}
                    error={formError}
                  />
                )}

                {step === 2 && !userAlreadyExists && !inviteCreatedWithSuccess.userId && (
                  <NameStep
                    name={invitationData.name}
                    onHandleChangeName={handleChangeName}
                    disabled={userAlreadyExists}
                    customerAccountId={invitationData.customerAccountId}
                    accountName={invitationData.accountName}
                    country={invitationData.country}
                  />
                )}

                {step === 2 && userAlreadyExists && (
                  <>
                    <ExistUserStep
                      fullName={invitationData.name}
                      email={invitationData.email}
                      accountName={invitationData.accountName}
                      customerAccountId={invitationData.customerAccountId}
                    />
                  </>
                )}

                <Styled.BoxStepButtons>
                  <Styled.StepButton
                    disabled={
                      nextDisabled ||
                      invitationData.email.length < 3 ||
                      btnText === formatMessage({ id: 'customerInvitationPage_processingButton' })
                    }
                    type="submit"
                    dark
                  >
                    {btnText}
                  </Styled.StepButton>
                  <Styled.StepButton type="button" onClick={handleClickBack} data-testid="back">
                    {formatMessage({ id: 'customerInvitationPage_backButton' })}
                  </Styled.StepButton>
                </Styled.BoxStepButtons>
              </Form>
            )}
          </Formik>
        </Styled.Box>
      )}

      {inviteCreatedWithSuccess.userId && (
        <InvitationSuccessfullySent
          userExist={userAlreadyExists}
          country={invitationData.country}
          firstName={invitationData.name}
          email={invitationData.email}
          accountName={invitationData.accountName}
          accountId={invitationData.customerAccountId}
          handleRedirectDone={handleFinishedInviteUser}
          handleRedirectNewUser={handleNewInviteUser}
        />
      )}

      <ModalDiscardChanges
        openModal={modalDiscardChanges.isOpen}
        handleButtonCloseModal={() =>
          setModalDiscardChanges({ isOpen: false, cancelButton: false })
        }
        handleKeepEditing={() => setModalDiscardChanges({ isOpen: false, cancelButton: false })}
        handleCancel={handleInvitationCancel}
      />
      <ModalErrorInviteUser
        canAct={modalProps.canAct}
        title={modalProps.title}
        subtitle={modalProps.subtitle}
        openModal={modalErrorInviteUser}
        handleTryAgain={() => {
          sendInvitation();
          setModalErrorInviteUser(false);
        }}
        handleCancel={() => setModalErrorInviteUser(false)}
        handleButtonCloseModal={() => setModalErrorInviteUser(false)}
      />
    </>
  );
};
