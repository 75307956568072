import { styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled('div')({});

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '16px',
    textAlign: 'center',
    margin: '24px',
    width: '100%',
  },
  title: {
    fontSize: '32px',
  },
  subtitle: {
    fontSize: '16px',
    width: '60%',
  },
  errorMessage: {
    width: '70vw',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '70vw',
  },
  pos: {
    marginBottom: 12,
  },
});
