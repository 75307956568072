import React from 'react';
import { useIntl } from 'react-intl';
import { MiddleTitle } from '../NameStep/Styled';
import AccountAvatarImage from './images/accountAvatar.png';
import * as Styled from './Styled';

interface IProps {
  accountName: string;
  customerAccountId: string;
}

export const SelectedAccount = ({ accountName, customerAccountId }: IProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <>
      <MiddleTitle>
        {formatMessage({ id: 'customerInvitationPage_selectedAccount_middleTitle' })}
      </MiddleTitle>
      <Styled.Container>
        <Styled.AccountAvatar src={AccountAvatarImage} />
        <Styled.List>
          <Styled.ListItem>{accountName}</Styled.ListItem>
          <Styled.ListItem>
            {formatMessage(
              {
                id: 'customerInvitationPage_selectedAccount_subtitle',
              },
              { accountId: customerAccountId }
            )}
          </Styled.ListItem>
        </Styled.List>
      </Styled.Container>
    </>
  );
};
