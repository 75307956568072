import { MessageMap } from './types';

export const enUS: MessageMap = {
  adminForceInvitationPage_registrationForm_inputEmail_validatingMessage: 'Validating...',
  adminForceInvitationPage_registrationForm_stepper_title: 'Add new Force user',
  adminForceInvitationPage_registrationForm_submitButton_error:
    'An error occurred with your request. Please, try again later.',
  adminForceInvitationPage_registrationForm_textField_managerDoNotExistError:
    'This administrator user do not exists.',
  adminForceInvitationPage_resultPage_invite_failed: 'Invite failed',
  adminForceInvitationPage_resultPage_invite_success: 'Invited',
  adminForceInvitationPage_resultPage_managedByLinked_failed: 'Manager not linked',
  adminForceInvitationPage_resultPage_managedByLinked_success: 'Manager linked',
  adminInvitationForcePage_chipsField_firstDescription: 'Vendor ID',
  adminInvitationPage_chipsField_firstDescription: 'Company IDs',
  adminInvitationPage_chipsField_firstDescriptionOptional: 'optional',
  adminInvitationPage_chipsField_secondDescription: 'Use commas to separate values.',
  adminInvitationPage_feedbackPage_accountId: 'Account ID',
  adminInvitationPage_feedbackPage_addNewUserButton: 'Add new user',
  adminInvitationPage_feedbackPage_addedSuccessfuly: 'User successfully added!',
  adminInvitationPage_feedbackPage_associatedAccount: 'We added the selected account to the user.',
  adminInvitationPage_feedbackPage_finishButton: 'Got it',
  adminInvitationPage_feedbackPage_invitedUser:
    'We sent an email inviting the user to register and start using the BEES platform.',
  adminInvitationPage_modalErrorInviteUser_actionButton: 'Try again',
  adminInvitationPage_modalErrorInviteUser_cancelButton: 'Cancel',
  adminInvitationPage_modalErrorInviteUser_closeButton: 'Ok',
  adminInvitationPage_registrationForm_backButton: 'Go back',
  adminInvitationPage_registrationForm_bdrId_errorMessage: 'Enter a valid BDR ID',
  adminInvitationPage_registrationForm_bdrId_placeholder: 'BDR ID',
  adminInvitationPage_registrationForm_card_title: 'Properties Group',
  adminInvitationPage_registrationForm_country_inputLabel: 'Country',
  adminInvitationPage_registrationForm_country_inputPlaceholder: 'Select an option',
  adminInvitationPage_registrationForm_finishButton: 'Submit',
  adminInvitationPage_registrationForm_inputEmail_errorMessage:
    'Enter a valid email (ex: marcus@gmail.com)',
  adminInvitationPage_registrationForm_inputEmail_placeholder: 'Email',
  adminInvitationPage_registrationForm_inputLastName_errorMessage: 'Enter a last name',
  adminInvitationPage_registrationForm_inputLastName_placeholder: 'Last name',
  adminInvitationPage_registrationForm_inputName_errorMessage: 'Enter a name',
  adminInvitationPage_registrationForm_inputName_placeholder: 'First name',
  adminInvitationPage_registrationForm_managedBy_placeholder: 'Managed by',
  adminInvitationPage_registrationForm_permissionGroup_inputLabel: 'Permission groups',
  adminInvitationPage_registrationForm_permissionGroup_inputPlaceholder: 'Select an option',
  adminInvitationPage_registrationForm_processingButton: 'Processing...',
  adminInvitationPage_registrationForm_removeButton: 'Remove',
  adminInvitationPage_registrationForm_routeId_inputLabel: 'Route ID',
  adminInvitationPage_registrationForm_routeId_inputPlaceholder: 'Route ID (optional)',
  adminInvitationPage_registrationForm_secondSectionTitle: 'People',
  adminInvitationPage_registrationForm_stepper_subtitle:
    'Enter the information below to add the new users.',
  adminInvitationPage_registrationForm_stepper_title: 'Add new administrators',
  adminInvitationPage_registrationForm_supportedCountries_inputLabel: 'Supported countries',
  adminInvitationPage_registrationForm_supportedCountries_inputPlaceholder: 'Select an option',
  adminInvitationPage_registrationForm_textField_userAlreadyExistError:
    'This administrator user already exists.',
  adminInvitationPage_registrationForm_userType_card_title: 'Select User Type',
  adminInvitationPage_registrationForm_userType_inputLabel: 'User Type',
  adminInvitationPage_registrationForm_validateEmail_errorMessage:
    'Enter a valid email (ex: marcus@gmail.com)',
  adminInvitationPage_registrationForm_validateRouteId_errorMessage: 'Enter a valid Route ID',
  adminInvitationPage_registrationForm_validateTextFields_errorMessageAllowOnlyLetters:
    'This field only accepts letters.',
  adminInvitationPage_registrationForm_vendorId_inputLabel: 'Vendor ID',
  adminInvitationPage_registrationForm_vendorId_inputPlaceholder: 'Vendor ID',
  adminInvitationPage_registrationForm_vendorId_invalidVendorIdError:
    'Enter a valid Vendor ID. Vendor ID must have 36 characters.',
  adminInvitationPage_registrationForm_vendorId_valueAlreadyExistError:
    'This vendor ID already added.',
  adminInvitationPage_resultPage_addNewUserButton: 'Add new user',
  adminInvitationPage_resultPage_associated: 'Associated',
  adminInvitationPage_resultPage_emailSent:
    'We sent an email to each added person, inviting them to register and start using the BEES platforms.',
  adminInvitationPage_resultPage_errorMessage: 'Some invitations couldn’t be sent.',
  adminInvitationPage_resultPage_failed: 'Failed',
  adminInvitationPage_resultPage_finishButton: 'Ok, done',
  adminInvitationPage_resultPage_infoSection_companiesTitle: 'Company IDs',
  adminInvitationPage_resultPage_infoSection_countryTitle: 'Country',
  adminInvitationPage_resultPage_infoSection_deliveryCenterIdsTitle: 'Delivery center IDs',
  adminInvitationPage_resultPage_infoSection_logisticOperatorIdsTitle: 'Logistic operator IDs',
  adminInvitationPage_resultPage_infoSection_permissionGroupsTitle: 'Permission groups',
  adminInvitationPage_resultPage_infoSection_supportedCountriesTitle: 'Supported countries',
  adminInvitationPage_resultPage_invited: 'Invited',
  adminInvitationPage_resultPage_partial: 'Partial',
  adminInvitationPage_resultPage_successMessage:
    'Users added and invitation emails successfully sent.',
  appName: 'BEES User Management',
  customerInvitationPage_backButton: 'Go back',
  customerInvitationPage_emailFromAdminUser: "Admin users can't be invited",
  customerInvitationPage_emailIsRequired: 'Email address is required',
  customerInvitationPage_emailStep_inputLabel: 'Email',
  customerInvitationPage_emailStep_subtitle: 'First, enter the user’s email.',
  customerInvitationPage_errorModal_duplicatedInsertError_subtitle:
    "This email is already registered and can't be invited again.",
  customerInvitationPage_errorModal_duplicatedInsertError_title: 'You can’t add this user',
  customerInvitationPage_errorModal_generalError_subtitle:
    'We are facing a temporary instability, please try again.',
  customerInvitationPage_errorModal_generalError_title: "The user couldn't be added",
  customerInvitationPage_existUserStep_subtitle:
    'We found a user with this email. You can add an account to this user.',
  customerInvitationPage_finishButton: 'Submit',
  customerInvitationPage_invalidEmailFormat: 'Enter a valid email (ex: marcus@gmail.com)',
  customerInvitationPage_nameStep_inputLabel: 'First name',
  customerInvitationPage_nameStep_inputPlaceholder: 'New User',
  customerInvitationPage_nameStep_subtitle:
    'Enter the following information to pre-register the new user.',
  customerInvitationPage_nextButton: 'Next',
  customerInvitationPage_processingButton: 'Processing...',
  customerInvitationPage_selectedAccount_middleTitle: 'Add to',
  customerInvitationPage_selectedAccount_subtitle: 'Account ID {accountId}',
  customerInvitationPage_steps: 'Step {step} of {steps}',
  customerInvitationPage_title: 'Add new customer',
  discardChangesModal_confirmButton: 'Yes, close',
  discardChangesModal_keepEditingButton: 'No, keep editing',
  discardChangesModal_subtitle:
    "All the entered information will be lost and this action can't be undone.",
  discardChangesModal_title: 'Are you sure you want to close?',
  invitationPage_administratorCard_subtitle: 'Manages users, products, routines and more.',
  invitationPage_administratorCard_title: 'Administrator',
  invitationPage_step: 'Step 1',
  invitationPage_subtitle: 'Choose a user category to continue.',
  invitationPage_title: 'Add new user',
  test_missing: 'Just English option available',
  test_translate: 'Translate',
};
