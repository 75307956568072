import { styled } from '@material-ui/core';
import { StackChildProps, StackContainerProps } from './interface';

export const StyledChild = styled('div')({
  flexGrow: ({ fullwidth }: StackChildProps) => fullwidth || undefined,
  margin: ({ spacing, orientation }: StackChildProps) =>
    orientation?.includes('row') ? `0 ${spacing} 0 0` : `0 0 ${spacing} 0`,
});
export const StyledContainer = styled('div')({
  display: 'flex',
  flexGrow: 1,
  alignItems: ({ align }: StackContainerProps) => align || 'normal',
  flexDirection: ({ orientation }: StackContainerProps) => orientation || 'row',
});
