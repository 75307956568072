import styled, { css } from 'styled-components';

export const AppName = styled.p`
  height: 56px;
  color: rgba(0, 0, 0, 0.55);
  font: 400 16px/24px 'Work Sans', sans-serif;
  width: 100%;
`;

export const Panel = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  margin-top: 24px;
  max-width: 100%;
  padding: 32px 40px;
  margin: 32px auto;
  display: flex;
  flex-direction: column;
`;

interface IPropsInputError {
  error: boolean;
}
export const Input = styled.input<IPropsInputError>`
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  border: 1px solid #dfdede;
  border-radius: 8px;
  margin-top: 4px;
  padding: 0 16px;

  &:focus {
    border: 1px solid #000000;
  }

  ${(props) =>
    props.error &&
    css`
      border-color: #c9201d;
    `}
`;

export const Label = styled.label<IPropsInputError>`
  height: 20px;
  width: 100%;
  color: rgba(0, 0, 0, 0.9);
  font-family: 'Work Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  ${(props) =>
    props.error &&
    css`
      color: #c9201d;
    `}
`;

export const ValidationMessage = styled.span`
  width: 100%;
  color: #c9201d;
  font-family: 'Work Sans';
  font-size: 12px;
  letter-spacing: 0.03px;
  line-height: 18px;
  margin-top: 4px;
`;
