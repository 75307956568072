import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { PropsPerClusterProvider } from 'admin-portal-shared-services';
import React from 'react';
import { AppBarConfig } from './AppBarConfig';
import { GlobalStyle } from './global/global';
import { IntlProvider } from './i18n';

type AppProps = {
  segmentKey: Record<'default' | 'Restricted_US', string>;
};

export function App(appProps: AppProps): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-identity-invitation-mfe',
    seed: 'admin-portal-identity-invitation-mfe',
  });

  return (
    <PropsPerClusterProvider propValues={appProps}>
      <IntlProvider>
        <GlobalStyle />
        <StylesProvider generateClassName={generateClassName}>
          <AppBarConfig />
        </StylesProvider>
      </IntlProvider>
    </PropsPerClusterProvider>
  );
}
