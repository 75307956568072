import React, { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from '../../../components/Modal/Modal';
import * as Styled from '../../../components/Modal/styles';

interface IModalDiscardChanges {
  openModal: boolean;
  handleButtonCloseModal: () => void;
  handleKeepEditing(event: MouseEvent<HTMLButtonElement>): void;
  handleCancel(event: MouseEvent<HTMLButtonElement>): void;
}

export function ModalDiscardChanges({
  openModal,
  handleButtonCloseModal,
  handleKeepEditing,
  handleCancel,
}: IModalDiscardChanges): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Modal
      modalTitle={formatMessage({ id: 'discardChangesModal_title' })}
      isModalOpen={openModal}
      handleButtonCloseModal={handleButtonCloseModal}
    >
      <Styled.ModalSubtitle>
        {formatMessage({ id: 'discardChangesModal_subtitle' })}
      </Styled.ModalSubtitle>
      <Styled.ModalFooter>
        <Styled.ModalButton variant="outline" onClick={handleCancel} data-testid="redirect button">
          {formatMessage({ id: 'discardChangesModal_confirmButton' })}
        </Styled.ModalButton>
        <Styled.ModalButton onClick={handleKeepEditing} data-testid="keep editing button">
          {formatMessage({ id: 'discardChangesModal_keepEditingButton' })}
        </Styled.ModalButton>
      </Styled.ModalFooter>
    </Modal>
  );
}
