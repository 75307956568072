import { useAuthenticationService as authService } from 'admin-portal-shared-services';
import axios, { AxiosInstance } from 'axios';
import { v4 } from 'uuid';
import { getCountry, getToken } from '../../helpers';
import { getApiHost } from '../host/HostService';
import {
  Action,
  AuditLogs,
  AuditLogsAccountParams,
  AuditLogsAdminInvitationParams,
  AuditLogsCustomerAssociationToAccountParams,
  AuditLogsCustomerInvitationParams,
  AuditLogsForceInvitationParams,
  AuditLogsUserParams,
  Metadata,
  Target,
} from './types';

const axiosInstance: AxiosInstance = axios.create();
const BASE_URL = getApiHost();
const auditServiceRelayURL = `${BASE_URL}/audit`;

const getHeaderParams = (country = getCountry()) => ({
  requestTraceId: v4(),
  Authorization: getToken(),
  country,
});

const createMetadataEncoded = (metadata: Metadata) => {
  const metadataEncoded: { [key: string]: string } = {};
  const metadataKeys = Object.keys(metadata);
  const metadataValues = Object.values(metadata);

  metadataKeys.forEach((key, index) => {
    const encoder = typeof metadataValues[index] === 'object';
    metadataEncoded[key] = encoder ? JSON.stringify(metadataValues[index]) : metadataValues[index];
  });
  return metadataEncoded;
};

const createAuditLogsForUser = (auditLogsParams: AuditLogsUserParams): AuditLogs => {
  const { userId: entityId, metadata, operation } = auditLogsParams;
  const metadataEncoded = createMetadataEncoded(metadata);

  const action: Action = {
    metadata: metadataEncoded,
    operation,
  };
  const target: Target = {
    entity: 'USERS',
    entityId,
  };

  return {
    action,
    target,
  };
};
const createAuditLogsForAccount = (auditLogsParams: AuditLogsAccountParams): AuditLogs => {
  const { accountId: entityId, metadata, operation } = auditLogsParams;
  const metadataEncoded = createMetadataEncoded(metadata);

  const action: Action = {
    metadata: metadataEncoded,
    operation,
  };
  const target: Target = {
    entity: 'ACCOUNTS',
    entityId,
  };

  return {
    action,
    target,
  };
};

const postAuditLogs = async (
  headers: Record<string, string>,
  auditLogs: AuditLogs
): Promise<boolean> => {
  try {
    const response = await authService()
      .enhancedAxios(axiosInstance)
      .post(auditServiceRelayURL, auditLogs, { headers });
    return response.status === 202;
  } catch {
    return false;
  }
};

const postAuditLogsAdminInvitation = async (
  userMetadata: AuditLogsAdminInvitationParams
): Promise<boolean> => {
  const headerParams = getHeaderParams(userMetadata.metadata.country);
  const auditLogs: AuditLogs = createAuditLogsForUser({ ...userMetadata, operation: 'INSERT' });
  return postAuditLogs(headerParams, auditLogs);
};

const postAuditLogsForceInvitation = async (
  userMetadata: AuditLogsForceInvitationParams
): Promise<boolean> => {
  const headerParams = getHeaderParams(userMetadata.metadata.country);
  const auditLogs: AuditLogs = createAuditLogsForUser({ ...userMetadata, operation: 'INSERT' });
  return postAuditLogs(headerParams, auditLogs);
};

const postAuditLogsCustomerInvitation = async (
  userMetadata: AuditLogsCustomerInvitationParams
): Promise<boolean> => {
  const headerParams = getHeaderParams(userMetadata.metadata.country);
  const auditLogsUser: AuditLogs = createAuditLogsForUser({ ...userMetadata, operation: 'INSERT' });
  const auditLogsAccount: AuditLogs = createAuditLogsForAccount({
    metadata: { wasApprovedBySystem: true, userId: { added: [userMetadata.userId] } },
    operation: 'UPDATE',
    accountId: userMetadata.metadata.accountId.added[0],
  });
  const audiLogsAccountResult = await postAuditLogs(headerParams, auditLogsAccount);
  const audiLogsUserResult = await postAuditLogs(headerParams, auditLogsUser);
  return audiLogsAccountResult && audiLogsUserResult;
};

const postAuditLogsCustomerAssociationToAccount = async (
  userMetadata: AuditLogsCustomerAssociationToAccountParams
): Promise<boolean> => {
  const headerParams = getHeaderParams(userMetadata.metadata.country);
  const auditLogsUser: AuditLogs = createAuditLogsForUser({ ...userMetadata, operation: 'INSERT' });
  const auditLogsAccount: AuditLogs = createAuditLogsForAccount({
    metadata: {
      wasApprovedBySystem: true,
      userId: { added: [userMetadata.userId] },
      country: userMetadata.metadata.country,
    },
    operation: 'UPDATE',
    accountId: userMetadata.metadata.accountId.added[0],
  });
  const audiLogsAccountResult = await postAuditLogs(headerParams, auditLogsAccount);
  const audiLogsUserResult = await postAuditLogs(headerParams, auditLogsUser);
  return audiLogsAccountResult && audiLogsUserResult;
};

export {
  axiosInstance,
  postAuditLogs,
  postAuditLogsAdminInvitation,
  postAuditLogsCustomerAssociationToAccount,
  postAuditLogsCustomerInvitation,
  postAuditLogsForceInvitation,
};
