import React from 'react';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';

interface IRoute {
  exact: boolean;
  path: string;
  component: React.ComponentType<RouteComponentProps> | undefined;
  permission: boolean;
}

export function PrivateRoute({
  exact,
  path,
  component,
  permission,
  ...rest
}: IRoute): JSX.Element | null {
  const history = useHistory();
  React.useEffect(() => {
    if (!permission) {
      history.push('/error/403');
    }
  }, [history, permission]);

  return permission ? <Route exact={exact} path={path} component={component} {...rest} /> : null;
}
