import { Link } from '@admin-portal-shared-components/icons';
import React, { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { ScreenName1 } from '../../../../analytics';
import { SegmentService } from '../../../helpers';
import { initialLetters } from '../../../helpers/InitialLetters/initialLetters';
import AccountAvatarImage from './images/accountAvatar.png';
import * as Styled from './styles';

interface IInvitationSuccessfullySent {
  userExist: boolean;
  country: string;
  firstName: string;
  email: string;
  accountName: string;
  accountId: string;
  handleRedirectNewUser(event: MouseEvent<HTMLButtonElement>): void;
  handleRedirectDone(event: MouseEvent<HTMLButtonElement>): void;
}

export function InvitationSuccessfullySent({
  userExist,
  country,
  firstName,
  email,
  accountName,
  accountId,
  handleRedirectNewUser,
  handleRedirectDone,
}: IInvitationSuccessfullySent): JSX.Element {
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    SegmentService.customerInvitationResult({
      user_associated: userExist,
      user_invited: !userExist,
      account_country: country,
      account_id: accountId,
      account_name: accountName,
      new_user_email: email,
      screen_name: ScreenName1.CustomerUserInvitation,
    });
  }, [accountId, accountName, country, email, userExist]);

  return (
    <Styled.Container>
      <Styled.Header>
        <h2>{formatMessage({ id: 'adminInvitationPage_feedbackPage_addedSuccessfuly' })}</h2>
        <p>
          {userExist
            ? formatMessage({ id: 'adminInvitationPage_feedbackPage_associatedAccount' })
            : formatMessage({ id: 'adminInvitationPage_feedbackPage_invitedUser' })}
        </p>
      </Styled.Header>
      <Styled.Card>
        <Styled.CartItem>
          <li>
            <Styled.Avatar>{initialLetters(firstName)}</Styled.Avatar>
          </li>
          <li>{firstName}</li>
          <li>{email}</li>
        </Styled.CartItem>
        <Link size="xlarge" href="#" />
        <Styled.CartItem>
          <li>
            <Styled.AccountAvatar src={AccountAvatarImage} />
          </li>
          <li>{accountName}</li>
          <li>
            {formatMessage({ id: 'adminInvitationPage_feedbackPage_accountId' })} {accountId}
          </li>
        </Styled.CartItem>
      </Styled.Card>

      <Styled.Footer>
        <Styled.Button type="button" variant="outline" onClick={handleRedirectNewUser}>
          {formatMessage({ id: 'adminInvitationPage_feedbackPage_addNewUserButton' })}
        </Styled.Button>
        <Styled.Button type="button" onClick={handleRedirectDone}>
          {formatMessage({ id: 'adminInvitationPage_feedbackPage_finishButton' })}
        </Styled.Button>
      </Styled.Footer>
    </Styled.Container>
  );
}
