import React from 'react';
import { useIntl } from 'react-intl';
import * as Styled from '../../Styled';
import { Profile } from '../Profile/Profile';
import { SelectedAccount } from '../SelectedAccount/SelectedAccount';

interface IProps {
  fullName: string;
  email: string;
  accountName: string;
  customerAccountId: string;
}

export function ExistUserStep({
  fullName,
  email,
  accountName,
  customerAccountId,
}: IProps): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <>
      <Styled.SubTitle>
        {formatMessage({ id: 'customerInvitationPage_existUserStep_subtitle' })}
      </Styled.SubTitle>

      <Styled.Panel>
        <Profile fullName={fullName} email={email} />
        <SelectedAccount accountName={accountName} customerAccountId={customerAccountId} />
      </Styled.Panel>
    </>
  );
}
