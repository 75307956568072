import styled, { css } from 'styled-components';

interface IButton {
  variant?: string;
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.header`
  text-align: center;

  & h2,
  p {
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
  }

  & h2 {
    font: 600 32px/40px 'Barlow', sans-serif;
    margin-bottom: 8px;
  }

  & p {
    font: 400 16px/24px 'Work Sans', sans-serif;
    letter-spacing: 0.1px;
    width: 450px;
    margin: auto;
  }
`;

export const Card = styled.div`
  width: 632px;
  height: 268px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  padding: 16px;

  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 276px 30px 276px;

  margin-top: 32px;
  margin-bottom: 32px;
`;

export const CartItem = styled.ul`
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;

  & li {
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(1) {
      margin-bottom: 16px;
    }

    &:nth-child(2) {
      font: 600 16px/24px 'Work Sans', sans-serif;
    }

    &:nth-child(3) {
      font: 400 14px/20px 'Work Sans', sans-serif;
      letter-spacing: 0.1px;
    }
  }
`;

export const Avatar = styled.div`
  background-color: #ffff00;
  width: 96px;
  height: 96px;
  font: 600 32px/40px 'Barlow', sans-serif;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const AccountAvatar = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 16px;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
`;

export const Button = styled.button<IButton>`
  height: 48px;
  border: 0;
  border-radius: 24px;
  display: flex;
  place-items: center;
  font: 500 18px/24px 'Work Sans', sans-serif;
  color: #fff;
  background-color: #000000;
  padding: 0 24px;
  cursor: pointer;
  text-decoration: none;

  &:first-child {
    margin-right: 16px;
  }

  &[disabled] {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color: rgba(0, 0, 0, 0.55);
    cursor: not-allowed;
  }

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      color: #000;
      border: 1px solid #000000;
      border-radius: 24px;
      background-color: #ffffff;
    `}
`;
