import React, { ChangeEvent, useRef, useState } from 'react';
import { useAuthenticationService } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { supportedCountries } from 'country-utils/src/entities';
import { SupportedCountryKeys, formatCountry } from 'country-utils';
import { ControlledSelect } from '../../../../../components/ControlledSelect';
import { Stack } from '../../../../../components/Stack/Stack';
import {
  AddUserIcon,
  Card,
  FirstSection,
  FormGroup,
  IconWrapper,
  LineDivider,
  SecondSectionTitle,
  StyledButton,
  StyledForm,
  TitleContainer,
  UserDivider,
  UserInputsWrapper,
  UserSection,
} from '../../AdminForceRegistration.styles';
import { ForceFormFields } from '../RegistrationForceForm/RegistrationForceForm';
import { useUserSupportedCountries } from '../../../../../hooks/useIsGlobalManager/useIsGlobalManager';
import { WrapperVendorIdSection } from '../FormAdminPortal/FormAdminPortal.styles';
import { StyledFormHelperText } from './FormForce.styles';

interface FormForceProps {
  onSubmit: (data: ForceFormFields) => void;
  permissionGroups: string[];
  isValidatingUserEmails: boolean[];
  isValidatingManagedByEmails: boolean[];
  validateUserEmail: (value: string, index: number) => Promise<void>;
  validateManagedByEmail: (value: string, index: number) => Promise<void>;
}

export const FormForce = ({
  onSubmit,
  permissionGroups,
  isValidatingUserEmails,
  isValidatingManagedByEmails,
  validateUserEmail,
  validateManagedByEmail: validateManagerByEmail,
}: FormForceProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
  } = useFormContext<ForceFormFields>();
  const {
    fields: fieldsUser,
    remove: removeFieldUser,
    append: appendFieldUser,
  } = useFieldArray({
    control,
    name: 'users',
  });
  const vendorIdValidationTimeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const authenticationService = useAuthenticationService();
  const userVendorId = authenticationService.getVendorId();
  const loggedUserSupportedCountries = useUserSupportedCountries();
  const availableCountries = loggedUserSupportedCountries?.filter((country) =>
    Object.keys(supportedCountries).includes(country)
  );
  const [inputLength, setInputLength] = useState(userVendorId.length);
  const onlyLettersPattern = /^[A-Za-z]+$/;

  function onChangeUserEmailInput(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    userFieldIndex: number
  ) {
    const { value } = event.target;
    setValue(`users.${userFieldIndex}.email`, value);

    validateUserEmail(value, userFieldIndex);

    const { users } = getValues();
    const isLastUserEmailInput = userFieldIndex === users.length - 1;
    if (isLastUserEmailInput) addUserEmailInput();
  }

  function onChangeManagerEmailInput(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    userFieldIndex: number
  ) {
    const { value } = event.target;
    setValue(`users.${userFieldIndex}.managedBy`, value);

    validateManagerByEmail(value, userFieldIndex);

    const { users } = getValues();
    const isLastManagerEmailInput = userFieldIndex === users.length - 1;
    if (isLastManagerEmailInput) addUserEmailInput();
  }

  function addUserEmailInput() {
    const maxUserInputs = 10;
    const { users } = getValues();
    const shouldAppendNewRow = users.length < maxUserInputs;
    if (shouldAppendNewRow)
      appendFieldUser(
        { firstName: '', lastName: '', email: '', bdrId: '' },
        { shouldFocus: false }
      );
  }

  function renderRemoveButton(index: number) {
    const showRemoveButton = fieldsUser.length > 1;
    const isLastElement = index === fieldsUser.length - 1;

    return (
      <StyledButton
        show={isLastElement && showRemoveButton}
        data-testid={`remove-button-${index}`}
        onClick={() => removeFieldUser(index)}
        variant="outlined"
        margin="16px 0 0 0"
      >
        {formatMessage({ id: 'adminInvitationPage_registrationForm_removeButton' })}
      </StyledButton>
    );
  }

  function renderUserEmailHelperText(index: number): JSX.Element | null {
    const isValidating = isValidatingUserEmails[index];
    if (isValidating) {
      return (
        <FormHelperText role="alert" style={{ marginLeft: 0, marginRight: 0 }}>
          {formatMessage({
            id: 'adminForceInvitationPage_registrationForm_inputEmail_validatingMessage',
          })}
        </FormHelperText>
      );
    }

    const hasError = Boolean(errors?.users?.[index]?.email);
    if (hasError) {
      return (
        <FormHelperText role="alert" style={{ marginLeft: 0, marginRight: 0 }}>
          {errors?.users?.[index]?.email?.message}
        </FormHelperText>
      );
    }

    return null;
  }

  function renderManagedByEmailHelperText(index: number): JSX.Element | null {
    const isValidating = isValidatingManagedByEmails[index];
    if (isValidating) {
      return (
        <FormHelperText role="alert" style={{ marginLeft: 0, marginRight: 0 }}>
          {formatMessage({
            id: 'adminForceInvitationPage_registrationForm_inputEmail_validatingMessage',
          })}
        </FormHelperText>
      );
    }

    const hasError = Boolean(errors?.users?.[index]?.managedBy);
    if (hasError) {
      return (
        <FormHelperText role="alert" style={{ marginLeft: 0, marginRight: 0 }}>
          {errors?.users?.[index]?.managedBy?.message}
        </FormHelperText>
      );
    }

    return null;
  }

  function onChangeInputVendors(vendorId: ChangeEvent<HTMLInputElement>) {
    const trimmedValue = vendorId.currentTarget.value.trim();
    const withoutSpacesValue = trimmedValue.replace(/\s/g, '');
    const updatedVendorIdValue: string[] = [withoutSpacesValue];
    setInputLength(withoutSpacesValue.length);
    setValue('vendors', updatedVendorIdValue);
    validateVendor(updatedVendorIdValue[0]);
  }

  const validateVendor = async (vendorId: string): Promise<void> => {
    const debounceDelayMs = 200;
    const isVendorValid = validateVendorId(vendorId);
    const errorMessage = formatMessage({
      id: 'adminInvitationPage_registrationForm_vendorId_invalidVendorIdError',
    });
    clearTimeout(vendorIdValidationTimeoutsRef.current[0]);
    vendorIdValidationTimeoutsRef.current[0] = setTimeout(async () => {
      if (!isVendorValid) {
        setError('vendors', { message: errorMessage });
      } else {
        clearErrors('vendors');
      }
    }, debounceDelayMs);
  };

function validateVendorId(vendor: string | string[]) {
  const vendorId = Array.isArray(vendor) ? vendor[0] : vendor;
  return vendorId.length === 36;
}
  return (
    <Card data-testid="usertype-force-form">
      <TitleContainer>
        {formatMessage({ id: 'adminInvitationPage_registrationForm_card_title' })}
      </TitleContainer>
      <LineDivider />

      <StyledForm onSubmit={handleSubmit(onSubmit)} id="usersForm">
        <FirstSection>
          <ControlledSelect
            controllerProps={{
              control,
              name: 'permissionGroups',
            }}
            autocompleteProps={{
              fullWidth: true,
              multiple: true,
              closeIcon: null,
              disableCloseOnSelect: true,
              options: permissionGroups,
              ChipProps: { size: 'small' },
            }}
            textFieldProps={{
              label: formatMessage({
                id: 'adminInvitationPage_registrationForm_permissionGroup_inputLabel',
              }),
              placeholder: formatMessage({
                id: 'adminInvitationPage_registrationForm_permissionGroup_inputPlaceholder',
              }),
              style: { borderRadius: '12px' },
            }}
          />
          <ControlledSelect
            data-testid="country"
            controllerProps={{
              control,
              name: 'country',
              rules: { required: 'Required' },
            }}
            autocompleteProps={{
              fullWidth: true,
              closeIcon: null,
              options: availableCountries || [],
              getOptionLabel: (option) => formatCountry(option as SupportedCountryKeys),
            }}
            textFieldProps={{
              label: formatMessage({
                id: 'adminInvitationPage_registrationForm_country_inputLabel',
              }),
              placeholder: formatMessage({
                id: 'adminInvitationPage_registrationForm_country_inputPlaceholder',
              }),
              style: { borderRadius: '12px' },
            }}
          />
          <ControlledSelect
            data-testid="supportedCountries"
            controllerProps={{
              control,
              name: 'supportedCountries',
              rules: { required: 'Required' },
            }}
            autocompleteProps={{
              fullWidth: true,
              closeIcon: null,
              options: availableCountries || [],
              ChipProps: { size: 'small' },
              getOptionLabel: (option) => formatCountry(option as SupportedCountryKeys),
              onChange: (_, value) => {
                const selectedCountry = value as SupportedCountryKeys;
                setValue('supportedCountries', [selectedCountry]);
              },
            }}
            textFieldProps={{
              label: formatMessage({
                id: 'adminInvitationPage_registrationForm_supportedCountries_inputLabel',
              }),
              placeholder: formatMessage({
                id: 'adminInvitationPage_registrationForm_supportedCountries_inputPlaceholder',
              }),
              style: { borderRadius: '12px' },
            }}
          />

          <WrapperVendorIdSection>
            <FormControl variant="outlined" size="small" error={Boolean(errors?.vendors)}>
              <TextField
                variant="outlined"
                data-testid="vendorId"
                id="vendorId"
                size="small"
                defaultValue={userVendorId}
                fullWidth
                error={Boolean(errors?.vendors)}
                {...register('vendors' as const, {
                  validate: (value) => validateVendorId(value),
                  onChange: (value) => onChangeInputVendors(value),
                })}
                inputProps={{ 'aria-label': 'vendorId', maxLength: 36, minLength: 36 }}
                label={formatMessage({
                  id: 'adminInvitationPage_registrationForm_vendorId_inputLabel',
                })}
                placeholder={formatMessage({
                  id: 'adminInvitationPage_registrationForm_vendorId_inputPlaceholder',
                })}
              />

              {Boolean(errors?.vendors) && (
                <StyledFormHelperText
                  style={{ alignSelf: 'flex-start' }}
                  data-testid="error-vendor-message"
                  role="alert"
                >
                  {errors?.vendors?.message}
                </StyledFormHelperText>
              )}
            </FormControl>

            <p>{inputLength}/36</p>
          </WrapperVendorIdSection>
        </FirstSection>
        <SecondSectionTitle>
          {formatMessage({
            id: 'adminInvitationPage_registrationForm_secondSectionTitle',
          })}
        </SecondSectionTitle>
        <UserSection>
          {fieldsUser.map((fieldUser, fieldUserIndex) => {
            return (
              <div key={fieldUser.id}>
                <Stack orientation="row" spacing="16px" align="start" fullWidth={[1]}>
                  <IconWrapper>
                    <AddUserIcon />
                  </IconWrapper>
                  <UserInputsWrapper>
                    <FormGroup>
                      <FormControl
                        error={Boolean(errors?.users?.[fieldUserIndex]?.email)}
                        variant="outlined"
                        size="small"
                        style={{ flexGrow: 1 }}
                      >
                        <TextField
                          variant="outlined"
                          data-testid={`user-email-${fieldUserIndex}`}
                          id={`user-email-${fieldUserIndex}`}
                          error={Boolean(errors?.users?.[fieldUserIndex]?.email)}
                          {...register(`users.${fieldUserIndex}.email` as const, {
                            required:
                              fieldUserIndex < fieldsUser.length - 1 || fieldsUser.length === 1
                                ? formatMessage({
                                    id: 'adminInvitationPage_registrationForm_inputEmail_errorMessage',
                                  })
                                : false,
                            onChange: (value) => onChangeUserEmailInput(value, fieldUserIndex),
                          })}
                          size="small"
                          type="text"
                          placeholder={formatMessage({
                            id: 'adminInvitationPage_registrationForm_inputEmail_placeholder',
                          })}
                          inputProps={{ 'aria-label': 'E-mail' }}
                        />
                        {renderUserEmailHelperText(fieldUserIndex)}
                      </FormControl>

                      <FormControl
                        variant="outlined"
                        size="small"
                        error={Boolean(errors?.users?.[fieldUserIndex]?.firstName)}
                      >
                        <TextField
                          id={`user-name-${fieldUserIndex}`}
                          data-testid={`user-name-${fieldUserIndex}`}
                          size="small"
                          variant="outlined"
                          {...register(`users.${fieldUserIndex}.firstName` as const, {
                            required:
                              fieldUserIndex < fieldsUser.length - 1 || fieldsUser.length === 1
                                ? formatMessage({
                                    id: 'adminInvitationPage_registrationForm_inputName_errorMessage',
                                  })
                                : false,
                            pattern: {
                              value: onlyLettersPattern,
                              message: formatMessage({
                                id: 'adminInvitationPage_registrationForm_validateTextFields_errorMessageAllowOnlyLetters',
                              }),
                            },
                          })}
                          type="text"
                          placeholder={formatMessage({
                            id: 'adminInvitationPage_registrationForm_inputName_placeholder',
                          })}
                          inputProps={{ 'aria-label': 'First name' }}
                          error={Boolean(errors?.users?.[fieldUserIndex]?.firstName)}
                        />
                        {Boolean(errors?.users?.[fieldUserIndex]?.firstName) && (
                          <StyledFormHelperText
                            data-testid={`error-user-name-${fieldUserIndex}`}
                            role="alert"
                          >
                            {errors?.users?.[fieldUserIndex]?.firstName?.message}
                          </StyledFormHelperText>
                        )}
                      </FormControl>

                      <FormControl
                        variant="outlined"
                        size="small"
                        error={Boolean(errors?.users?.[fieldUserIndex]?.lastName)}
                      >
                        <TextField
                          id={`user-name-${fieldUserIndex}`}
                          data-testid={`user-last-name-${fieldUserIndex}`}
                          size="small"
                          variant="outlined"
                          {...register(`users.${fieldUserIndex}.lastName` as const, {
                            required:
                              fieldUserIndex < fieldsUser.length - 1 || fieldsUser.length === 1
                                ? formatMessage({
                                    id: 'adminInvitationPage_registrationForm_inputLastName_errorMessage',
                                  })
                                : false,
                            pattern: {
                              value: onlyLettersPattern,
                              message: formatMessage({
                                id: 'adminInvitationPage_registrationForm_validateTextFields_errorMessageAllowOnlyLetters',
                              }),
                            },
                          })}
                          type="text"
                          placeholder={formatMessage({
                            id: 'adminInvitationPage_registrationForm_inputLastName_placeholder',
                          })}
                          inputProps={{ 'aria-label': 'Last name' }}
                          error={Boolean(errors?.users?.[fieldUserIndex]?.lastName)}
                        />
                        {Boolean(errors?.users?.[fieldUserIndex]?.lastName) && (
                          <StyledFormHelperText
                            data-testid={`error-user-last-name-${fieldUserIndex}`}
                            role="alert"
                          >
                            {errors?.users?.[fieldUserIndex]?.lastName?.message}
                          </StyledFormHelperText>
                        )}
                      </FormControl>
                    </FormGroup>

                    <FormGroup>
                      <FormControl
                        error={Boolean(errors?.users?.[fieldUserIndex]?.managedBy)}
                        variant="outlined"
                        size="small"
                        style={{ flexGrow: 1 }}
                      >
                        <TextField
                          variant="outlined"
                          data-testid={`managed-by-${fieldUserIndex}`}
                          id={`managed-by-${fieldUserIndex}`}
                          error={Boolean(errors?.users?.[fieldUserIndex]?.managedBy)}
                          {...register(`users.${fieldUserIndex}.managedBy` as const, {
                            required:
                              fieldUserIndex < fieldsUser.length - 1 || fieldsUser.length === 1
                                ? formatMessage({
                                    id: 'adminInvitationPage_registrationForm_inputEmail_errorMessage',
                                  })
                                : false,
                            onChange: (value) => onChangeManagerEmailInput(value, fieldUserIndex),
                          })}
                          size="small"
                          type="text"
                          placeholder={formatMessage({
                            id: 'adminInvitationPage_registrationForm_managedBy_placeholder',
                          })}
                          inputProps={{ 'aria-label': 'Managed By' }}
                        />
                        {renderManagedByEmailHelperText(fieldUserIndex)}
                      </FormControl>

                      <FormControl
                        variant="outlined"
                        size="small"
                        error={Boolean(errors?.users?.[fieldUserIndex]?.bdrId)}
                        style={{ flexGrow: 1 }}
                      >
                        <TextField
                          id={`user-bdrId-${fieldUserIndex}`}
                          data-testid={`user-bdrId-${fieldUserIndex}`}
                          size="small"
                          variant="outlined"
                          {...register(`users.${fieldUserIndex}.bdrId` as const, {
                            required:
                              fieldUserIndex < fieldsUser.length - 1 || fieldsUser.length === 1
                                ? formatMessage({
                                    id: 'adminInvitationPage_registrationForm_bdrId_errorMessage',
                                  })
                                : false,
                          })}
                          type="text"
                          placeholder={formatMessage({
                            id: 'adminInvitationPage_registrationForm_bdrId_placeholder',
                          })}
                          inputProps={{ 'aria-label': 'BDR ID' }}
                          error={Boolean(errors?.users?.[fieldUserIndex]?.bdrId)}
                        />
                        {Boolean(errors?.users?.[fieldUserIndex]?.bdrId) && (
                          <FormHelperText role="alert" style={{ marginLeft: 0, marginRight: 0 }}>
                            {errors?.users?.[fieldUserIndex]?.bdrId?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FormGroup>
                  </UserInputsWrapper>
                  {renderRemoveButton(fieldUserIndex)}
                </Stack>
                {fieldUserIndex !== fieldsUser.length - 1 && <UserDivider />}
              </div>
            );
          })}
        </UserSection>
      </StyledForm>
    </Card>
  );
};
