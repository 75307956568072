import styled, { css } from 'styled-components';

interface IAvatar {
  variant?: string;
}

const large = css`
  width: 96px;
  height: 96px;
  font: 600 32px/40px 'Barlow', sans-serif;
`;

export const Avatar = styled.div<IAvatar>`
  background-color: #ffff00;
  width: 56px;
  height: 56px;
  font: 600 20px/24px 'Barlow', sans-serif;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${({ variant }) => variant === 'large' && large}
`;
