import { Divider, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import React from 'react';
import { useIntl } from 'react-intl';
import { IRegisterForceUserResponse } from '__types/register-user-payload.types';
import { useStyles } from './TableItem.styles';

export const TableItem = ({ data }: { data: IRegisterForceUserResponse }): JSX.Element => {
  const { formatMessage } = useIntl();
  const { email, firstName, invited, managedByLinked } = data;
  const {
    root,
    content,
    statusIcon,
    successful,
    failed,
    primaryInfo,
    secondaryInfo,
    statusContainer,
  } = useStyles();

  return (
    <div className={root}>
      <div className={content}>
        <div className={primaryInfo}>
          <Typography variant="body1">{email}</Typography>
        </div>
        <div className={primaryInfo}>
          <Typography variant="body1" className={secondaryInfo}>
            {firstName}
          </Typography>
        </div>
        <div className={statusContainer}>
          {invited === 'Invited' ? (
            <Typography variant="body1" className={successful}>
              <CheckCircleOutlineIcon className={statusIcon} />
              {formatMessage({ id: 'adminForceInvitationPage_resultPage_invite_success' })}
            </Typography>
          ) : (
            <Typography variant="body1" className={failed}>
              <ReportProblemOutlinedIcon className={statusIcon} />
              {formatMessage({ id: 'adminForceInvitationPage_resultPage_invite_failed' })}
            </Typography>
          )}
          {managedByLinked === 'Linked' ? (
            <Typography variant="body1" className={successful}>
              <CheckCircleOutlineIcon className={statusIcon} />
              {formatMessage({ id: 'adminForceInvitationPage_resultPage_managedByLinked_success' })}
            </Typography>
          ) : (
            <Typography variant="body1" className={failed}>
              <ReportProblemOutlinedIcon className={statusIcon} />
              {formatMessage({ id: 'adminForceInvitationPage_resultPage_managedByLinked_failed' })}
            </Typography>
          )}
        </div>
      </div>
      <Divider />
    </div>
  );
};
