import { Card, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { MessageInformation } from 'supplier-portal-shared-components';
import { ResultsForce } from '../../AdminForceRegistration';
import { ButtonSection } from './components/ButtonSection/ButtonSection';
import { InfoSection } from './components/InfoSection/InfoSection';
import { Table } from './components/Table/Table';
import { useStyles } from './ResultsForcePage.styles';

export interface Props {
  data: ResultsForce;
  setStatus: React.Dispatch<React.SetStateAction<'idle' | 'processing' | 'resolved'>>;
}

export const ResultsForcePage = ({ data, setStatus }: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const { root, card, title, subtitle, titleContent, errorMessage } = useStyles();
  const shouldShowAlert = data.users.some((user) => user.invited === 'Failed');

  return (
    <div className={root}>
      <div className={titleContent}>
        <Typography variant="h4" gutterBottom className={title}>
          {formatMessage({ id: 'adminInvitationPage_resultPage_successMessage' })}
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={subtitle}>
          {formatMessage({ id: 'adminInvitationPage_resultPage_emailSent' })}
        </Typography>
        {shouldShowAlert ? (
          <MessageInformation type="error" customClasses={errorMessage}>
            {formatMessage({ id: 'adminInvitationPage_resultPage_errorMessage' })}
          </MessageInformation>
        ) : null}
      </div>
      <Card className={card}>
        <InfoSection data={data} />
        <Divider />
        <Table invitations={data.users} />
      </Card>
      <ButtonSection setStatus={setStatus} />
    </div>
  );
};
