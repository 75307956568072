import { useAuthenticationService as authService } from 'admin-portal-shared-services';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { IGroup } from 'interfaces/IGroup';
import { IRequestHeaders } from 'interfaces/IRequestHeaders';
import { IResponse } from 'interfaces/IResponse';
import { v4 } from 'uuid';
import { getCountry, getToken } from '../../helpers';
import { getApiHost } from '../host/HostService';

export class GroupsService {
  private axiosInstance: AxiosInstance;

  private headers: IRequestHeaders;

  constructor() {
    this.axiosInstance = axios.create();
    this.headers = {
      country: getCountry(),
      Authorization: getToken(),
      requestTraceId: v4(),
    };
  }

  public async getGroups(): Promise<AxiosResponse<IResponse<IGroup[]>>> {
    const URL = `${getApiHost()}/groups`;

    const result = await authService()
      .enhancedAxios(this.axiosInstance)
      .get(URL, { headers: { ...this.headers, requestTraceId: v4() } });

    return result;
  }
}
