import { MessageMap } from './types';

export const ptBR: MessageMap = {
  adminForceInvitationPage_registrationForm_inputEmail_validatingMessage: 'Validando...',
  adminForceInvitationPage_registrationForm_stepper_title: 'Adicionar novos usuários do Force',
  adminForceInvitationPage_registrationForm_submitButton_error:
    'Ocorreu um erro com sua requisição. Tente novamente mais tarde.',
  adminForceInvitationPage_registrationForm_textField_managerDoNotExistError:
    'Este usuário administrador não existe.',
  adminForceInvitationPage_resultPage_invite_failed: 'Falha no convite',
  adminForceInvitationPage_resultPage_invite_success: 'Convidado',
  adminForceInvitationPage_resultPage_managedByLinked_failed: 'Manager não vinculado',
  adminForceInvitationPage_resultPage_managedByLinked_success: 'Manager vinculado',
  adminInvitationForcePage_chipsField_firstDescription: 'Vendor ID',
  adminInvitationPage_chipsField_firstDescription: 'IDs da empresa',
  adminInvitationPage_chipsField_firstDescriptionOptional: 'opcional',
  adminInvitationPage_chipsField_secondDescription: 'Use vírgulas para separar valores.',
  adminInvitationPage_feedbackPage_accountId: 'ID da conta',
  adminInvitationPage_feedbackPage_addNewUserButton: 'Adicionar novo usuário',
  adminInvitationPage_feedbackPage_addedSuccessfuly: 'Usuário adicionado com sucesso!',
  adminInvitationPage_feedbackPage_associatedAccount: 'Adicionamos a conta selecionada ao usuário.',
  adminInvitationPage_feedbackPage_finishButton: 'Entendi',
  adminInvitationPage_feedbackPage_invitedUser:
    'Enviamos um e-mail convidando o usuário a se cadastrar e começar a usar a plataforma BEES.',
  adminInvitationPage_modalErrorInviteUser_actionButton: 'Tentar de novo',
  adminInvitationPage_modalErrorInviteUser_cancelButton: 'Cancelar',
  adminInvitationPage_modalErrorInviteUser_closeButton: 'Ok',
  adminInvitationPage_registrationForm_backButton: 'Voltar',
  adminInvitationPage_registrationForm_bdrId_errorMessage: 'Insira um BDR ID válido',
  adminInvitationPage_registrationForm_bdrId_placeholder: 'BDR ID',
  adminInvitationPage_registrationForm_card_title: 'Atributos do grupo',
  adminInvitationPage_registrationForm_country_inputLabel: 'País',
  adminInvitationPage_registrationForm_country_inputPlaceholder: 'Selecionar uma opção',
  adminInvitationPage_registrationForm_finishButton: 'Enviar',
  adminInvitationPage_registrationForm_inputEmail_errorMessage:
    'Insira um e-mail válido (ex: marcus@gmail.com)',
  adminInvitationPage_registrationForm_inputEmail_placeholder: 'E-mail',
  adminInvitationPage_registrationForm_inputLastName_errorMessage: 'Insira um sobrenome',
  adminInvitationPage_registrationForm_inputLastName_placeholder: 'Sobrenome',
  adminInvitationPage_registrationForm_inputName_errorMessage: 'Insira um nome',
  adminInvitationPage_registrationForm_inputName_placeholder: 'Nome',
  adminInvitationPage_registrationForm_managedBy_placeholder: 'Gerenciado por',
  adminInvitationPage_registrationForm_permissionGroup_inputLabel: 'Grupos permitidos',
  adminInvitationPage_registrationForm_permissionGroup_inputPlaceholder: 'Selecionar uma opção',
  adminInvitationPage_registrationForm_processingButton: 'Processando...',
  adminInvitationPage_registrationForm_removeButton: 'Excluir',
  adminInvitationPage_registrationForm_routeId_inputLabel: 'Route ID',
  adminInvitationPage_registrationForm_routeId_inputPlaceholder: 'Route ID (opcional)',
  adminInvitationPage_registrationForm_secondSectionTitle: 'Pessoas',
  adminInvitationPage_registrationForm_stepper_subtitle:
    'Insira as informações abaixo para adicionar os novos usuários.',
  adminInvitationPage_registrationForm_stepper_title: 'Adicionar novos administradores',
  adminInvitationPage_registrationForm_supportedCountries_inputLabel: 'Países permitidos',
  adminInvitationPage_registrationForm_supportedCountries_inputPlaceholder: 'Selecionar uma opção',
  adminInvitationPage_registrationForm_textField_userAlreadyExistError:
    'Este usuário administrador já existe.',
  adminInvitationPage_registrationForm_userType_card_title: 'Selecione o tipo de usuário',
  adminInvitationPage_registrationForm_userType_inputLabel: 'Tipo de usuário',
  adminInvitationPage_registrationForm_validateEmail_errorMessage:
    'Insira um e-mail válido (ex: marcus@gmail.com)',
  adminInvitationPage_registrationForm_validateRouteId_errorMessage: 'Insira um Route ID válido ',
  adminInvitationPage_registrationForm_validateTextFields_errorMessageAllowOnlyLetters:
    'Este campo aceita apenas letras.',
  adminInvitationPage_registrationForm_vendorId_inputLabel: 'Vendor ID',
  adminInvitationPage_registrationForm_vendorId_inputPlaceholder: 'Vendor ID',
  adminInvitationPage_registrationForm_vendorId_invalidVendorIdError:
    'Insira um Vendor ID válido. Um Vendor ID deve conter 36 caracteres.',
  adminInvitationPage_registrationForm_vendorId_valueAlreadyExistError:
    'Este vendor ID já foi adicionado.',
  adminInvitationPage_resultPage_addNewUserButton: 'Adicionar novo usuário',
  adminInvitationPage_resultPage_associated: 'Associated',
  adminInvitationPage_resultPage_emailSent:
    'Enviamos um e-mail para cada pessoa adicionada, convidando-os a se cadastrar e começar a usar as plataformas BEES.',
  adminInvitationPage_resultPage_errorMessage: 'Alguns convites não puderam ser enviados.',
  adminInvitationPage_resultPage_failed: 'Erro',
  adminInvitationPage_resultPage_finishButton: 'Ok, done',
  adminInvitationPage_resultPage_infoSection_companiesTitle: 'IDs da empresa',
  adminInvitationPage_resultPage_infoSection_countryTitle: 'País',
  adminInvitationPage_resultPage_infoSection_deliveryCenterIdsTitle: 'Delivery center IDs',
  adminInvitationPage_resultPage_infoSection_logisticOperatorIdsTitle: 'Logistic operator IDs',
  adminInvitationPage_resultPage_infoSection_permissionGroupsTitle: 'Grupos permitidos',
  adminInvitationPage_resultPage_infoSection_supportedCountriesTitle: 'Países permitidos',
  adminInvitationPage_resultPage_invited: 'Convidado',
  adminInvitationPage_resultPage_partial: 'Partial',
  adminInvitationPage_resultPage_successMessage:
    'Usuários adicionados e e-mails de convites enviados com sucesso.',
  appName: 'BEES User Management',
  customerInvitationPage_backButton: 'Voltar',
  customerInvitationPage_emailFromAdminUser: "Admin users can't be invited",
  customerInvitationPage_emailIsRequired: 'Email address is required',
  customerInvitationPage_emailStep_inputLabel: 'E-mail',
  customerInvitationPage_emailStep_subtitle: 'Primeiro, insira o e-mail do usuário.',
  customerInvitationPage_errorModal_duplicatedInsertError_subtitle:
    "This email is already registered and can't be invited again.",
  customerInvitationPage_errorModal_duplicatedInsertError_title: 'You can’t add this user',
  customerInvitationPage_errorModal_generalError_subtitle:
    'Estamos passando uma instabilidade temporária. Por favor, tente novamente.',
  customerInvitationPage_errorModal_generalError_title: 'O usuário não pôde ser adicionado',
  customerInvitationPage_existUserStep_subtitle:
    'Encontramos um usuário com este e-mail. Você pode adicionar uma conta a este usuário.',
  customerInvitationPage_finishButton: 'Enviar',
  customerInvitationPage_invalidEmailFormat: 'Insira um e-mail válido (ex: marcus@gmail.com)',
  customerInvitationPage_nameStep_inputLabel: 'Nome',
  customerInvitationPage_nameStep_inputPlaceholder: 'New User',
  customerInvitationPage_nameStep_subtitle:
    'Insira as seguintes informações para fazer o pré-cadastro do novo usuário.',
  customerInvitationPage_nextButton: 'Próximo',
  customerInvitationPage_processingButton: 'Processando...',
  customerInvitationPage_selectedAccount_middleTitle: 'Adicionar a',
  customerInvitationPage_selectedAccount_subtitle: 'ID da conta {accountId}',
  customerInvitationPage_steps: 'Passo {step} de 2',
  customerInvitationPage_title: 'Adicionar novo usuário',
  discardChangesModal_confirmButton: 'Sim, fechar',
  discardChangesModal_keepEditingButton: 'Não, continuar editando',
  discardChangesModal_subtitle:
    'Todas as informações inseridas serão perdidas e esta ação não poderá ser desfeita.',
  discardChangesModal_title: 'Tem certeza que deseja fechar?',
  invitationPage_administratorCard_subtitle: 'Gerencia usuários, produtos, rotinas e mais.',
  invitationPage_administratorCard_title: 'Administrador',
  invitationPage_step: 'Passo 1',
  invitationPage_subtitle: 'Escolha uma categoria de usuário para continuar.',
  invitationPage_title: 'Adicionar novo usuário',
  test_missing: 'Apenas opção em português brasileiro disponível',
  test_translate: 'Tradução',
};
