import { enUS, es419, ptBR } from './languages';
import { LocaleMessagesMap, SupportedLocales } from './types';

export const supportedLocales = ['en-US', 'pt-BR', 'es-419'] as const;

export const DEFAULT_LANGUAGE = 'en-US';

export const isSupportedLocale = (locale: string): locale is SupportedLocales =>
  supportedLocales.includes(locale as SupportedLocales);

export const messages: LocaleMessagesMap = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es419,
};
