import { usePreferredLanguage } from 'admin-portal-shared-services';
import React from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import { DEFAULT_LANGUAGE, isSupportedLocale, messages } from './consts';
import { IntlProviderProps, LocaleMessagesMap, MessageMap, SupportedLocales } from './types';

export const mergeMessages = (
  messagesInput: LocaleMessagesMap,
  selectedLocale: SupportedLocales
): MessageMap => {
  const defaultMessages = messagesInput[DEFAULT_LANGUAGE];
  const localeMessages = messagesInput[selectedLocale];
  return { ...defaultMessages, ...localeMessages };
};

export const IntlProvider = ({ children = null }: IntlProviderProps): JSX.Element => {
  const preferredLanguage = usePreferredLanguage();
  const locale = isSupportedLocale(preferredLanguage) ? preferredLanguage : DEFAULT_LANGUAGE;
  const mergedMessages = mergeMessages(messages, locale);

  return (
    <NativeProvider locale={locale} defaultLocale={DEFAULT_LANGUAGE} messages={mergedMessages}>
      {children}
    </NativeProvider>
  );
};
