import React, { ChangeEvent } from 'react';
import { Input as StyledInput, Label, ValidationMessage } from './Styles';

interface IProps {
  name: string;
  type: string;
  value: string;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  placeholder?: string;
  onHandleBlur?: () => void;
}

export const Input = ({
  type = 'text',
  error = false,
  value,
  errorMessage,
  onHandleChange,
  label,
  onHandleBlur,
  disabled,
  placeholder,
  ...rest
}: IProps): JSX.Element => {
  return (
    <>
      <Label error={error}>{label}</Label>
      <ValidationMessage data-testid="error-message">{error && errorMessage}</ValidationMessage>
      <StyledInput
        type={type}
        error={error}
        value={value}
        onChange={onHandleChange}
        onBlur={onHandleBlur}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus
        {...rest}
      />
    </>
  );
};
