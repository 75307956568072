import { styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled('div')({});

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '0 30px',
    width: '100%',
  },
});
