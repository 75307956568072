import React, { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { Input } from '../../../../../components/Forms/Input/Input';
import * as styled from '../../Styled';

interface IProps {
  email: string;
  error?: string;
  onHandleChangeEmail: (event: ChangeEvent<HTMLInputElement>) => void;
  onHandleBlurEmail?: () => void;
}

export const EmailStep = ({
  email,
  onHandleChangeEmail,
  error,
  onHandleBlurEmail,
}: IProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <>
      <styled.SubTitle>
        {formatMessage({ id: 'customerInvitationPage_emailStep_subtitle' })}
      </styled.SubTitle>
      <styled.Panel>
        <Input
          type="email"
          error={!!error}
          label={formatMessage({ id: 'customerInvitationPage_emailStep_inputLabel' })}
          value={email}
          errorMessage={error}
          onHandleChange={onHandleChangeEmail}
          onHandleBlur={onHandleBlurEmail}
          name="email"
          data-testid="input-email"
          aria-label="email"
        />
      </styled.Panel>
    </>
  );
};
