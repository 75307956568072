export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function getToken(): string {
  const token = localStorage.getItem('authHeader');
  return token as string;
}

export function getCountry(): string {
  const token = getToken().split(' ')[1];
  const decodedToken: { country: string } = parseJwt(token);
  const { country } = decodedToken;
  return country;
}

export function getCountries(): string[] {
  const token = getToken().split(' ')[1];
  const decodedToken: { country: string; supportedCountries: string[] } = parseJwt(token);
  const { country, supportedCountries } = decodedToken;
  let myCountries: string[] = [country, ...supportedCountries];
  myCountries = [...new Set(myCountries)];
  return myCountries;
}
