import { IRegisterForceUserResponse } from '__types/register-user-payload.types';
import React, { useState } from 'react';
import { AuditLogsForceInvitationParams, AuditLogsUserTypes } from 'services/AuditLogs/types';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import {
  CountriesItem1,
  Country,
  EmailItem1,
  PermissionsItem5,
  ScreenName1,
} from '../../../../analytics';
import { SegmentService } from '../../../helpers';
import { postAuditLogsForceInvitation } from '../../../services/AuditLogs/AuditLogsService';
import { InvitationService } from '../../../services/Invitation/InvitationService';
import { Container } from './AdminForceRegistration.styles';
import {
  ForceFormFields,
  RegistrationForceForm,
} from './components/RegistrationForceForm/RegistrationForceForm';
import { ResultsForcePage } from './components/ResultsForcePage/ResultsForcePage';

export interface ResultsForce extends Omit<ForceFormFields, 'users'> {
  users: IRegisterForceUserResponse[];
}

export type AdminForceRegistrationStatus = 'idle' | 'processing' | 'resolved';

export const AdminForceRegistration = (): JSX.Element => {
  const toastService = useToast();
  const { formatMessage } = useIntl();
  const invitationService = new InvitationService();
  const [status, setStatus] = useState<AdminForceRegistrationStatus>('idle');
  const [userResults, setUserResults] = useState<ResultsForce | undefined>();
  const [isForceForm, setIsForceForm] = useState<boolean>(false);

  const handleSubmit = async (formValues: ForceFormFields) => {
    setStatus('processing');

    try {
      const email: string[] = formValues.users.map((user) => user.email);

      const adminUserEventData = {
        screen_name: ScreenName1.AdminUserInvitation,
        permissions: formValues.permissionGroups as unknown as PermissionsItem5[],
        country: formValues.country as Country,
        countries: formValues.supportedCountries as unknown as CountriesItem1[],
        email: email as unknown as EmailItem1[],
      };

      SegmentService.adminUserInvitationSubmitted(adminUserEventData);

      const { data } = isForceForm
        ? await invitationService.sendForceFormInvitations(formValues)
        : await invitationService.sendAdminForceInvitations(formValues);
      setUserResults({ ...formValues, users: data });
      if (data[0]?.id) {
        const auditLogsMetadata: AuditLogsForceInvitationParams = getForceInviationAuditLogsParams(
          formValues,
          data[0].id
        );
        await postAuditLogsForceInvitation(auditLogsMetadata);
      }
      setStatus('resolved');
    } catch (error) {
      setStatus('idle');
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({
          id: 'adminForceInvitationPage_registrationForm_submitButton_error',
        }),
      });
    }
  };

  const getForceInviationAuditLogsParams = (formValues: ForceFormFields, userId: string) => ({
    metadata: {
      wasApprovedBySystem: true,
      country: formValues.country,
      userType: 'FORCE' as AuditLogsUserTypes,
      permissionGroup: {
        added: formValues.permissionGroups,
      },
      supportedCountry: {
        added: formValues.supportedCountries,
      },
      vendorId: {
        added: formValues.vendors,
      },
    },
    userId,
  });

  return (
    <Container>
      {(status === 'idle' || status === 'processing') && (
        <RegistrationForceForm
          onSubmit={handleSubmit}
          status={status}
          setIsForceForm={setIsForceForm}
        />
      )}
      {status === 'resolved' && userResults && (
        <ResultsForcePage data={userResults} setStatus={setStatus} />
      )}
    </Container>
  );
};
