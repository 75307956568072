import React, { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input } from '../../../../../components/Forms/Input/Input';
import { AccountService } from '../../../../../services/Account/AccountService';
import * as styled from '../../Styled';
import { SelectedAccount } from '../SelectedAccount/SelectedAccount';

interface IProps {
  name: string;
  accountName: string;
  customerAccountId: string;
  country: string;
  disabled?: boolean;
  onHandleChangeName: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface IAccount {
  name: string;
  accountId: string;
  customerAccountId: string;
}

export const NameStep = ({
  name,
  country,
  customerAccountId,
  accountName,
  disabled,
  onHandleChangeName,
}: IProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [, setSelectedAccount] = useState<IAccount | undefined>(undefined);

  useEffect(() => {
    const service = new AccountService();
    service.getAccount(country, customerAccountId).then((result) => {
      const { data } = result;
      if (data) setSelectedAccount(data);
    });
  }, [country, customerAccountId]);

  return (
    <>
      <styled.SubTitle>
        {formatMessage({ id: 'customerInvitationPage_nameStep_subtitle' })}
      </styled.SubTitle>

      <styled.Panel>
        <Input
          type="text"
          label={formatMessage({ id: 'customerInvitationPage_nameStep_inputLabel' })}
          value={name}
          onHandleChange={onHandleChangeName}
          name="email"
          disabled={disabled}
          data-testid="input-name"
          placeholder={formatMessage({
            id: 'customerInvitationPage_nameStep_inputPlaceholder',
          })}
          aria-label="name"
        />
        <SelectedAccount accountName={accountName} customerAccountId={customerAccountId} />
      </styled.Panel>
    </>
  );
};
