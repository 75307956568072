import { X } from '@admin-portal-shared-components/icons';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { SegmentService } from '../../helpers';
import { ModalDiscardChanges } from '../Feedbacks/ModalDiscardChanges/ModalDiscardChanges';
import * as Styled from './styles';

export const InvitationPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [modalDiscardChanges, setModalDiscardChanges] = React.useState<boolean>(false);

  const handleClickRedirectToInitialWorkflow = () => {
    SegmentService.userInvitationCanceled();
    history.push('/users');
  };

  useEffect(() => {
    SegmentService.addUserViewed();
  }, []);

  const handleOpenModalDiscardChanges = () => {
    setModalDiscardChanges(true);
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.CloseButton
          aria-label="close invite workflow"
          onClick={handleOpenModalDiscardChanges}
          data-testid="close invite workflow"
        >
          <X size="large" />
        </Styled.CloseButton>

        <Styled.Header>
          <Styled.Step>{formatMessage({ id: 'invitationPage_step' })}</Styled.Step>
          <Styled.Title>{formatMessage({ id: 'invitationPage_title' })}</Styled.Title>
          <Styled.Description>
            {formatMessage({ id: 'invitationPage_subtitle' })}
          </Styled.Description>
        </Styled.Header>

        <ModalDiscardChanges
          openModal={modalDiscardChanges}
          handleButtonCloseModal={() => setModalDiscardChanges(false)}
          handleKeepEditing={() => setModalDiscardChanges(false)}
          handleCancel={handleClickRedirectToInitialWorkflow}
        />
      </Styled.Wrapper>
    </>
  );
};
