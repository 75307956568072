import { Card, Input, Paragraph } from '@hexa-ui/components';
import { FormHelperText } from '@material-ui/core';
import styled from 'styled-components';

export const WrapperVendorIds = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const LabelChips = styled.label``;

export const WrapperChipsVendorIds = styled(Card as any)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 12px;
  overflow-y: auto;
  padding: 16px 8px;
  border-radius: 4px;
`;

export const InputChipsWrapper = styled.div`
  * {
    outline: none !important;
  }
`;

export const InputChips = styled(Input as any)`
  width: 300px;
  height: 32px;
`;

export const ErrorMessage = styled(Paragraph as any)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #f44336;
  font-size: 12px;
  font-family: 'Roboto', 'Relvetica', 'Arial', sans-serif;
`;

export const WrapperVendorIdSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  p {
    align-self: flex-end;
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`;
