import segmentAnalytics, {
  AdminUserInvitationResult,
  AdminUserInvitationSubmitted,
  CustomerUserInvitationResult,
  CustomerUserInvitationSubmitted,
  ScreenName1,
} from '../../../analytics/index';

export class SegmentService {
  static adminUserInvitationCanceled(): void {
    segmentAnalytics.adminUserInvitationCanceled({
      screen_name: ScreenName1.AdminUserInvitation,
    });
  }

  static adminUserCategorySelected(): void {
    segmentAnalytics.adminUserCategorySelected({ screen_name: ScreenName1.AdminUserInvitation });
  }

  static userInvitationCanceled(): void {
    segmentAnalytics.userInvitationCanceled({ screen_name: ScreenName1.UserInvitation });
  }

  static adminUserInvitationResult(data: AdminUserInvitationResult): void {
    segmentAnalytics.adminUserInvitationResult(data);
  }

  static adminUserInvitationRestarted(): void {
    segmentAnalytics.adminUserInvitationRestarted({
      screen_name: ScreenName1.AdminUserInvitation,
    });
  }

  static adminUserInvitationFinished(): void {
    segmentAnalytics.adminUserInvitationFinished({
      screen_name: ScreenName1.AdminUserInvitation,
    });
  }

  static adminUserInvitationSubmitted(eventData: AdminUserInvitationSubmitted): void {
    segmentAnalytics.adminUserInvitationSubmitted(eventData);
  }

  static addUserViewed(): void {
    segmentAnalytics.userInvitationViewed({ screen_name: ScreenName1.UserDetails1 });
  }

  static customerUserInvitationCanceled(cancelButton: boolean): void {
    segmentAnalytics.customerUserInvitationCanceled({
      cancel_button: cancelButton,
      screen_name: ScreenName1.CustomerUserInvitation,
    });
  }

  static customerInvitationSubmitted(data: CustomerUserInvitationSubmitted): void {
    segmentAnalytics.customerUserInvitationSubmitted(data);
  }

  static customerInvitationResult(data: CustomerUserInvitationResult): void {
    segmentAnalytics.customerUserInvitationResult(data);
  }

  static customerInvitationRestarted(): void {
    segmentAnalytics.customerUserInvitationRestarted({
      screen_name: ScreenName1.CustomerUserInvitation,
    });
  }

  static customerInvitationFinished(): void {
    segmentAnalytics.customerUserInvitationFinished({
      screen_name: ScreenName1.CustomerUserInvitation,
    });
  }
}
