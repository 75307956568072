import React from 'react';
import { initialLetters } from '../../helpers/InitialLetters/initialLetters';
import * as Styled from './styles';

type IAvatar = { name: string; variant?: string } & typeof defaultProps;

export function Avatar({ name, variant }: IAvatar): JSX.Element {
  return (
    <Styled.Avatar variant={variant} data-testid="avatar">
      {initialLetters(name)}
    </Styled.Avatar>
  );
}

const defaultProps = {
  variant: 'small',
};

Avatar.defaultProps = defaultProps;
