import { styled } from '@material-ui/core';

export const StepIndicator = styled('div')({
  fontFamily: 'Work Sans',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
});

export const Title = styled('div')({
  fontFamily: 'Barlow',
  fontSize: '32px',
  lineHeight: '40px',
  marginBottom: '8px',
});

export const Subtitle = styled('div')({
  fontFamily: 'Work Sans',
  fontSize: '16px',
  lineHeight: '24px',
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
