import { ResultsForce } from 'pages/Invitation/AdminForceRegistration/AdminForceRegistration';
import React from 'react';
import { useIntl } from 'react-intl';
import { BoxInformation } from 'supplier-portal-shared-components';
import { formatCountry, AllSupportedCountryKeys } from 'country-utils';
import { useStyles } from './InfoSection.styles';

interface Props {
  data: ResultsForce;
}

export const InfoSection = ({
  data: { permissionGroups, supportedCountries, country },
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const { root, infos, primaryInfo, secondaryInfo } = useStyles();
  const permissionGroupsDescription = permissionGroups.join(',\r\n');
  const supportedCountriesDescription = supportedCountries
    .map((countryAbbreviation: string) =>
      formatCountry(countryAbbreviation as AllSupportedCountryKeys)
    )
    .join(',\r\n');
  return (
    <div className={root}>
      <div className={infos}>
        <BoxInformation
          customClasses={primaryInfo}
          description={permissionGroupsDescription}
          noMargin
          title={formatMessage({
            id: 'adminInvitationPage_resultPage_infoSection_permissionGroupsTitle',
          })}
        />
        <BoxInformation
          customClasses={secondaryInfo}
          description={formatCountry(country as AllSupportedCountryKeys)}
          noMargin
          title={formatMessage({
            id: 'adminInvitationPage_resultPage_infoSection_countryTitle',
          })}
        />
        <BoxInformation
          customClasses={secondaryInfo}
          description={supportedCountriesDescription}
          noMargin
          title={formatMessage({
            id: 'adminInvitationPage_resultPage_infoSection_supportedCountriesTitle',
          })}
        />
      </div>
    </div>
  );
};
