/* eslint-disable no-shadow */
import { useAuthenticationService } from 'admin-portal-shared-services';
import React, { useCallback, useEffect, useState, useRef, useReducer } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button } from '@hexa-ui/components';
import { ControlledSelect } from '../../../../../components/ControlledSelect';
import { SegmentService } from '../../../../../helpers';
import {
  useIsGlobalManager,
  useUserGroups,
} from '../../../../../hooks/useIsGlobalManager/useIsGlobalManager';
import { IGroup } from '../../../../../interfaces/IGroup';
import { GroupsService } from '../../../../../services/Groups/groupsService';
import { InvitationService } from '../../../../../services/Invitation/InvitationService';
import { emailRegex } from '../../../../../utils/validations';
import { StepperTitle } from '../../../components/StepperTitle/StepperTitle';
import { AdminForceRegistrationStatus } from '../../AdminForceRegistration';
import { FormAdminPortal } from '../FormAdminPortal/FormAdminPortal';
import {
  ButtonContainer,
  Card,
  FirstSection,
  FormContainer,
  LineDivider,
  TitleContainer,
} from '../../AdminForceRegistration.styles';
import { FormForce } from '../FormForce/FormForce';

export interface UserFormData {
  firstName: string;
  lastName: string;
  email: string;
  bdrId?: string;
  managedBy?: string;
  managedById?: string;
  managedByAppName?: string;
  managedByCountry?: string;
}

export enum OriginApp {
  adminportal = 'Admin Force User',
  force = 'Frontline User',
}

export enum BeesForceGroups {
  adminportal = 'BeesForceAdmin',
  force = 'BeesForce',
}

export type ForceFormFields = {
  originApp: string;
  users: UserFormData[];
  country: string;
  supportedCountries: string[];
  permissionGroups: string[];
  vendors: string[];
};

interface Props {
  onSubmit: (data: ForceFormFields) => void;
  status: AdminForceRegistrationStatus;
  setIsForceForm: (isFrontlineForm: boolean) => void;
}

export const RegistrationForceForm = ({
  onSubmit,
  status,
  setIsForceForm: setIsFrontlineForm,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const userEmailValidationTimeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const history = useHistory();
  const isGlobalManager = useIsGlobalManager();
  const userGroups = useUserGroups();
  const invitationService = new InvitationService();
  const authenticationService = useAuthenticationService();
  const userVendorId = authenticationService.getVendorId();

  const { user_country: userCountry } = authenticationService.getUserCountryAndLanguage();

  const handleClickBackInitialWorkflow = () => {
    SegmentService.adminUserInvitationCanceled();
    history.push('/invitations');
  };

  const methods = useForm<ForceFormFields>({
    mode: 'onChange',
    defaultValues: {
      originApp: undefined,
      permissionGroups: [],
      users: [{ firstName: '', email: '' }],
      country: userCountry!,
      supportedCountries: [userCountry!],
      vendors: [userVendorId],
    },
  });
  const {
    control,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { isValid },
    reset,
    resetField,
  } = methods;
  const [authorizationGroups, setAuthorizationGroups] = useState<IGroup[]>([]);
  const [isValidatingUserEmails, dispatchIsValidatingUserEmails] = useReducer(
    reducerIsValidatingUserEmails,
    []
  );
  const [isValidatingManagedByEmails, dispatchIsValidatingManagedByEmails] = useReducer(
    reducerIsValidatingUserEmails,
    []
  );
  const isSubmitButtonDisabled =
    !isValid ||
    status === 'processing' ||
    isValidatingUserEmails.some((isValidating) => isValidating) ||
    isValidatingManagedByEmails.some((isValidating) => isValidating);
  const { originApp } = getValues();
  const isUserTypeSelectedForce = originApp === 'force';
  const isUserTypeSelectedAdminPortal = originApp === 'adminportal';

  function reducerIsValidatingUserEmails(
    state: boolean[],
    action: { isValidating: boolean; index: number }
  ) {
    const { index, isValidating } = action;
    const isValidatingEmailUpdated = [...state];
    isValidatingEmailUpdated[index] = isValidating;
    return isValidatingEmailUpdated;
  }

  const formatGroupName = ({ app, name }: IGroup) => (app ? `${app}.${name}` : name);

  const handleUserTypeChange = () => {
    resetField('permissionGroups');
    resetField('users');
    resetField('country');
    resetField('supportedCountries');
    resetField('vendors');
  };

  const handleFilterPermissionGroupsByUserType = () => {
    const filteredGroups: string[] = [];
    const groups = authorizationGroups.map((group) => formatGroupName(group));

    groups.forEach((group) => {
      if (group.split('.').shift() === Object(BeesForceGroups)[originApp])
        filteredGroups.push(group);
    });

    return filteredGroups;
  };

  const getPermissionGroupsOptions = () => {
    return handleFilterPermissionGroupsByUserType()
      .map((group) => group)
      .sort((a, b) => a.localeCompare(b));
  };

  const fetchAuthorizationGroups = useCallback(async () => {
    const groupService = new GroupsService();
    const {
      data: { data },
    } = await groupService.getGroups();
    setAuthorizationGroups(data);
  }, [setAuthorizationGroups]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (isGlobalManager) {
      fetchAuthorizationGroups();
    } else {
      setAuthorizationGroups(userGroups || []);
    }
  }, [isGlobalManager, userGroups, fetchAuthorizationGroups, userVendorId]);

  const validateUserEmail = async (value: string, index: number): Promise<void> => {
    const debounceDelayMs = 500;
    clearTimeout(userEmailValidationTimeoutsRef.current[index]);
    userEmailValidationTimeoutsRef.current[index] = setTimeout(async () => {
      let isUserEmailValid = true;
      let errorMessage = '';

      dispatchIsValidatingUserEmails({ isValidating: true, index });

      const isValidEmail = emailRegex.test(value);
      if (isValidEmail) {
        const [wasAlreadyInvited, isCustomer] = await Promise.all([
          invitationService.adminUserAlreadyExists({
            country: String(userCountry),
            value,
          }),
          invitationService.getCustomerUser({
            country: String(userCountry),
            value,
          }),
        ]);

        if (!wasAlreadyInvited && !isCustomer) {
          isUserEmailValid = true;
        } else {
          isUserEmailValid = false;
          errorMessage = formatMessage({
            id: 'adminInvitationPage_registrationForm_textField_userAlreadyExistError',
          });
        }
      } else {
        isUserEmailValid = false;
        errorMessage = formatMessage({
          id: 'adminInvitationPage_registrationForm_validateEmail_errorMessage',
        });
      }

      if (isUserEmailValid) {
        clearErrors(`users.${index}.email`);
      } else {
        setError(`users.${index}.email`, { message: errorMessage });
      }

      dispatchIsValidatingUserEmails({ isValidating: false, index });
    }, debounceDelayMs);
  };

  const validateManagerByEmail = async (value: string, index: number): Promise<void> => {
    const debounceDelayMs = 500;
    clearTimeout(userEmailValidationTimeoutsRef.current[index]);
    userEmailValidationTimeoutsRef.current[index] = setTimeout(async () => {
      let isUserEmailValid = true;
      let errorMessage = '';
      let adminUserData;

      dispatchIsValidatingManagedByEmails({ isValidating: true, index });

      const isValidEmail = emailRegex.test(value);
      if (isValidEmail) {
        adminUserData = await invitationService
          .getAdminUserData({
            country: String(userCountry),
            value,
          })
          .then((result) => {
            return result?.data;
          });

        if (adminUserData) {
          isUserEmailValid = true;
        } else {
          isUserEmailValid = false;
          errorMessage = formatMessage({
            id: 'adminForceInvitationPage_registrationForm_textField_managerDoNotExistError',
          });
        }
      } else {
        isUserEmailValid = false;
        errorMessage = formatMessage({
          id: 'adminInvitationPage_registrationForm_validateEmail_errorMessage',
        });
      }

      if (isUserEmailValid) {
        clearErrors(`users.${index}.managedBy`);
        setValue(`users.${index}.managedById`, adminUserData.id);
        setValue(`users.${index}.managedByAppName`, adminUserData.appName);
        setValue(`users.${index}.managedByCountry`, adminUserData.country);
      } else {
        setError(`users.${index}.managedBy`, { message: errorMessage });
        resetField(`users.${index}.managedById`);
        resetField(`users.${index}.managedByAppName`);
        resetField(`users.${index}.managedByCountry`);
      }

      dispatchIsValidatingManagedByEmails({ isValidating: false, index });
    }, debounceDelayMs);
  };

  const submit = (data: ForceFormFields) => {
    const updatedData = {
      ...data,
      vendors: Array.isArray(data.vendors) ? data.vendors : [data.vendors],
    };
    onSubmit(updatedData);
  };

  function renderForm(): JSX.Element | null {
    if (isUserTypeSelectedForce) {
      setIsFrontlineForm(true);
      return (
        <FormForce
          onSubmit={submit}
          isValidatingUserEmails={isValidatingUserEmails}
          permissionGroups={getPermissionGroupsOptions()}
          validateUserEmail={validateUserEmail}
          isValidatingManagedByEmails={isValidatingManagedByEmails}
          validateManagedByEmail={validateManagerByEmail}
        />
      );
    }

    if (isUserTypeSelectedAdminPortal) {
      setIsFrontlineForm(false);
      return (
        <FormAdminPortal
          onSubmit={submit}
          isValidatingUserEmails={isValidatingUserEmails}
          permissionGroups={getPermissionGroupsOptions()}
          validateUserEmail={validateUserEmail}
          isValidatingManagedByEmails={isValidatingManagedByEmails}
          validateManagedByEmail={validateManagerByEmail}
        />
      );
    }

    return null;
  }

  return (
    <FormContainer>
      <StepperTitle
        currentStep={2}
        stepMax={2}
        title={formatMessage({ id: 'adminForceInvitationPage_registrationForm_stepper_title' })}
        subtitle={formatMessage({
          id: 'adminInvitationPage_registrationForm_stepper_subtitle',
        })}
      />

      <Card>
        <TitleContainer>
          {formatMessage({ id: 'adminInvitationPage_registrationForm_userType_card_title' })}
        </TitleContainer>
        <LineDivider />

        <FirstSection>
          <ControlledSelect
            controllerProps={{
              control,
              name: 'originApp',
              rules: { required: 'Required' },
            }}
            autocompleteProps={{
              fullWidth: true,
              multiple: false,
              closeIcon: null,
              disableCloseOnSelect: false,
              options: Object.keys(OriginApp),
              getOptionLabel: (currentOriginApp) => Object(OriginApp)[String(currentOriginApp)],
              onInputChange: () => handleUserTypeChange(),
            }}
            textFieldProps={{
              label: formatMessage({
                id: 'adminInvitationPage_registrationForm_userType_inputLabel',
              }),
              style: { borderRadius: '12px' },
            }}
          />
        </FirstSection>
      </Card>

      <FormProvider {...methods}>{renderForm()}</FormProvider>

      <ButtonContainer>
        <Button
          disabled={status === 'processing'}
          variant="secondary"
          type="button"
          data-testid="back button"
          onClick={handleClickBackInitialWorkflow}
        >
          {formatMessage({
            id: 'adminInvitationPage_registrationForm_backButton',
          })}
        </Button>

        <Button
          disabled={isSubmitButtonDisabled}
          variant="primary"
          type="submit"
          data-testid="form-submit-button"
          form="usersForm"
          isLoading={status === 'processing'}
        >
          {formatMessage({
            id: 'adminInvitationPage_registrationForm_finishButton',
          })}
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
};
