import { useAuthenticationService as authService } from 'admin-portal-shared-services';
import axios from 'axios';
import { IResponse } from 'interfaces/IResponse';
import { IUser } from 'interfaces/IUser';
import { ForceFormFields } from 'pages/Invitation/AdminForceRegistration/components/RegistrationForceForm/RegistrationForceForm';

import {
  Group,
  IRegisterForceUserPayloadRequest,
  IRegisterForceUserResponse,
  IRegisterUserPayloadRequest,
  IRegisterUserResponse,
} from '__types/register-user-payload.types';
import { FormFields } from 'pages/Invitation/AdminRegistration/components/RegistrationForm/RegistrationForm';
import { v4 } from 'uuid';
import { getToken } from '../../helpers';
import { getApiHost } from '../host/HostService';

interface ISendCustomerInvitationParams {
  country: string;
  vendorAccountId: string;
  vendorId: string;
  name: string;
  email: string;
  managedBy?: string;
}

export interface ISendCustomerInvitationResponse {
  status: string;
  message: string;
  data: {
    userId: string;
  };
}

interface IGetUserDetailsParams {
  country: string;
  value: string;
}
export type AdminUserAlreadyExist = IGetUserDetailsParams;

export type GetCustomerUser = IGetUserDetailsParams;

enum ApplicationNames {
  adminportal = 'Bees Hub',
  cmsadmin = 'Bees CMS',
  deliveradmin = 'Bees Deliver',
  force = 'Force BDR',
  oncustomer = 'Bees Grow',
  social = 'Bees Social',
  supplier = 'Bees Partner',
}

// TODO: refactor this class to eslint can be enabled again
export class InvitationService {
  // eslint-disable-next-line class-methods-use-this
  async sendCustomerInvitation(
    sendParams: ISendCustomerInvitationParams
  ): Promise<ISendCustomerInvitationResponse> {
    const { data } = await authService()
      .enhancedAxios(axios.create())
      .request({
        headers: {
          Authorization: getToken(),
          country: sendParams.country,
          requestTraceId: v4(),
          'content-type': 'application/json',
        },
        url: `${getApiHost()}/invitation-mfe/customer-user`,
        method: 'POST',
        data: sendParams,
      });

    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  async adminUserAlreadyExists({
    country,
    value,
  }: AdminUserAlreadyExist): Promise<boolean | undefined> {
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const axiosInstance = authService().enhancedAxios(axios.create());

    return Promise.allSettled(
      Object.keys(ApplicationNames).map((appName) => {
        const params = { value, appName };
        return axiosInstance.get(`${getApiHost()}/user-mfe/users/details`, { headers, params });
      })
    )
      .then((results) => results.some((result) => result.status === 'fulfilled'))
      .catch(() => undefined);
  }

  // eslint-disable-next-line class-methods-use-this
  async getAdminUserData({ country, value }: AdminUserAlreadyExist): Promise<IUser | undefined> {
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    const axiosInstance = authService().enhancedAxios(axios.create());

    return Promise.allSettled(
      Object.keys(ApplicationNames).map((appName) => {
        const params = { value, appName };
        return axiosInstance.get(`${getApiHost()}/user-mfe/users/details`, { headers, params });
      })
    )
      .then((results) => results.find((result) => result.status === 'fulfilled')?.value?.data)
      .catch(() => undefined);
  }

  // eslint-disable-next-line class-methods-use-this
  async getCustomerUser({ country, value }: GetCustomerUser): Promise<IUser | undefined> {
    const params = { value, appName: 'b2b', ignoreCountry: true };
    const headers = {
      country,
      requestTraceId: v4(),
      Authorization: getToken(),
    };

    return authService()
      .enhancedAxios(axios.create())
      .get(`${getApiHost()}/user-mfe/users/details`, { headers, params })
      .then(({ data }) => data?.data)
      .catch(() => undefined);
  }

  // eslint-disable-next-line class-methods-use-this
  async sendAdminForceInvitations(
    formValues: ForceFormFields
  ): Promise<IResponse<IRegisterForceUserResponse[]>> {
    const { users, country, supportedCountries, permissionGroups, vendors, originApp } = formValues;

    const payload: IRegisterForceUserPayloadRequest = {
      supportedCountries,
      country,
      vendors,
      originApp,
      identities: users
        .filter((user) => user.email)
        .map((user) => ({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          bdrId: user?.bdrId,
          managedBy: user?.managedBy,
          managedById: user?.managedById,
          managedByAppName: user?.managedByAppName,
          managedByCountry: user?.managedByCountry,
          preferredLanguage: user?.bdrId && country === 'BR' ? 'pt-BR' : undefined,
        })),
      groups: permissionGroups.map<Group>((value: string) => {
        const group = value.split('.').reverse();
        return {
          name: group[0],
          app: group[1],
        };
      }),
    };

    const { data } = await authService()
      .enhancedAxios(axios.create())
      .request({
        headers: {
          country,
          requestTraceId: v4(),
          Authorization: getToken(),
        },
        url: `${getApiHost()}/invitation-mfe/admin-user-force`,
        method: 'POST',
        data: payload,
      });
    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  async sendForceFormInvitations(
    formValues: ForceFormFields
  ): Promise<IResponse<IRegisterForceUserResponse[]>> {
    const { users, country, supportedCountries, permissionGroups, vendors, originApp } = formValues;

    const payload: IRegisterForceUserPayloadRequest = {
      supportedCountries,
      country,
      vendors,
      originApp,
      identities: users
        .filter((user) => user.email)
        .map((user) => ({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          bdrId: user?.bdrId,
          managedBy: user?.managedBy,
          managedById: user?.managedById,
          managedByAppName: user?.managedByAppName,
          managedByCountry: user?.managedByCountry,
          preferredLanguage: user?.bdrId && country === 'BR' ? 'pt-BR' : undefined,
        })),
      groups: permissionGroups.map<Group>((value: string) => {
        const group = value.split('.').reverse();
        return {
          name: group[0],
          app: group[1],
        };
      }),
    };

    const { data } = await authService()
      .enhancedAxios(axios.create())
      .request({
        headers: {
          country,
          requestTraceId: v4(),
          Authorization: getToken(),
        },
        url: `${getApiHost()}/invitation-mfe/admin-user-force`,
        method: 'POST',
        data: payload,
      });
    return data;
  }
}
