import React from 'react';
import { useIntl } from 'react-intl';
import { Container, StepIndicator, Subtitle, Title } from './StepperTitle.styles';

interface Props {
  currentStep: number;
  stepMax: number;
  title: string;
  subtitle: string;
}

export const StepperTitle = ({ currentStep, title, subtitle, stepMax }: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <StepIndicator>
        {formatMessage(
          { id: 'customerInvitationPage_steps' },
          { step: currentStep, steps: stepMax }
        )}
      </StepIndicator>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
};
