/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv';
import * as Segment from './segment';

export interface CompaniesItem {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface PermissionsItem {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface AccountDeselection {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem[] | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_id: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface AccountRemovalCanceled {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}
export interface AccountRemovalStarted {
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The number of selected accounts. Sample: '1, 3, 5'
   */
  selected_accounts: number | null;
}
export interface CompaniesItem1 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface PermissionsItem1 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem1 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface AccountRemovalSubmissionResult {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem1[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem1[] | null;
  /**
   * The number of fail removal. Sample: 1,2,3,4
   */
  removal_fail_count: number | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The number of selected accounts. Sample: '1, 3, 5'
   */
  selected_accounts: number | null;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem1[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_id: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface CompaniesItem2 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface PermissionsItem2 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem2 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface AccountRemovalSubmitted {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem2[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem2[] | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The number of selected accounts. Sample: '1, 3, 5'
   */
  selected_accounts: number | null;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem2[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_id: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface CompaniesItem3 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface PermissionsItem3 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem3 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface AccountSelection {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem3[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem3[] | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem3[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_id: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface AdminUserCategorySelected {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface AdminUserInvitationCanceled {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface AdminUserInvitationFinished {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface AdminUserInvitationRestarted {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface CountriesItem {
  /**
   * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
   */
  supported_country: SupportedCountry;
}
export interface DeliveryItem {
  /**
   * The delivery center id generated. Sample: BK32
   */
  delivery_center_id: string | null;
}
export interface EmailItem {
  /**
   * The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  email_list: string | null;
}
export interface LogisticItem {
  /**
   * The Logistic operator ID. Sample: 460751
   */
  logistic_operator_id: string | null;
}
export interface PermissionsItem4 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface AdminUserInvitationResult {
  /**
   * The number of fails associations. Sample: 1,2,3,4
   */
  association_fail_count: number | null;
  /**
   * The number of success associations. Sample: 1,2,3,4
   */
  association_success_count: number | null;
  /**
   * The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  countries: CountriesItem[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  delivery: DeliveryItem[] | null;
  /**
   * The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  email: EmailItem[] | null;
  /**
   * The number of fails invitations. Sample: 1,2,3,4
   */
  invitation_fail_count: number | null;
  /**
   * The number of success invitations. Sample: 1,2,3,4
   */
  invitation_success_count: number | null;
  /**
   * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  logistic: LogisticItem[] | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem4[] | null;
  /**
   * The Route ID used for force users. Sample: 20210111-100902-1841701
   */
  route_id: string | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface CompaniesItem4 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface CountriesItem1 {
  /**
   * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
   */
  supported_country: SupportedCountry;
}
export interface DeliveryItem1 {
  /**
   * The delivery center id generated. Sample: BK32
   */
  delivery_center_id: string | null;
}
export interface EmailItem1 {
  /**
   * The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  email_list: string | null;
}
export interface LogisticItem1 {
  /**
   * The Logistic operator ID. Sample: 460751
   */
  logistic_operator_id: string | null;
}
export interface PermissionsItem5 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface AdminUserInvitationSubmitted {
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem4[] | null;
  /**
   * The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  countries: CountriesItem1[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  delivery: DeliveryItem1[] | null;
  /**
   * The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  email: EmailItem1[] | null;
  /**
   * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  logistic: LogisticItem1[] | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem5[] | null;
  /**
   * The Route ID used for force users. Sample: 20210111-100902-1841701
   */
  route_id: string | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface CompaniesItem5 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface PermissionsItem6 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem4 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface AllAccountDeselection {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem5[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem6[] | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem4[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_id: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface CompaniesItem6 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface PermissionsItem7 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem5 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface AllAccountSelection {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem6[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem7[] | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem5[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_id: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface ApplicationsItem {
  /**
   * The value chosen to filter the list. Which variable the user is using to perform the filtering action. Permitted values: All, admin portal, cmsadmin, deliveradmin, force, oncustomer, social, supplier
   */
  app_filter: AppFilter;
}
export interface ApplicationFiltered {
  /**
   * The array of applications. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  applications: ApplicationsItem[] | null;
  /**
   * The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country', 'Application'
   */
  filter_criteria: FilterCriteria;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS
   */
  list_type: ListType;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName2;
}
export interface CountryFiltered {
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country', 'Application'
   */
  filter_criteria: FilterCriteria;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS
   */
  list_type: ListType;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}
export interface CustomerUserInvitationCanceled {
  /**
   * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
   */
  cancel_button: boolean | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface CustomerUserInvitationFinished {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface CustomerUserInvitationRestarted {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface CustomerUserInvitationResult {
  /**
   * The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
   */
  account_country: string | null;
  /**
   * The account ID in a given context. Permitted values. Samples: 'H8999'
   */
  account_id: string | null;
  /**
   * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
   */
  account_name: string | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  new_user_email: string | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
  /**
   * The user was associated or not. It's a boolean. If trigarred, set True. If not, set False.
   */
  user_associated: boolean | null;
  /**
   * The user was invited or not. It's a boolean. If triggarred, set True. If not, set False.
   */
  user_invited: boolean | null;
}
export interface CustomerUserInvitationSubmitted {
  /**
   * The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
   */
  account_country: string | null;
  /**
   * The account ID in a given context. Permitted values. Samples: 'H8999'
   */
  account_id: string | null;
  /**
   * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
   */
  account_name: string | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  new_user_email: string | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface UserBlockCanceled {
  /**
   * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
   */
  cancel_button: boolean | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}
export interface CompaniesItem7 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface PermissionsItem8 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem6 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface UserBlocked {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem7[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem8[] | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem6[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface UserClicked {
  /**
   * The number of pages loaded. Samples: 10, 15, 30
   */
  clicked_item_position: number | null;
  /**
   * The number of items listed in the given context. Samples: 3, 8, 15
   */
  listed_items: number | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}
export interface CompaniesItem8 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface DeliveryItem2 {
  /**
   * The delivery center id generated. Sample: BK32
   */
  delivery_center_id: string | null;
}
export interface LogisticItem2 {
  /**
   * The Logistic operator ID. Sample: 460751
   */
  logistic_operator_id: string | null;
}
export interface PermissionsItem9 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem7 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface UserDetailsViewed {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem8[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  delivery: DeliveryItem2[] | null;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  logistic: LogisticItem2[] | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem9[] | null;
  /**
   * The Route ID used for force users. Sample: 20210111-100902-1841701
   */
  route_id: string | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem7[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface UserEditingCanceled {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}
export interface UserEditingStarted {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}
export interface CompaniesItem9 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface DeliveryItem3 {
  /**
   * The delivery center id generated. Sample: BK32
   */
  delivery_center_id: string | null;
}
export interface LogisticItem3 {
  /**
   * The Logistic operator ID. Sample: 460751
   */
  logistic_operator_id: string | null;
}
export interface PermissionsItem10 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem8 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface UserEditingSubmissionResult {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem9[] | null;
  /**
   * The number of fails company associantion. Sample: 1,2,3,4
   */
  company_association_fail_count: number | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The number of fails supported country association. Sample: 1,2,3,4
   */
  country_association_fail_count: number | null;
  /**
   * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  delivery: DeliveryItem3[] | null;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The number of fails group associations. Sample: 1,2,3,4
   */
  group_association_fail_count: number | null;
  /**
   * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  logistic: LogisticItem3[] | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem10[] | null;
  /**
   * The Route ID used for force users. Sample: 20210111-100902-1841701
   */
  route_id: string | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem8[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_id: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface CompaniesItem10 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface DeliveryItem4 {
  /**
   * The delivery center id generated. Sample: BK32
   */
  delivery_center_id: string | null;
}
export interface LogisticItem4 {
  /**
   * The Logistic operator ID. Sample: 460751
   */
  logistic_operator_id: string | null;
}
export interface PermissionsItem11 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem9 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface UserEditingSubmitted {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem10[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  delivery: DeliveryItem4[] | null;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  logistic: LogisticItem4[] | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem11[] | null;
  /**
   * The Route ID used for force users. Sample: 20210111-100902-1841701
   */
  route_id: string | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem9[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_id: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface UserInfoCopied {
  /**
   * 'Copied value in the given context. Sample: 'ID' , 'phone', 'email'
   */
  copied_criteria: CopiedCriteria;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}
export interface UserInvitationCanceled {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface UserInvitationStarted {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}
export interface UserInvitationViewed {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName1;
}
export interface InformationItem {
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked
   */
  user_status: UserStatus;
}
export interface UserListPagination {
  /**
   * The number of pages loaded. Samples: 10, 15, 30
   */
  clicked_item_position: number | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  information: InformationItem[] | null;
  /**
   * The number of items listed in the given context. Samples: 3, 8, 15
   */
  listed_items: number | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
   */
  search_criteria: SearchCriteria;
}
export interface InformationItem1 {
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  accounts_associated: number | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked
   */
  user_status: UserStatus;
}
export interface UserListViewed {
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  information: InformationItem1[] | null;
  /**
   * The number of items listed in the given context. Samples: 3, 8, 15
   */
  listed_items: number | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
   */
  search_criteria: SearchCriteria;
}
export interface UserSearchCriteria {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS
   */
  list_type: ListType;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
   */
  search_criteria: SearchCriteria;
}
export interface UserTypeSelected {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName2;
  /**
   * The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
   */
  user_type: UserType;
}
export interface UserUnblockCanceled {
  /**
   * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
   */
  cancel_button: boolean | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}
export interface CompaniesItem11 {
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  company_ids: string | null;
}
export interface PermissionsItem12 {
  /**
   * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
   */
  permissions_groups: string | null;
}
export interface SupportedCountryItem10 {
  /**
   * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  supported_countries: string | null;
}
export interface UserUnblocked {
  /**
   * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
   */
  account_remove_permission: boolean | null;
  /**
   * The number of associated accounts. Sample: '1, 3, 5'
   */
  associated_accounts: number | null;
  /**
   * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  block_permission: boolean | null;
  /**
   * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  companies: CompaniesItem11[] | null;
  /**
   * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
   */
  country: Country;
  /**
   * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
   */
  edit_permission: boolean | null;
  /**
   * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  permissions: PermissionsItem12[] | null;
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
  /**
   * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
   */
  'supported country': SupportedCountryItem10[] | null;
  /**
   * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
   */
  user_email: string | null;
  /**
   * The user phone number in the given context. Samples: +5519999841766
   */
  user_phone: string | null;
  /**
   * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
   */
  user_status: string | null;
}
export interface UserViewed {
  /**
   * The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
   */
  screen_name: ScreenName;
}

export enum Country {
  Ar = 'AR',
  Br = 'BR',
  Ca = 'CA',
  Dr = 'DR',
  Ec = 'EC',
  Hn = 'HN',
  Mx = 'MX',
  Pa = 'PA',
  Pe = 'PE',
  Py = 'PY',
  Sv = 'SV',
  Tz = 'TZ',
  Us = 'US',
  Uy = 'UY',
  Za = 'ZA',
  Null = 'null',
}
export enum ScreenName {
  Users = 'USERS',
  UserDetails = 'USER_DETAILS',
  Null = 'null',
}
export enum ScreenName1 {
  AdminUserInvitation = 'ADMIN_USER_INVITATION',
  CustomerUserInvitation = 'CUSTOMER_USER_INVITATION',
  Users1 = 'USERS',
  UserDetails1 = 'USER_DETAILS',
  UserInvitation = 'USER_INVITATION',
  Null1 = 'null',
}
export enum SupportedCountry {
  Ar = 'AR',
  Br = 'BR',
  Ca = 'CA',
  Co = 'CO',
  Do = 'DO',
  Ec = 'EC',
  Hn = 'HN',
  Kr = 'KR',
  Mx = 'MX',
  Pa = 'PA',
  Pe = 'PE',
  Py = 'PY',
  Sv = 'SV',
  Tz = 'TZ',
  Ug = 'UG',
  Uk = 'UK',
  Us = 'US',
  Uy = 'UY',
  Za = 'ZA',
  Null = 'null',
}
export enum AppFilter {
  AdminPortal = 'ADMIN_PORTAL',
  All = 'ALL',
  Cmsadmin = 'CMSADMIN',
  Deliveradmin = 'DELIVERADMIN',
  Force = 'FORCE',
  Oncustomer = 'ONCUSTOMER',
  Social = 'SOCIAL',
  Supplier = 'SUPPLIER',
  Null = 'null',
}
export enum FilterCriteria {
  Application = 'APPLICATION',
  Country = 'COUNTRY',
  Null = 'null',
}
export enum ListType {
  Users = 'USERS',
  Null = 'null',
}
export enum ScreenName2 {
  AdminUserInvitation1 = 'ADMIN_USER_INVITATION',
  Users2 = 'USERS',
  UserDetails2 = 'USER_DETAILS',
  UserInvitation1 = 'USER_INVITATION',
  Null2 = 'null',
}
export enum CopiedCriteria {
  Email = 'EMAIL',
  Id = 'ID',
  Phone = 'PHONE',
  Null = 'null',
}
export enum UserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  ToBeOnboarded = 'TO BE ONBOARDED',
  Null = 'null',
}
export enum SearchCriteria {
  Email = 'EMAIL',
  Id = 'ID',
  Name = 'NAME',
  Other = 'OTHER',
  Phone = 'PHONE',
}
export enum UserType {
  Customer = 'CUSTOMER',
  Hub = 'HUB',
  Null = 'null',
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: Ajv.ErrorObject[]
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true });
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '7.0.2-0',
      },
    },
  };
}

/**
 * @typedef CompaniesItem
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AccountDeselection
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SupportedCountryItem[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef AccountRemovalCanceled
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef AccountRemovalStarted
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {number | null} selected_accounts - The number of selected accounts. Sample: '1, 3, 5'
 */
/**
 * @typedef CompaniesItem1
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem1
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem1
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AccountRemovalSubmissionResult
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem1[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem1[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} removal_fail_count - The number of fail removal. Sample: 1,2,3,4
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {number | null} selected_accounts - The number of selected accounts. Sample: '1, 3, 5'
 * @property {SupportedCountryItem1[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CompaniesItem2
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem2
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem2
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AccountRemovalSubmitted
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem2[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem2[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {number | null} selected_accounts - The number of selected accounts. Sample: '1, 3, 5'
 * @property {SupportedCountryItem2[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CompaniesItem3
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem3
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem3
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AccountSelection
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem3[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem3[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SupportedCountryItem3[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef AdminUserCategorySelected
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef AdminUserInvitationCanceled
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef AdminUserInvitationFinished
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef AdminUserInvitationRestarted
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CountriesItem
 * @property {SupportedCountry} supported_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 */
/**
 * @typedef DeliveryItem
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef EmailItem
 * @property {string | null} email_list - The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 */
/**
 * @typedef LogisticItem
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef PermissionsItem4
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef AdminUserInvitationResult
 * @property {number | null} association_fail_count - The number of fails associations. Sample: 1,2,3,4
 * @property {number | null} association_success_count - The number of success associations. Sample: 1,2,3,4
 * @property {CountriesItem[] | null} countries - The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {DeliveryItem[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {EmailItem[] | null} email - The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} invitation_fail_count - The number of fails invitations. Sample: 1,2,3,4
 * @property {number | null} invitation_success_count - The number of success invitations. Sample: 1,2,3,4
 * @property {LogisticItem[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {PermissionsItem4[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CompaniesItem4
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CountriesItem1
 * @property {SupportedCountry} supported_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 */
/**
 * @typedef DeliveryItem1
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef EmailItem1
 * @property {string | null} email_list - The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 */
/**
 * @typedef LogisticItem1
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef PermissionsItem5
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef AdminUserInvitationSubmitted
 * @property {CompaniesItem4[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {CountriesItem1[] | null} countries - The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {DeliveryItem1[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {EmailItem1[] | null} email - The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {LogisticItem1[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {PermissionsItem5[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CompaniesItem5
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem6
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem4
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AllAccountDeselection
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem5[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem6[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SupportedCountryItem4[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CompaniesItem6
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem7
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem5
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AllAccountSelection
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem6[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem7[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SupportedCountryItem5[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef ApplicationsItem
 * @property {AppFilter} app_filter - The value chosen to filter the list. Which variable the user is using to perform the filtering action. Permitted values: All, admin portal, cmsadmin, deliveradmin, force, oncustomer, social, supplier
 */
/**
 * @typedef ApplicationFiltered
 * @property {ApplicationsItem[] | null} applications - The array of applications. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {FilterCriteria} filter_criteria - The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country', 'Application'
 * @property {ListType} list_type - The name of the screen where the user is taking this action. Permitted Values: USERS
 * @property {ScreenName2} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CountryFiltered
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {FilterCriteria} filter_criteria - The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country', 'Application'
 * @property {ListType} list_type - The name of the screen where the user is taking this action. Permitted Values: USERS
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CustomerUserInvitationCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CustomerUserInvitationFinished
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CustomerUserInvitationRestarted
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CustomerUserInvitationResult
 * @property {string | null} account_country - The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {string | null} new_user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {boolean | null} user_associated - The user was associated or not. It's a boolean. If trigarred, set True. If not, set False.
 * @property {boolean | null} user_invited - The user was invited or not. It's a boolean. If triggarred, set True. If not, set False.
 */
/**
 * @typedef CustomerUserInvitationSubmitted
 * @property {string | null} account_country - The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {string | null} new_user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef UserBlockCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CompaniesItem7
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem8
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem6
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef UserBlocked
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem7[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem8[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SupportedCountryItem6[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserClicked
 * @property {number | null} clicked_item_position - The number of pages loaded. Samples: 10, 15, 30
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CompaniesItem8
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef DeliveryItem2
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef LogisticItem2
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef PermissionsItem9
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem7
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef UserDetailsViewed
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem8[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {DeliveryItem2[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {LogisticItem2[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {PermissionsItem9[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SupportedCountryItem7[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserEditingCanceled
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef UserEditingStarted
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CompaniesItem9
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef DeliveryItem3
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef LogisticItem3
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef PermissionsItem10
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem8
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef UserEditingSubmissionResult
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem9[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} company_association_fail_count - The number of fails company associantion. Sample: 1,2,3,4
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {number | null} country_association_fail_count - The number of fails supported country association. Sample: 1,2,3,4
 * @property {DeliveryItem3[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} group_association_fail_count - The number of fails group associations. Sample: 1,2,3,4
 * @property {LogisticItem3[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {PermissionsItem10[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SupportedCountryItem8[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CompaniesItem10
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef DeliveryItem4
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef LogisticItem4
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef PermissionsItem11
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem9
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef UserEditingSubmitted
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem10[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {DeliveryItem4[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {LogisticItem4[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {PermissionsItem11[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SupportedCountryItem9[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserInfoCopied
 * @property {CopiedCriteria} copied_criteria - 'Copied value in the given context. Sample: 'ID' , 'phone', 'email'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef UserInvitationCanceled
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef UserInvitationStarted
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef UserInvitationViewed
 * @property {ScreenName1} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef InformationItem
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked
 */
/**
 * @typedef UserListPagination
 * @property {number | null} clicked_item_position - The number of pages loaded. Samples: 10, 15, 30
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {InformationItem[] | null} information - The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SearchCriteria} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
 */
/**
 * @typedef InformationItem1
 * @property {number | null} accounts_associated - The number of associated accounts. Sample: '1, 3, 5'
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked
 */
/**
 * @typedef UserListViewed
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {InformationItem1[] | null} information - The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SearchCriteria} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
 */
/**
 * @typedef UserSearchCriteria
 * @property {ListType} list_type - The name of the screen where the user is taking this action. Permitted Values: USERS
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SearchCriteria} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
 */
/**
 * @typedef UserTypeSelected
 * @property {ScreenName2} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {UserType} user_type - The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
 */
/**
 * @typedef UserUnblockCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */
/**
 * @typedef CompaniesItem11
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem12
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem10
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef UserUnblocked
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem11[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {Country} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem12[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 * @property {SupportedCountryItem10[] | null} supported country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserViewed
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS
 */

/**
 * Fires a '' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emptyIdentifier(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: '',
    type: 'object',
  };
  const message = {
    event: '',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when user deselects an account
 *
 * @param {AccountDeselection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountDeselection(
  props: AccountDeselection,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user deselects an account',
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_id: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_id',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'user_status',
          'edit_permission',
          'screen_name',
          'supported country',
          'user_id',
          'account_remove_permission',
          'user_email',
          'country',
          'companies',
          'block_permission',
          'permissions',
          'user_phone',
          'associated_accounts',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Account Deselection',
    type: 'object',
  };
  const message = {
    event: 'Account Deselection',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Account Deselection', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * 
Triggered when the user cancels the User Removal journey by clicking on the "Cancel" button on the Confirmation Modal 
 *
 * @param {AccountRemovalCanceled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountRemovalCanceled(
  props?: AccountRemovalCanceled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '\nTriggered when the user cancels the User Removal journey by clicking on the "Cancel" button on the Confirmation Modal ',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Account Removal Canceled',
    type: 'object',
  };
  const message = {
    event: 'Account Removal Canceled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Account Removal Canceled', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * 
Triggered when the user starts the Account Removal journey by clicking on the "Remove" button on the User Details Tab
 *
 * @param {AccountRemovalStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountRemovalStarted(
  props: AccountRemovalStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '\nTriggered when the user starts the Account Removal journey by clicking on the "Remove" button on the User Details Tab',
    properties: {
      context: {},
      properties: {
        properties: {
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          selected_accounts: {
            description: "The number of selected accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/selected_accounts',
            type: ['integer', 'null'],
          },
        },
        required: ['selected_accounts', 'screen_name', 'associated_accounts'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Account Removal Started',
    type: 'object',
  };
  const message = {
    event: 'Account Removal Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Account Removal Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * 
Triggered when the process of the User Removal is finished and returns feedback (failure and/or success)
 *
 * @param {AccountRemovalSubmissionResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountRemovalSubmissionResult(
  props: AccountRemovalSubmissionResult,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '\nTriggered when the process of the User Removal is finished and returns feedback (failure and/or success)',
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          removal_fail_count: {
            description: 'The number of fail removal. Sample: 1,2,3,4',
            id: '/properties/properties/properties/removal_fail_count',
            type: ['integer', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          selected_accounts: {
            description: "The number of selected accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/selected_accounts',
            type: ['integer', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_id: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_id',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'supported country',
          'block_permission',
          'user_phone',
          'edit_permission',
          'country',
          'user_status',
          'account_remove_permission',
          'permissions',
          'selected_accounts',
          'screen_name',
          'removal_fail_count',
          'companies',
          'associated_accounts',
          'user_id',
          'user_email',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Account Removal Submission Result',
    type: 'object',
  };
  const message = {
    event: 'Account Removal Submission Result',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Account Removal Submission Result',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Triggered when the user clicks on the "Remove" button on the Confirmation Modal
 *
 * @param {AccountRemovalSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountRemovalSubmitted(
  props: AccountRemovalSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the "Remove" button on the Confirmation Modal',
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          selected_accounts: {
            description: "The number of selected accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/selected_accounts',
            type: ['integer', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_id: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_id',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'selected_accounts',
          'supported country',
          'country',
          'edit_permission',
          'associated_accounts',
          'screen_name',
          'user_status',
          'permissions',
          'user_id',
          'user_email',
          'user_phone',
          'block_permission',
          'companies',
          'account_remove_permission',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Account Removal Submitted',
    type: 'object',
  };
  const message = {
    event: 'Account Removal Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Account Removal Submitted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when user selects accounts
 *
 * @param {AccountSelection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountSelection(
  props: AccountSelection,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user selects accounts',
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_id: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_id',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'block_permission',
          'user_phone',
          'user_id',
          'user_email',
          'supported country',
          'edit_permission',
          'account_remove_permission',
          'user_status',
          'companies',
          'country',
          'screen_name',
          'associated_accounts',
          'permissions',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Account Selection',
    type: 'object',
  };
  const message = {
    event: 'Account Selection',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Account Selection', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user selects the "Administrator" option
 *
 * @param {AdminUserCategorySelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserCategorySelected(
  props: AdminUserCategorySelected,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user selects the "Administrator" option',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['DDCs', 'screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Admin User Category Selected',
    type: 'object',
  };
  const message = {
    event: 'Admin User Category Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Admin User Category Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user clicks on the "back" button
 *
 * @param {AdminUserInvitationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationCanceled(
  props: AdminUserInvitationCanceled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the "back" button',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['DDCs', 'screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Admin User Invitation Canceled',
    type: 'object',
  };
  const message = {
    event: 'Admin User Invitation Canceled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Admin User Invitation Canceled',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Triggered when the user clicks on the "Done" button
 *
 * @param {AdminUserInvitationFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationFinished(
  props: AdminUserInvitationFinished,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the "Done" button',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['DDCs', 'screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Admin User Invitation Finished',
    type: 'object',
  };
  const message = {
    event: 'Admin User Invitation Finished',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Admin User Invitation Finished',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Triggered when the user clicks on the "Add new user" button
 *
 * @param {AdminUserInvitationRestarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationRestarted(
  props: AdminUserInvitationRestarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the "Add new user" button',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['DDCs', 'screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Admin User Invitation Restarted',
    type: 'object',
  };
  const message = {
    event: 'Admin User Invitation Restarted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Admin User Invitation Restarted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Triggered when the process of the Admin User Invitation is finished and returns feedback (failure and/or success)
 *
 * @param {AdminUserInvitationResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationResult(
  props: AdminUserInvitationResult,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the process of the Admin User Invitation is finished and returns feedback (failure and/or success)',
    properties: {
      context: {},
      properties: {
        properties: {
          association_fail_count: {
            description: 'The number of fails associations. Sample: 1,2,3,4',
            id: '/properties/properties/properties/association_fail_count',
            type: ['integer', 'null'],
          },
          association_success_count: {
            description: 'The number of success associations. Sample: 1,2,3,4',
            id: '/properties/properties/properties/association_success_count',
            type: ['integer', 'null'],
          },
          countries: {
            description:
              "The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/countries',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/countries/items',
              properties: {
                supported_country: {
                  description:
                    'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
                  enum: [
                    'AR',
                    'BR',
                    'CA',
                    'CO',
                    'DO',
                    'EC',
                    'HN',
                    'KR',
                    'MX',
                    'PA',
                    'PE',
                    'PY',
                    'SV',
                    'TZ',
                    'UG',
                    'UK',
                    'US',
                    'UY',
                    'ZA',
                    null,
                  ],
                  id: '/properties/properties/properties/countries/items/properties/supported_country',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_country'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          delivery: {
            description:
              "The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/delivery',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/delivery/items',
              properties: {
                delivery_center_id: {
                  description: 'The delivery center id generated. Sample: BK32',
                  id: '/properties/properties/properties/delivery/items/properties/delivery_center_id',
                  type: ['string', 'null'],
                },
              },
              required: ['delivery_center_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          email: {
            description:
              "The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/email',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/email/items',
              properties: {
                email_list: {
                  description:
                    'The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
                  id: '/properties/properties/properties/email/items/properties/email_list',
                  type: ['string', 'null'],
                },
              },
              required: ['email_list'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          invitation_fail_count: {
            description: 'The number of fails invitations. Sample: 1,2,3,4',
            id: '/properties/properties/properties/invitation_fail_count',
            type: ['integer', 'null'],
          },
          invitation_success_count: {
            description: 'The number of success invitations. Sample: 1,2,3,4',
            id: '/properties/properties/properties/invitation_success_count',
            type: ['integer', 'null'],
          },
          logistic: {
            description:
              "The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/logistic',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/logistic/items',
              properties: {
                logistic_operator_id: {
                  description: 'The Logistic operator ID. Sample: 460751',
                  id: '/properties/properties/properties/logistic/items/properties/logistic_operator_id',
                  type: ['string', 'null'],
                },
              },
              required: ['logistic_operator_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          route_id: {
            description: 'The Route ID used for force users. Sample: 20210111-100902-1841701',
            id: '/properties/properties/properties/route_id',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: [
          'delivery',
          'route_id',
          'screen_name',
          'permissions',
          'invitation_success_count',
          'emails',
          'invitation_fail_count',
          'association_success_count',
          'email',
          'association_fail_count',
          'countries',
          'logistic',
          'DDCs',
          'country',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Admin User Invitation Result',
    type: 'object',
  };
  const message = {
    event: 'Admin User Invitation Result',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Admin User Invitation Result', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user clicks on the "finish" button
 *
 * @param {AdminUserInvitationSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationSubmitted(
  props: AdminUserInvitationSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the "finish" button',
    properties: {
      context: {},
      properties: {
        properties: {
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          countries: {
            description:
              "The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/countries',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/countries/items',
              properties: {
                supported_country: {
                  description:
                    'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
                  enum: [
                    'AR',
                    'BR',
                    'CA',
                    'CO',
                    'DO',
                    'EC',
                    'HN',
                    'KR',
                    'MX',
                    'PA',
                    'PE',
                    'PY',
                    'SV',
                    'TZ',
                    'UG',
                    'UK',
                    'US',
                    'UY',
                    'ZA',
                    null,
                  ],
                  id: '/properties/properties/properties/countries/items/properties/supported_country',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_country'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          delivery: {
            description:
              "The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/delivery',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/delivery/items',
              properties: {
                delivery_center_id: {
                  description: 'The delivery center id generated. Sample: BK32',
                  id: '/properties/properties/properties/delivery/items/properties/delivery_center_id',
                  type: ['string', 'null'],
                },
              },
              required: ['delivery_center_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          email: {
            description:
              "The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/email',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/email/items',
              properties: {
                email_list: {
                  description:
                    'The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
                  id: '/properties/properties/properties/email/items/properties/email_list',
                  type: ['string', 'null'],
                },
              },
              required: ['email_list'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          logistic: {
            description:
              "The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/logistic',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/logistic/items',
              properties: {
                logistic_operator_id: {
                  description: 'The Logistic operator ID. Sample: 460751',
                  id: '/properties/properties/properties/logistic/items/properties/logistic_operator_id',
                  type: ['string', 'null'],
                },
              },
              required: ['logistic_operator_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          route_id: {
            description: 'The Route ID used for force users. Sample: 20210111-100902-1841701',
            id: '/properties/properties/properties/route_id',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: [
          'permissions',
          'companies',
          'logistic',
          'email',
          'DDCs',
          'screen_name',
          'country',
          'countries',
          'route_id',
          'delivery',
          'emails',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Admin User Invitation Submitted',
    type: 'object',
  };
  const message = {
    event: 'Admin User Invitation Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Admin User Invitation Submitted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Triggered when user clicks on 'Deselect All'
 *
 * @param {AllAccountDeselection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function allAccountDeselection(
  props: AllAccountDeselection,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "Triggered when user clicks on 'Deselect All'",
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_id: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_id',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'user_phone',
          'screen_name',
          'companies',
          'edit_permission',
          'country',
          'supported country',
          'user_id',
          'user_email',
          'block_permission',
          'user_status',
          'account_remove_permission',
          'permissions',
          'associated_accounts',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'All Account Deselection',
    type: 'object',
  };
  const message = {
    event: 'All Account Deselection',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('All Account Deselection', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when user clicks on 'Select All'
 *
 * @param {AllAccountSelection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function allAccountSelection(
  props: AllAccountSelection,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "Triggered when user clicks on 'Select All'",
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_id: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_id',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'user_id',
          'block_permission',
          'edit_permission',
          'user_phone',
          'supported country',
          'user_email',
          'country',
          'associated_accounts',
          'screen_name',
          'permissions',
          'user_status',
          'companies',
          'account_remove_permission',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'All Account Selection',
    type: 'object',
  };
  const message = {
    event: 'All Account Selection',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('All Account Selection', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user filters the list by application
 *
 * @param {ApplicationFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationFiltered(
  props: ApplicationFiltered,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user filters the list by application',
    properties: {
      context: {},
      properties: {
        properties: {
          applications: {
            description:
              "The array of applications. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/applications',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/applications/items',
              properties: {
                app_filter: {
                  description:
                    'The value chosen to filter the list. Which variable the user is using to perform the filtering action. Permitted values: All, admin portal, cmsadmin, deliveradmin, force, oncustomer, social, supplier',
                  enum: [
                    'ADMIN_PORTAL',
                    'ALL',
                    'CMSADMIN',
                    'DELIVERADMIN',
                    'FORCE',
                    'ONCUSTOMER',
                    'SOCIAL',
                    'SUPPLIER',
                    null,
                  ],
                  id: '/properties/properties/properties/applications/items/properties/app_filter',
                  type: ['string', 'null'],
                },
              },
              required: ['app_filter'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          filter_criteria: {
            description:
              "The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country', 'Application'",
            enum: ['APPLICATION', 'COUNTRY', null],
            id: '/properties/properties/properties/filter_criteria',
            type: ['string', 'null'],
          },
          list_type: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS',
            enum: ['USERS', null],
            id: '/properties/properties/properties/list_type',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['ADMIN_USER_INVITATION', 'USERS', 'USER_DETAILS', 'USER_INVITATION', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['list_type', 'filter_criteria', 'applications', 'screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Application Filtered',
    type: 'object',
  };
  const message = {
    event: 'Application Filtered',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Application Filtered', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * 
Triggered when the user filters the list by Country
 *
 * @param {CountryFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function countryFiltered(
  props: CountryFiltered,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: '\nTriggered when the user filters the list by Country',
    properties: {
      context: {},
      properties: {
        properties: {
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          filter_criteria: {
            description:
              "The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country', 'Application'",
            enum: ['APPLICATION', 'COUNTRY', null],
            id: '/properties/properties/properties/filter_criteria',
            type: ['string', 'null'],
          },
          list_type: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS',
            enum: ['USERS', null],
            id: '/properties/properties/properties/list_type',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'list_type', 'country', 'filter_criteria'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Country Filtered',
    type: 'object',
  };
  const message = {
    event: 'Country Filtered',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Country Filtered', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user cancels the User Invitation journey by clicking on the "Back" button on the User Invitation Modal
 *
 * @param {CustomerUserInvitationCanceled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationCanceled(
  props?: CustomerUserInvitationCanceled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user cancels the User Invitation journey by clicking on the "Back" button on the User Invitation Modal',
    properties: {
      context: {},
      properties: {
        properties: {
          cancel_button: {
            description:
              "Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/cancel_button',
            type: ['boolean', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['cancel_button', 'screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Customer User Invitation Canceled',
    type: 'object',
  };
  const message = {
    event: 'Customer User Invitation Canceled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Customer User Invitation Canceled',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Tiggered when the user clicks on the "Done" button
 *
 * @param {CustomerUserInvitationFinished} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationFinished(
  props?: CustomerUserInvitationFinished,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Tiggered when the user clicks on the "Done" button',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Customer User Invitation Finished',
    type: 'object',
  };
  const message = {
    event: 'Customer User Invitation Finished',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Customer User Invitation Finished',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Tiggered when the user clicks on the "Add new user" button
 *
 * @param {CustomerUserInvitationRestarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationRestarted(
  props?: CustomerUserInvitationRestarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Tiggered when the user clicks on the "Add new user" button',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Customer User Invitation Restarted',
    type: 'object',
  };
  const message = {
    event: 'Customer User Invitation Restarted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Customer User Invitation Restarted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Triggered when the process of the User Invitation is failed.
 *
 * @param {CustomerUserInvitationResult} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationResult(
  props?: CustomerUserInvitationResult,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the process of the User Invitation is failed. ',
    properties: {
      context: {},
      properties: {
        properties: {
          account_country: {
            description:
              'The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
            id: '/properties/properties/properties/account_country',
            type: ['string', 'null'],
          },
          account_id: {
            description: "The account ID in a given context. Permitted values. Samples: 'H8999'",
            id: '/properties/properties/properties/account_id',
            type: ['string', 'null'],
          },
          account_name: {
            description:
              "The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
            id: '/properties/properties/properties/account_name',
            type: ['string', 'null'],
          },
          new_user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/new_user_email',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          user_associated: {
            description:
              "The user was associated or not. It's a boolean. If trigarred, set True. If not, set False.",
            id: '/properties/properties/properties/user_associated',
            type: ['boolean', 'null'],
          },
          user_invited: {
            description:
              "The user was invited or not. It's a boolean. If triggarred, set True. If not, set False.",
            id: '/properties/properties/properties/user_invited',
            type: ['boolean', 'null'],
          },
        },
        required: [
          'screen_name',
          'account_name',
          'account_id',
          'account_country',
          'new_user_email',
          'user_associated',
          'user_invited',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Customer User Invitation Result',
    type: 'object',
  };
  const message = {
    event: 'Customer User Invitation Result',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Customer User Invitation Result',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Triggered when the user finishes to fill-out the user invitation forms and clicks on the "Finish" button
 *
 * @param {CustomerUserInvitationSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationSubmitted(
  props?: CustomerUserInvitationSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user finishes to fill-out the user invitation forms and clicks on the "Finish" button',
    properties: {
      context: {},
      properties: {
        properties: {
          account_country: {
            description:
              'The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
            id: '/properties/properties/properties/account_country',
            type: ['string', 'null'],
          },
          account_id: {
            description: "The account ID in a given context. Permitted values. Samples: 'H8999'",
            id: '/properties/properties/properties/account_id',
            type: ['string', 'null'],
          },
          account_name: {
            description:
              "The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
            id: '/properties/properties/properties/account_name',
            type: ['string', 'null'],
          },
          new_user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/new_user_email',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: [
          'screen_name',
          'account_name',
          'account_id',
          'account_country',
          'new_user_email',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Customer User Invitation Submitted',
    type: 'object',
  };
  const message = {
    event: 'Customer User Invitation Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Customer User Invitation Submitted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Triggered when user cancels user block
 *
 * @param {UserBlockCanceled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userBlockCanceled(
  props?: UserBlockCanceled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user cancels user block',
    properties: {
      context: {},
      properties: {
        properties: {
          cancel_button: {
            description:
              "Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/cancel_button',
            type: ['boolean', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'cancel_button'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'User Block Canceled',
    type: 'object',
  };
  const message = {
    event: 'User Block Canceled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Block Canceled', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user status is blocked by an admin user
 *
 * @param {UserBlocked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userBlocked(
  props: UserBlocked,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user status is blocked by an admin user',
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'user_phone',
          'user_email',
          'companies',
          'permissions',
          'block_permission',
          'associated_accounts',
          'country',
          'supported country',
          'account_remove_permission',
          'edit_permission',
          'user_status',
          'screen_name',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Blocked',
    type: 'object',
  };
  const message = {
    event: 'User Blocked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Blocked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * 
Listing position the user was in when clicked
 *
 * @param {UserClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userClicked(
  props: UserClicked,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: '\nListing position the user was in when clicked',
    properties: {
      context: {},
      properties: {
        properties: {
          clicked_item_position: {
            description: 'The number of pages loaded. Samples: 10, 15, 30',
            id: '/properties/properties/properties/clicked_item_position',
            type: ['integer', 'null'],
          },
          listed_items: {
            description: 'The number of items listed in the given context. Samples: 3, 8, 15',
            id: '/properties/properties/properties/listed_items',
            type: ['integer', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'listed_items', 'clicked_item_position'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Clicked',
    type: 'object',
  };
  const message = {
    event: 'User Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the Details tab of the User Details page loads
 *
 * @param {UserDetailsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userDetailsViewed(
  props: UserDetailsViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the Details tab of the User Details page loads',
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          delivery: {
            description:
              "The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/delivery',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/delivery/items',
              properties: {
                delivery_center_id: {
                  description: 'The delivery center id generated. Sample: BK32',
                  id: '/properties/properties/properties/delivery/items/properties/delivery_center_id',
                  type: ['string', 'null'],
                },
              },
              required: ['delivery_center_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          logistic: {
            description:
              "The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/logistic',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/logistic/items',
              properties: {
                logistic_operator_id: {
                  description: 'The Logistic operator ID. Sample: 460751',
                  id: '/properties/properties/properties/logistic/items/properties/logistic_operator_id',
                  type: ['string', 'null'],
                },
              },
              required: ['logistic_operator_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          route_id: {
            description: 'The Route ID used for force users. Sample: 20210111-100902-1841701',
            id: '/properties/properties/properties/route_id',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'edit_permission',
          'screen_name',
          'user_phone',
          'companies',
          'delivery',
          'route_id',
          'associated_accounts',
          'country',
          'user_email',
          'user_status',
          'supported country',
          'permissions',
          'account_remove_permission',
          'logistic',
          'block_permission',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Details Viewed',
    type: 'object',
  };
  const message = {
    event: 'User Details Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Details Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * 
Triggered when the user cancels the User Edit Setup journey by clicking on the "Cancel" button on the Edit User Information Modal
 *
 * @param {UserEditingCanceled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userEditingCanceled(
  props?: UserEditingCanceled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '\nTriggered when the user cancels the User Edit Setup journey by clicking on the "Cancel" button on the Edit User Information Modal',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'User Editing Canceled',
    type: 'object',
  };
  const message = {
    event: 'User Editing Canceled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Editing Canceled', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user starts the User Edit Setup journey by clicking on the "Edit" button on the User Details Tab
 *
 * @param {UserEditingStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userEditingStarted(
  props?: UserEditingStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user starts the User Edit Setup journey by clicking on the "Edit" button on the User Details Tab',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'User Editing Started',
    type: 'object',
  };
  const message = {
    event: 'User Editing Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Editing Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * 
Triggered when the User Edit process is finished and returns feedback (failure and/or success)
 *
 * @param {UserEditingSubmissionResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userEditingSubmissionResult(
  props: UserEditingSubmissionResult,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '\nTriggered when the User Edit process is finished and returns feedback (failure and/or success)',
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          company_association_fail_count: {
            description: 'The number of fails company associantion. Sample: 1,2,3,4',
            id: '/properties/properties/properties/company_association_fail_count',
            type: ['integer', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          country_association_fail_count: {
            description: 'The number of fails supported country association. Sample: 1,2,3,4',
            id: '/properties/properties/properties/country_association_fail_count',
            type: ['integer', 'null'],
          },
          delivery: {
            description:
              "The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/delivery',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/delivery/items',
              properties: {
                delivery_center_id: {
                  description: 'The delivery center id generated. Sample: BK32',
                  id: '/properties/properties/properties/delivery/items/properties/delivery_center_id',
                  type: ['string', 'null'],
                },
              },
              required: ['delivery_center_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          group_association_fail_count: {
            description: 'The number of fails group associations. Sample: 1,2,3,4',
            id: '/properties/properties/properties/group_association_fail_count',
            type: ['integer', 'null'],
          },
          logistic: {
            description:
              "The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/logistic',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/logistic/items',
              properties: {
                logistic_operator_id: {
                  description: 'The Logistic operator ID. Sample: 460751',
                  id: '/properties/properties/properties/logistic/items/properties/logistic_operator_id',
                  type: ['string', 'null'],
                },
              },
              required: ['logistic_operator_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          route_id: {
            description: 'The Route ID used for force users. Sample: 20210111-100902-1841701',
            id: '/properties/properties/properties/route_id',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_id: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_id',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'company_association_fail_count',
          'user_id',
          'user_phone',
          'group_association_fail_count',
          'screen_name',
          'associated_accounts',
          'delivery',
          'permissions',
          'country_association_fail_count',
          'route_id',
          'logistic',
          'user_status',
          'country',
          'user_email',
          'account_remove_permission',
          'supported country',
          'edit_permission',
          'companies',
          'block_permission',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Editing Submission Result',
    type: 'object',
  };
  const message = {
    event: 'User Editing Submission Result',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'User Editing Submission Result',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * 
Triggered when the user finishes to fill-out the user information forms and clicks on the "Save Changes" button on the Edit User Information Modal
 *
 * @param {UserEditingSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userEditingSubmitted(
  props: UserEditingSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '\nTriggered when the user finishes to fill-out the user information forms and clicks on the "Save Changes" button on the Edit User Information Modal',
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          delivery: {
            description:
              "The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/delivery',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/delivery/items',
              properties: {
                delivery_center_id: {
                  description: 'The delivery center id generated. Sample: BK32',
                  id: '/properties/properties/properties/delivery/items/properties/delivery_center_id',
                  type: ['string', 'null'],
                },
              },
              required: ['delivery_center_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          logistic: {
            description:
              "The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/logistic',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/logistic/items',
              properties: {
                logistic_operator_id: {
                  description: 'The Logistic operator ID. Sample: 460751',
                  id: '/properties/properties/properties/logistic/items/properties/logistic_operator_id',
                  type: ['string', 'null'],
                },
              },
              required: ['logistic_operator_id'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          route_id: {
            description: 'The Route ID used for force users. Sample: 20210111-100902-1841701',
            id: '/properties/properties/properties/route_id',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_id: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_id',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'country',
          'edit_permission',
          'delivery',
          'user_phone',
          'companies',
          'associated_accounts',
          'supported country',
          'user_email',
          'block_permission',
          'screen_name',
          'user_status',
          'account_remove_permission',
          'logistic',
          'user_id',
          'permissions',
          'route_id',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Editing Submitted',
    type: 'object',
  };
  const message = {
    event: 'User Editing Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Editing Submitted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user clicks on the copy icon to copy the value
 *
 * @param {UserInfoCopied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInfoCopied(
  props: UserInfoCopied,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the copy icon to copy the value',
    properties: {
      context: {},
      properties: {
        properties: {
          copied_criteria: {
            description: "'Copied value in the given context. Sample: 'ID' , 'phone', 'email'",
            enum: ['EMAIL', 'ID', 'PHONE', null],
            id: '/properties/properties/properties/copied_criteria',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'copied_criteria'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Info Copied',
    type: 'object',
  };
  const message = {
    event: 'User Info Copied',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Info Copied', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user clicks on the "X" button
 *
 * @param {UserInvitationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationCanceled(
  props: UserInvitationCanceled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user clicks on the "X" button',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'DDCs'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Invitation Canceled',
    type: 'object',
  };
  const message = {
    event: 'User Invitation Canceled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Invitation Canceled', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user starts the User Invitation journey by clicking on the "Add User" button on the User List Tab
 *
 * @param {UserInvitationStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationStarted(
  props?: UserInvitationStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user starts the User Invitation journey by clicking on the "Add User" button on the User List Tab',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'User Invitation Started',
    type: 'object',
  };
  const message = {
    event: 'User Invitation Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Invitation Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user accesses the User Inviation Tab
 *
 * @param {UserInvitationViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationViewed(
  props: UserInvitationViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user accesses the User Inviation Tab ',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: [
              'ADMIN_USER_INVITATION',
              'CUSTOMER_USER_INVITATION',
              'USERS',
              'USER_DETAILS',
              'USER_INVITATION',
              null,
            ],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['DDCs', 'screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Invitation Viewed',
    type: 'object',
  };
  const message = {
    event: 'User Invitation Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Invitation Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user scrolls down to the next page on the user list (only when the next pagination response will be loaded)
 *
 * @param {UserListPagination} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userListPagination(
  props: UserListPagination,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Triggered when the user scrolls down to the next page on the user list (only when the next pagination response will be loaded)',
    properties: {
      context: {},
      properties: {
        properties: {
          clicked_item_position: {
            description: 'The number of pages loaded. Samples: 10, 15, 30',
            id: '/properties/properties/properties/clicked_item_position',
            type: ['integer', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          information: {
            description:
              "The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/information',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/information/items',
              properties: {
                user_email: {
                  description:
                    'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
                  id: '/properties/properties/properties/information/items/properties/user_email',
                  type: ['string', 'null'],
                },
                user_phone: {
                  description:
                    'The user phone number in the given context. Samples: +5519999841766',
                  id: '/properties/properties/properties/information/items/properties/user_phone',
                  type: ['string', 'null'],
                },
                user_status: {
                  description:
                    'The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked',
                  enum: ['ACTIVE', 'BLOCKED', 'TO BE ONBOARDED', null],
                  id: '/properties/properties/properties/information/items/properties/user_status',
                  type: ['string', 'null'],
                },
              },
              required: ['user_email', 'user_phone', 'user_status'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          listed_items: {
            description: 'The number of items listed in the given context. Samples: 3, 8, 15',
            id: '/properties/properties/properties/listed_items',
            type: ['integer', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          search_criteria: {
            description:
              "The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', \"phone', 'email', 'other'",
            enum: ['EMAIL', 'ID', 'NAME', 'OTHER', 'PHONE'],
            id: '/properties/properties/properties/search_criteria',
            type: 'string',
          },
        },
        required: [
          'listed_items',
          'clicked_item_position',
          'screen_name',
          'search_criteria',
          'information',
          'country',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User List Pagination',
    type: 'object',
  };
  const message = {
    event: 'User List Pagination',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User List Pagination', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user accesses the User List Tab
 *
 * @param {UserListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userListViewed(
  props: UserListViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user accesses the User List Tab ',
    properties: {
      context: {},
      properties: {
        properties: {
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          information: {
            description:
              "The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/information',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/information/items',
              properties: {
                accounts_associated: {
                  description: "The number of associated accounts. Sample: '1, 3, 5'",
                  id: '/properties/properties/properties/information/items/properties/accounts_associated',
                  type: ['integer', 'null'],
                },
                user_email: {
                  description:
                    'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
                  id: '/properties/properties/properties/information/items/properties/user_email',
                  type: ['string', 'null'],
                },
                user_phone: {
                  description:
                    'The user phone number in the given context. Samples: +5519999841766',
                  id: '/properties/properties/properties/information/items/properties/user_phone',
                  type: ['string', 'null'],
                },
                user_status: {
                  description:
                    'The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked',
                  enum: ['ACTIVE', 'BLOCKED', 'TO BE ONBOARDED', null],
                  id: '/properties/properties/properties/information/items/properties/user_status',
                  type: ['string', 'null'],
                },
              },
              required: ['user_email', 'user_phone', 'user_status', 'accounts_associated'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          listed_items: {
            description: 'The number of items listed in the given context. Samples: 3, 8, 15',
            id: '/properties/properties/properties/listed_items',
            type: ['integer', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          search_criteria: {
            description:
              "The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', \"phone', 'email', 'other'",
            enum: ['EMAIL', 'ID', 'NAME', 'OTHER', 'PHONE'],
            id: '/properties/properties/properties/search_criteria',
            type: 'string',
          },
        },
        required: ['screen_name', 'search_criteria', 'country', 'listed_items', 'information'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User List Viewed',
    type: 'object',
  };
  const message = {
    event: 'User List Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User List Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user selects terms to search a list based on a given criteria
 *
 * @param {UserSearchCriteria} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userSearchCriteria(
  props?: UserSearchCriteria,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user selects terms to search a list based on a given criteria',
    properties: {
      context: {},
      properties: {
        properties: {
          list_type: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS',
            enum: ['USERS', null],
            id: '/properties/properties/properties/list_type',
            type: ['string', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          search_criteria: {
            description:
              "The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', \"phone', 'email', 'other'",
            enum: ['EMAIL', 'ID', 'NAME', 'OTHER', 'PHONE'],
            id: '/properties/properties/properties/search_criteria',
            type: 'string',
          },
        },
        required: ['screen_name', 'list_type', 'search_criteria'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'User Search Criteria',
    type: 'object',
  };
  const message = {
    event: 'User Search Criteria',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Search Criteria', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user selects the user type
 *
 * @param {UserTypeSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userTypeSelected(
  props?: UserTypeSelected,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user selects the user type',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['ADMIN_USER_INVITATION', 'USERS', 'USER_DETAILS', 'USER_INVITATION', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          user_type: {
            description:
              "The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'",
            enum: ['CUSTOMER', 'HUB', null],
            id: '/properties/properties/properties/user_type',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'user_type'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'User Type Selected',
    type: 'object',
  };
  const message = {
    event: 'User Type Selected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Type Selected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when user cancels user unblock
 *
 * @param {UserUnblockCanceled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userUnblockCanceled(
  props?: UserUnblockCanceled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when user cancels user unblock',
    properties: {
      context: {},
      properties: {
        properties: {
          cancel_button: {
            description:
              "Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/cancel_button',
            type: ['boolean', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'cancel_button'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'User Unblock Canceled',
    type: 'object',
  };
  const message = {
    event: 'User Unblock Canceled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Unblock Canceled', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered when the user status is unblocked by an admin user
 *
 * @param {UserUnblocked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userUnblocked(
  props: UserUnblocked,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered when the user status is unblocked by an admin user',
    properties: {
      context: {},
      properties: {
        properties: {
          account_remove_permission: {
            description:
              "The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/account_remove_permission',
            type: ['boolean', 'null'],
          },
          associated_accounts: {
            description: "The number of associated accounts. Sample: '1, 3, 5'",
            id: '/properties/properties/properties/associated_accounts',
            type: ['integer', 'null'],
          },
          block_permission: {
            description:
              "The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/block_permission',
            type: ['boolean', 'null'],
          },
          companies: {
            description:
              "The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/companies',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/companies/items',
              properties: {
                company_ids: {
                  description:
                    "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
                  id: '/properties/properties/properties/companies/items/properties/company_ids',
                  type: ['string', 'null'],
                },
              },
              required: ['company_ids'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          country: {
            description:
              'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
            enum: [
              'AR',
              'BR',
              'CA',
              'DR',
              'EC',
              'HN',
              'MX',
              'PA',
              'PE',
              'PY',
              'SV',
              'TZ',
              'US',
              'UY',
              'ZA',
              null,
            ],
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          edit_permission: {
            description:
              "The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
            id: '/properties/properties/properties/edit_permission',
            type: ['boolean', 'null'],
          },
          permissions: {
            description:
              "The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/permissions',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/permissions/items',
              properties: {
                permissions_groups: {
                  description:
                    "Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
                  id: '/properties/properties/properties/permissions/items/properties/permissions_groups',
                  type: ['string', 'null'],
                },
              },
              required: ['permissions_groups'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          'supported country': {
            description:
              "The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
            id: '/properties/properties/properties/supported%20country',
            items: {
              description:
                "Do not implement with the 'items' structure/object, it's a normal array",
              id: '/properties/properties/properties/supported%20country/items',
              properties: {
                supported_countries: {
                  description:
                    'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
                  id: '/properties/properties/properties/supported%20country/items/properties/supported_countries',
                  type: ['string', 'null'],
                },
              },
              required: ['supported_countries'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          user_email: {
            description:
              'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
            id: '/properties/properties/properties/user_email',
            type: ['string', 'null'],
          },
          user_phone: {
            description: 'The user phone number in the given context. Samples: +5519999841766',
            id: '/properties/properties/properties/user_phone',
            type: ['string', 'null'],
          },
          user_status: {
            description:
              "The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
            id: '/properties/properties/properties/user_status',
            type: ['string', 'null'],
          },
        },
        required: [
          'user_status',
          'permissions',
          'country',
          'user_email',
          'block_permission',
          'associated_accounts',
          'account_remove_permission',
          'user_phone',
          'screen_name',
          'edit_permission',
          'supported country',
          'companies',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User Unblocked',
    type: 'object',
  };
  const message = {
    event: 'User Unblocked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Unblocked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * 
Triggered when the user accesses the User Tab
 *
 * @param {UserViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userViewed(
  props?: UserViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: '\nTriggered when the user accesses the User Tab',
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              'The name of the screen where the user is taking this action. Permitted Values: USERS, USER_DETAILS',
            enum: ['USERS', 'USER_DETAILS', null],
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'User Viewed',
    type: 'object',
  };
  const message = {
    event: 'User Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User Viewed', props || {}, withTypewriterContext(options), callback);
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * Fires a '' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  emptyIdentifier,
  /**
   * Triggered when user deselects an account
   *
   * @param {AccountDeselection} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  accountDeselection,
  /**
	 * 
	Triggered when the user cancels the User Removal journey by clicking on the "Cancel" button on the Confirmation Modal 
	 *
	 * @param {AccountRemovalCanceled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  accountRemovalCanceled,
  /**
	 * 
	Triggered when the user starts the Account Removal journey by clicking on the "Remove" button on the User Details Tab
	 *
	 * @param {AccountRemovalStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  accountRemovalStarted,
  /**
	 * 
	Triggered when the process of the User Removal is finished and returns feedback (failure and/or success)
	 *
	 * @param {AccountRemovalSubmissionResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  accountRemovalSubmissionResult,
  /**
   * Triggered when the user clicks on the "Remove" button on the Confirmation Modal
   *
   * @param {AccountRemovalSubmitted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  accountRemovalSubmitted,
  /**
   * Triggered when user selects accounts
   *
   * @param {AccountSelection} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  accountSelection,
  /**
   * Triggered when the user selects the "Administrator" option
   *
   * @param {AdminUserCategorySelected} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  adminUserCategorySelected,
  /**
   * Triggered when the user clicks on the "back" button
   *
   * @param {AdminUserInvitationCanceled} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  adminUserInvitationCanceled,
  /**
   * Triggered when the user clicks on the "Done" button
   *
   * @param {AdminUserInvitationFinished} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  adminUserInvitationFinished,
  /**
   * Triggered when the user clicks on the "Add new user" button
   *
   * @param {AdminUserInvitationRestarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  adminUserInvitationRestarted,
  /**
   * Triggered when the process of the Admin User Invitation is finished and returns feedback (failure and/or success)
   *
   * @param {AdminUserInvitationResult} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  adminUserInvitationResult,
  /**
   * Triggered when the user clicks on the "finish" button
   *
   * @param {AdminUserInvitationSubmitted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  adminUserInvitationSubmitted,
  /**
   * Triggered when user clicks on 'Deselect All'
   *
   * @param {AllAccountDeselection} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  allAccountDeselection,
  /**
   * Triggered when user clicks on 'Select All'
   *
   * @param {AllAccountSelection} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  allAccountSelection,
  /**
   * Triggered when the user filters the list by application
   *
   * @param {ApplicationFiltered} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  applicationFiltered,
  /**
	 * 
	Triggered when the user filters the list by Country
	 *
	 * @param {CountryFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  countryFiltered,
  /**
   * Triggered when the user cancels the User Invitation journey by clicking on the "Back" button on the User Invitation Modal
   *
   * @param {CustomerUserInvitationCanceled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  customerUserInvitationCanceled,
  /**
   * Tiggered when the user clicks on the "Done" button
   *
   * @param {CustomerUserInvitationFinished} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  customerUserInvitationFinished,
  /**
   * Tiggered when the user clicks on the "Add new user" button
   *
   * @param {CustomerUserInvitationRestarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  customerUserInvitationRestarted,
  /**
   * Triggered when the process of the User Invitation is failed.
   *
   * @param {CustomerUserInvitationResult} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  customerUserInvitationResult,
  /**
   * Triggered when the user finishes to fill-out the user invitation forms and clicks on the "Finish" button
   *
   * @param {CustomerUserInvitationSubmitted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  customerUserInvitationSubmitted,
  /**
   * Triggered when user cancels user block
   *
   * @param {UserBlockCanceled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userBlockCanceled,
  /**
   * Triggered when the user status is blocked by an admin user
   *
   * @param {UserBlocked} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userBlocked,
  /**
	 * 
	Listing position the user was in when clicked
	 *
	 * @param {UserClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  userClicked,
  /**
   * Triggered when the Details tab of the User Details page loads
   *
   * @param {UserDetailsViewed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userDetailsViewed,
  /**
	 * 
	Triggered when the user cancels the User Edit Setup journey by clicking on the "Cancel" button on the Edit User Information Modal
	 *
	 * @param {UserEditingCanceled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  userEditingCanceled,
  /**
   * Triggered when the user starts the User Edit Setup journey by clicking on the "Edit" button on the User Details Tab
   *
   * @param {UserEditingStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userEditingStarted,
  /**
	 * 
	Triggered when the User Edit process is finished and returns feedback (failure and/or success)
	 *
	 * @param {UserEditingSubmissionResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  userEditingSubmissionResult,
  /**
	 * 
	Triggered when the user finishes to fill-out the user information forms and clicks on the "Save Changes" button on the Edit User Information Modal
	 *
	 * @param {UserEditingSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  userEditingSubmitted,
  /**
   * Triggered when the user clicks on the copy icon to copy the value
   *
   * @param {UserInfoCopied} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userInfoCopied,
  /**
   * Triggered when the user clicks on the "X" button
   *
   * @param {UserInvitationCanceled} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userInvitationCanceled,
  /**
   * Triggered when the user starts the User Invitation journey by clicking on the "Add User" button on the User List Tab
   *
   * @param {UserInvitationStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userInvitationStarted,
  /**
   * Triggered when the user accesses the User Inviation Tab
   *
   * @param {UserInvitationViewed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userInvitationViewed,
  /**
   * Triggered when the user scrolls down to the next page on the user list (only when the next pagination response will be loaded)
   *
   * @param {UserListPagination} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userListPagination,
  /**
   * Triggered when the user accesses the User List Tab
   *
   * @param {UserListViewed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userListViewed,
  /**
   * Triggered when the user selects terms to search a list based on a given criteria
   *
   * @param {UserSearchCriteria} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userSearchCriteria,
  /**
   * Triggered when the user selects the user type
   *
   * @param {UserTypeSelected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userTypeSelected,
  /**
   * Triggered when user cancels user unblock
   *
   * @param {UserUnblockCanceled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userUnblockCanceled,
  /**
   * Triggered when the user status is unblocked by an admin user
   *
   * @param {UserUnblocked} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userUnblocked,
  /**
	 * 
	Triggered when the user accesses the User Tab
	 *
	 * @param {UserViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  userViewed,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_28i2T5O8sjBR4ejrsll1XLvRohF`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext()
        );
      }
    };
  },
});
