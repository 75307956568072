import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import styled, { AnyStyledComponent, css } from 'styled-components';
import { AutocompleteProps, CustomWidthComponentProps } from './types';

const withCustomWidth = <T>(component: unknown): ((props: T) => JSX.Element) => styled(
  component as AnyStyledComponent
)<CustomWidthComponentProps>`
  ${({ width, minWidth, maxWidth }) => css`
    width: ${width};
    min-width: ${minWidth};
    max-width: ${maxWidth};
  `}
`;

export const Autocomplete = withCustomWidth<AutocompleteProps>(MuiAutocomplete);
