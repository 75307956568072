import React from 'react';
import { Avatar } from '../../../../../components/Avatar/Avatar';
import * as Styled from './styles';

interface IProfile {
  fullName: string;
  email: string;
}

export function Profile({ fullName, email }: IProfile): JSX.Element {
  return (
    <Styled.Container>
      <Avatar name={fullName} />

      <Styled.List>
        <Styled.ListItem>{fullName}</Styled.ListItem>
        <Styled.ListItem>{email}</Styled.ListItem>
      </Styled.List>
    </Styled.Container>
  );
}
