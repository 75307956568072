import styled from 'styled-components';

export const Wrapper = styled.section`
  display: grid;
  justify-content: center;
  padding: 20px;
`;

export const Header = styled.header`
  margin-bottom: 32px;
  text-align: center;
`;

export const Step = styled.div`
  color: rgba(0, 0, 0, 0.9);
  font: 600 16px/24px 'Work Sans', sans-serif;
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.9);
  font: 600 32px/40px 'Barlow', sans-serif;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.9);
  font: 400 16px/24px 'Work Sans', sans-serif;
`;

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 16px;
`;

export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  right: 32px;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  & svg {
    color: rgba(0, 0, 0, 0.9);
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8;
  }
`;
