import { Button, styled as MuiStyled } from '@material-ui/core';
import { PersonAddOutlined } from '@material-ui/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%;
  width: 100%;
  font-family: Work Sans;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  margin: 15px 0 0 0;
  width: 100%;
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.15);
  margin-top: 32px;
  width: 90%;
  min-width: 400px;
`;

export const TitleContainer = styled.div`
  padding: 16px 24px;
  font-size: 20px;
  font-family: Barlow;
`;

export const FirstSection = MuiStyled('div')({
  width: '100%',
  padding: '16px 24px',
  '&>*:not(:last-child)': {
    marginBottom: '16px',
  },
});

export const UserSection = styled.div`
  padding: 16px 24px;
`;

export const SecondSectionTitle = styled.div`
  padding: 0px 24px;
  font-size: 16px;
  font-family: Barlow;
`;

export const StyledButton = MuiStyled(Button)(
  ({
    show = true,
    margin,
    variantType,
  }: {
    show?: boolean;
    margin?: string;
    variantType?: 'solid';
  }) => ({
    borderRadius: 999,
    visibility: show ? 'visible' : 'hidden',
    borderColor: 'black',
    margin,
    '&.Mui-disabled': {
      backgroundColor: '#F2F2F2',
      color: 'rgba(0,0,0,0.55)',
      border: 'none',
    },
    '&:hover': {
      color: '#000',
      backgroundColor: '#FFFF00',
    },
    ...(variantType === 'solid' && {
      backgroundColor: '#000',
      color: '#fff',
      '&:hover': {
        color: '#FFFF00',
        backgroundColor: '#000',
      },
    }),
  })
);

export const LineDivider = styled.hr`
  height: 1px;
  background-color: #ccc;
  border: none;
`;

export const UserDivider = styled.hr`
  height: 1px;
  background-color: #ccc;
  border: none;
  margin: 16px 0;
`;

export const AddUserIcon = MuiStyled(PersonAddOutlined)({
  color: 'rgba(0, 0, 0, 0.55)',
  height: '100%',
});

export const IconWrapper = styled.div`
  height: 40px;
  margin-top: 15px;
`;

export const UserInputsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const ChipsContainer = styled.div`
  display: flex;
  width: 95%;
  overflow: hidden;
`;

export const ButtonContainer = MuiStyled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '24px',
  width: '90%',
  gap: '16px',
});

export const StyledForm = styled.form`
  width: 100%;
`;
