import { styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled('div')({});

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
  },
  infos: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '12px',
  },
  info: {
    padding: '8px 0',
  },
  primaryInfo: {},
  secondaryInfo: {},
  oneLineInfo: {
    margin: '16px auto',
    width: '100%',
  },
});
