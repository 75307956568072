import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const List = styled.ul`
  list-style: none;
  margin-left: 16px;
`;

export const ListItem = styled.li`
  font: 400 14px/20px 'Work Sans', sans-serif;
  color: rgba(0, 0, 0, 0.9);

  &:first-child {
    font: 600 16px/24px 'Work Sans', sans-serif;
  }
`;
