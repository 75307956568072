import { styled as styledUI } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: static;
  padding: 16px;
  bottom: 0;
`;

export const ButtonsContainer = styledUI('div')({
  display: 'flex',
  padding: 30,
});

export const PrimaryButton = styledUI(Button)({
  borderRadius: '24px',
  padding: '12px 24px',
  color: '#000',
  border: '1px solid #000000;',
  margin: 8,
});

export const SecondaryButton = styledUI(Button)({
  borderRadius: '24px',
  padding: '12px 24px',
  color: 'rgba(255,255,255,0.95)',
  backgroundColor: '#000',
  border: '1px solid rgba(255,255,255,0.95)',
  margin: 8,
  '&:hover': {
    backgroundColor: '#000',
  },
});
