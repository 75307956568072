import { TextField } from '@material-ui/core';
import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { Autocomplete } from './styles';
import { ControlledSelectProps } from './types';

export const ControlledSelect = <T extends FieldValues>({
  autocompleteProps,
  textFieldProps,
  controllerProps,
}: ControlledSelectProps<T>): JSX.Element => {
  const { field, fieldState } = useController(controllerProps);

  return (
    <Autocomplete
      {...field}
      onChange={(_, data) => {
        field.onChange(data);
      }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          {...params}
          {...textFieldProps}
        />
      )}
      {...autocompleteProps}
    />
  );
};
