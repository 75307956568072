import React from 'react';
import { IRegisterForceUserResponse } from '__types/register-user-payload.types';
import { useStyles } from './Table.styles';
import { TableItem } from './TableItem/TableItem';

export type IProps = { invitations: IRegisterForceUserResponse[] };

export const Table = ({ invitations }: IProps): JSX.Element => {
  const { root } = useStyles();
  return (
    <div className={root}>
      {invitations?.map((invitation) => (
        <TableItem data={invitation} key={invitation.email} />
      ))}
    </div>
  );
};
