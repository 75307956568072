import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { SegmentService } from '../../../../../../../helpers/segmentService/segmentService';
import { AdminRegistrationStatus } from '../../../../../AdminRegistration/AdminRegistration';
import {
  ButtonsContainer,
  Container,
  PrimaryButton,
  SecondaryButton,
} from './ButtonSection.styles';

export type ButtonSectionProps = {
  setStatus: React.Dispatch<React.SetStateAction<AdminRegistrationStatus>>;
};

export const ButtonSection = ({ setStatus }: ButtonSectionProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleRedirectToAddNewUser = () => {
    setStatus('idle');
    SegmentService.adminUserInvitationRestarted();
  };

  const handleRedirectInitialWorkflow = () => {
    history.push('/');
    SegmentService.adminUserInvitationFinished();
  };

  return (
    <Container>
      <ButtonsContainer>
        <PrimaryButton onClick={handleRedirectToAddNewUser} data-testid="add-button">
          {formatMessage({ id: 'adminInvitationPage_resultPage_addNewUserButton' })}
        </PrimaryButton>
        <SecondaryButton onClick={handleRedirectInitialWorkflow} data-testid="ok-button">
          {formatMessage({ id: 'adminInvitationPage_resultPage_finishButton' })}
        </SecondaryButton>
      </ButtonsContainer>
    </Container>
  );
};
