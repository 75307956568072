import React, { FormEvent, KeyboardEvent, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { supportedCountries } from 'country-utils/src/entities';
import { Chip } from '@hexa-ui/components';
import { SupportedCountryKeys, formatCountry } from 'country-utils';
import { ControlledSelect } from '../../../../../components/ControlledSelect';
import { Stack } from '../../../../../components/Stack/Stack';
import {
  AddUserIcon,
  Card,
  FirstSection,
  FormGroup,
  IconWrapper,
  LineDivider,
  SecondSectionTitle,
  StyledButton,
  StyledForm,
  TitleContainer,
  UserDivider,
  UserInputsWrapper,
  UserSection,
} from '../../AdminForceRegistration.styles';
import { ForceFormFields } from '../RegistrationForceForm/RegistrationForceForm';
import { useUserSupportedCountries } from '../../../../../hooks/useIsGlobalManager/useIsGlobalManager';
import {
  ErrorMessage,
  InputChips,
  InputChipsWrapper,
  LabelChips,
  StyledFormHelperText,
  WrapperChipsVendorIds,
  WrapperVendorIds,
} from './FormAdminPortal.styles';

interface FormAdminPortalProps {
  onSubmit: (data: ForceFormFields) => void;
  permissionGroups: string[];
  isValidatingUserEmails: boolean[];
  isValidatingManagedByEmails: boolean[];
  validateUserEmail: (value: string, index: number) => Promise<void>;
  validateManagedByEmail: (value: string, index: number) => Promise<void>;
}

export const FormAdminPortal = ({
  onSubmit,
  permissionGroups,
  isValidatingUserEmails,
  isValidatingManagedByEmails,
  validateUserEmail,
  validateManagedByEmail: validateManagerByEmail,
}: FormAdminPortalProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
  } = useFormContext<ForceFormFields>();
  const loggedUserSupportedCountries = useUserSupportedCountries();
  const availableCountries = loggedUserSupportedCountries?.filter((country) =>
    Object.keys(supportedCountries).includes(country)
  );
  const { vendors } = getValues();
  const [inputChipValue, setInputChipValue] = useState('');
  const inputChipsRef = useRef(null);
  const toastService = useToast();
  const [forceVendorComponentUpdate, setForceVendorComponentUpdate] = useState(false);
  const onlyLettersPattern = /^[A-Za-z]+$/;

  const {
    fields: fieldsUser,
    remove: removeFieldUser,
    append: appendFieldUser,
  } = useFieldArray({
    control,
    name: 'users',
  });

  function addUserEmailInput() {
    const maxUserInputs = 10;
    const { users } = getValues();
    const shouldAppendNewRow = users.length < maxUserInputs;
    if (shouldAppendNewRow)
      appendFieldUser({ firstName: '', lastName: '', email: '' }, { shouldFocus: false });
  }

  function onChangeUserEmailInput(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    userFieldIndex: number
  ) {
    const { value } = event.target;
    setValue(`users.${userFieldIndex}.email`, value);

    validateUserEmail(value, userFieldIndex);

    const { users } = getValues();
    const isLastUserEmailInput = userFieldIndex === users.length - 1;
    if (isLastUserEmailInput) addUserEmailInput();
  }

  function renderRemoveButton(index: number) {
    const showRemoveButton = fieldsUser.length > 1;
    const isLastElement = index === fieldsUser.length - 1;

    return (
      <StyledButton
        show={isLastElement && showRemoveButton}
        data-testid={`remove-button-${index}`}
        onClick={() => removeFieldUser(index)}
        variant="outlined"
        margin="16px 0 0 0"
      >
        {formatMessage({
          id: 'adminInvitationPage_registrationForm_removeButton',
        })}
      </StyledButton>
    );
  }

  function renderUserEmailHelperText(index: number): JSX.Element | null {
    const isValidating = isValidatingUserEmails[index];
    if (isValidating) {
      return (
        <FormHelperText role="alert" style={{ marginLeft: 0, marginRight: 0 }}>
          {formatMessage({
            id: 'adminForceInvitationPage_registrationForm_inputEmail_validatingMessage',
          })}
        </FormHelperText>
      );
    }

    const hasError = Boolean(errors?.users?.[index]?.email);
    if (hasError) {
      return (
        <FormHelperText role="alert" style={{ marginLeft: 0, marginRight: 0 }}>
          {errors?.users?.[index]?.email?.message}
        </FormHelperText>
      );
    }

    return null;
  }

  function onChangeManagerEmailInput(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    userFieldIndex: number
  ) {
    const { value } = event.target;
    setValue(`users.${userFieldIndex}.managedBy`, value);
    validateManagerByEmail(value, userFieldIndex);

    const { users } = getValues();
    const isLastManagerEmailInput = userFieldIndex === users.length - 1;
    if (isLastManagerEmailInput) addUserEmailInput();
  }

  function handleAddVendor(vendor: string) {
    const oldVendorList = vendors;
    const isVendorAlreadyAdded =
      vendors?.length > 0 &&
      Array.isArray(oldVendorList) &&
      oldVendorList.some((oldVendor: string) => oldVendor === vendor);

    if (isVendorAlreadyAdded) {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({
          id: 'adminInvitationPage_registrationForm_vendorId_valueAlreadyExistError',
        }),
      });
    } else {
      let updatedVendorList: string[];

      if (vendors?.length > 0) {
        const vendorsField = vendors as string[];
        updatedVendorList = [...vendorsField, vendor];
      } else {
        updatedVendorList = [vendor];
      }

      setValue(`vendors`, updatedVendorList);

      setInputChipValue('');
    }
  }

  function handleRemoveVendor(vendor: string) {
    const updatedList: string[] = vendors.filter((filteredId: string) => filteredId !== vendor);

    setValue(`vendors`, updatedList);
    setForceVendorComponentUpdate((prev) => !prev);
  }

  function onChangeInputVendors(event: FormEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    if (value.length > 36) return;
    const trimmedValue = value.trim();
    const withoutSpacesValue = trimmedValue.replace(/\s/g, '');
    setInputChipValue(withoutSpacesValue);
  }

  async function onKeyDownInputVendors(event: KeyboardEvent<HTMLInputElement>) {
    if (['Enter', ',', 'Tab'].includes(event.key)) {
      event.preventDefault();
      const { value } = event.currentTarget;
      const isVendorValid = value.length === 36;
      const errorMessage = formatMessage({
        id: 'adminInvitationPage_registrationForm_vendorId_invalidVendorIdError',
      });

      if (isVendorValid) {
        clearErrors('vendors');
        handleAddVendor(value);
      } else {
        setError('vendors', { message: errorMessage });
      }
    }
  }

  function renderChipsVendorIds() {
    const vendorDataArray = vendors as string[];
    return vendorDataArray.map((vendor) => (
      <Chip.Root key={vendor}>
        <Chip.Label data-testid="chip-vendor">{vendor}</Chip.Label>
        <Chip.DeleteIcon type="button" onDelete={() => handleRemoveVendor(vendor)} />
      </Chip.Root>
    ));
  }

  function renderManagedByEmailHelperText(index: number): JSX.Element | null {
    const isValidating = isValidatingManagedByEmails[index];
    if (isValidating) {
      return (
        <FormHelperText role="alert" style={{ marginLeft: 0, marginRight: 0 }}>
          {formatMessage({
            id: 'adminForceInvitationPage_registrationForm_inputEmail_validatingMessage',
          })}
        </FormHelperText>
      );
    }
    const hasError = Boolean(errors?.users?.[index]?.managedBy);
    if (hasError) {
      return (
        <FormHelperText role="alert" style={{ marginLeft: 0, marginRight: 0 }}>
          {errors?.users?.[index]?.managedBy?.message}
        </FormHelperText>
      );
    }
    return null;
  }

  return (
    <Card data-testid="usertype-adminportal-form">
      <TitleContainer>
        {formatMessage({
          id: 'adminInvitationPage_registrationForm_card_title',
        })}
      </TitleContainer>
      <LineDivider />

      <StyledForm onSubmit={handleSubmit(onSubmit)} id="usersForm">
        <FirstSection>
          <ControlledSelect
            controllerProps={{
              control,
              name: 'permissionGroups',
              rules: { required: 'Required' },
            }}
            autocompleteProps={{
              fullWidth: true,
              multiple: true,
              closeIcon: null,
              disableCloseOnSelect: true,
              options: permissionGroups,
              ChipProps: { size: 'small' },
            }}
            textFieldProps={{
              label: formatMessage({
                id: 'adminInvitationPage_registrationForm_permissionGroup_inputLabel',
              }),
              placeholder: formatMessage({
                id: 'adminInvitationPage_registrationForm_permissionGroup_inputPlaceholder',
              }),
              style: { borderRadius: '12px' },
            }}
          />

          <ControlledSelect
            data-testid="country-select-field"
            controllerProps={{
              control,
              name: 'country',
              rules: { required: 'Required' },
            }}
            autocompleteProps={{
              fullWidth: true,
              closeIcon: null,
              options: availableCountries || [],
              getOptionLabel: (option) => formatCountry(option as SupportedCountryKeys),
            }}
            textFieldProps={{
              label: formatMessage({
                id: 'adminInvitationPage_registrationForm_country_inputLabel',
              }),
              placeholder: formatMessage({
                id: 'adminInvitationPage_registrationForm_country_inputPlaceholder',
              }),
              style: { borderRadius: '12px' },
            }}
          />

          <ControlledSelect
            data-testid="supportedCountries"
            controllerProps={{
              control,
              name: 'supportedCountries',
              rules: { required: 'Required' },
            }}
            autocompleteProps={{
              fullWidth: true,
              multiple: true,
              closeIcon: null,
              disableCloseOnSelect: true,
              options: availableCountries || [],
              ChipProps: { size: 'small' },
              getOptionLabel: (option) => formatCountry(option as SupportedCountryKeys),
            }}
            textFieldProps={{
              label: formatMessage({
                id: 'adminInvitationPage_registrationForm_supportedCountries_inputLabel',
              }),
              placeholder: formatMessage({
                id: 'adminInvitationPage_registrationForm_supportedCountries_inputPlaceholder',
              }),
              style: { borderRadius: '12px' },
            }}
          />

          <WrapperVendorIds data-testid="vendorId">
            <LabelChips htmlFor="vendorId">
              <WrapperChipsVendorIds elevated="minimal" border="small">
                {vendors?.length > 0 && renderChipsVendorIds()}

                <InputChipsWrapper>
                  <InputChips
                    data-testid="vendorId-input"
                    ref={inputChipsRef}
                    id="vendorId"
                    placeholder={formatMessage({
                      id: 'adminInvitationPage_registrationForm_vendorId_inputPlaceholder',
                    })}
                    value={inputChipValue}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={onChangeInputVendors}
                    // eslint-disable-next-line react/jsx-no-bind
                    onKeyDown={onKeyDownInputVendors}
                  />
                </InputChipsWrapper>
              </WrapperChipsVendorIds>
            </LabelChips>
          </WrapperVendorIds>
          {Boolean(errors?.vendors) && (
            <ErrorMessage data-testid="error-vendor-message" role="alert">
              {errors?.vendors?.message}
            </ErrorMessage>
          )}
        </FirstSection>

        <SecondSectionTitle>
          {formatMessage({
            id: 'adminInvitationPage_registrationForm_secondSectionTitle',
          })}
        </SecondSectionTitle>
        <UserSection>
          {fieldsUser.map((fieldUser, fieldUserIndex) => {
            return (
              <div key={fieldUser.id}>
                <Stack orientation="row" spacing="16px" align="start" fullWidth={[1]}>
                  <IconWrapper>
                    <AddUserIcon />
                  </IconWrapper>

                  <UserInputsWrapper>
                    <FormGroup>
                      <FormControl
                        error={Boolean(errors?.users?.[fieldUserIndex]?.email)}
                        variant="outlined"
                        size="small"
                        style={{ flexGrow: 1 }}
                      >
                        <TextField
                          variant="outlined"
                          data-testid={`user-email-${fieldUserIndex}`}
                          id={`user-email-${fieldUserIndex}`}
                          error={Boolean(errors?.users?.[fieldUserIndex]?.email)}
                          {...register(`users.${fieldUserIndex}.email` as const, {
                            required:
                              fieldUserIndex < fieldsUser.length - 1 || fieldsUser.length === 1
                                ? formatMessage({
                                    id: 'adminInvitationPage_registrationForm_inputEmail_errorMessage',
                                  })
                                : false,
                            onChange: (value) => onChangeUserEmailInput(value, fieldUserIndex),
                          })}
                          size="small"
                          type="text"
                          placeholder={formatMessage({
                            id: 'adminInvitationPage_registrationForm_inputEmail_placeholder',
                          })}
                          inputProps={{ 'aria-label': 'E-mail' }}
                        />
                        {renderUserEmailHelperText(fieldUserIndex)}
                      </FormControl>

                      <FormControl
                        variant="outlined"
                        size="small"
                        error={Boolean(errors?.users?.[fieldUserIndex]?.firstName)}
                      >
                        <TextField
                          id={`user-name-${fieldUserIndex}`}
                          data-testid={`user-name-${fieldUserIndex}`}
                          size="small"
                          variant="outlined"
                          {...register(`users.${fieldUserIndex}.firstName` as const, {
                            required:
                              fieldUserIndex < fieldsUser.length - 1 || fieldsUser.length === 1
                                ? formatMessage({
                                    id: 'adminInvitationPage_registrationForm_inputName_errorMessage',
                                  })
                                : false,
                            pattern: {
                              value: onlyLettersPattern,
                              message: formatMessage({
                                id: 'adminInvitationPage_registrationForm_validateTextFields_errorMessageAllowOnlyLetters',
                              }),
                            },
                          })}
                          type="text"
                          placeholder={formatMessage({
                            id: 'adminInvitationPage_registrationForm_inputName_placeholder',
                          })}
                          inputProps={{ 'aria-label': 'First name' }}
                          error={Boolean(errors?.users?.[fieldUserIndex]?.firstName)}
                        />

                        {Boolean(errors?.users?.[fieldUserIndex]?.firstName) && (
                          <StyledFormHelperText
                            data-testid={`error-user-name-${fieldUserIndex}`}
                            role="alert"
                          >
                            {errors?.users?.[fieldUserIndex]?.firstName?.message}
                          </StyledFormHelperText>
                        )}
                      </FormControl>

                      <FormControl
                        variant="outlined"
                        size="small"
                        error={Boolean(errors?.users?.[fieldUserIndex]?.lastName)}
                      >
                        <TextField
                          id={`user-last-name-${fieldUserIndex}`}
                          data-testid={`user-last-name-${fieldUserIndex}`}
                          size="small"
                          variant="outlined"
                          {...register(`users.${fieldUserIndex}.lastName` as const, {
                            required:
                              fieldUserIndex < fieldsUser.length - 1 || fieldsUser.length === 1
                                ? formatMessage({
                                    id: 'adminInvitationPage_registrationForm_inputLastName_errorMessage',
                                  })
                                : false,
                            pattern: {
                              value: onlyLettersPattern,
                              message: formatMessage({
                                id: 'adminInvitationPage_registrationForm_validateTextFields_errorMessageAllowOnlyLetters',
                              }),
                            },
                          })}
                          type="text"
                          placeholder={formatMessage({
                            id: 'adminInvitationPage_registrationForm_inputLastName_placeholder',
                          })}
                          inputProps={{ 'aria-label': 'Last name' }}
                          error={Boolean(errors?.users?.[fieldUserIndex]?.lastName)}
                        />

                        {Boolean(errors?.users?.[fieldUserIndex]?.lastName) && (
                          <StyledFormHelperText
                            data-testid={`error-user-last-name-${fieldUserIndex}`}
                            role="alert"
                          >
                            {errors?.users?.[fieldUserIndex]?.lastName?.message}
                          </StyledFormHelperText>
                        )}
                      </FormControl>
                    </FormGroup>

                    <FormGroup>
                      <FormControl
                        error={Boolean(errors?.users?.[fieldUserIndex]?.managedBy)}
                        variant="outlined"
                        size="small"
                        style={{ flexGrow: 1 }}
                      >
                        <TextField
                          variant="outlined"
                          data-testid={`managed-by-${fieldUserIndex}`}
                          id={`managed-by-${fieldUserIndex}`}
                          error={Boolean(errors?.users?.[fieldUserIndex]?.managedBy)}
                          {...register(`users.${fieldUserIndex}.managedBy` as const, {
                            required:
                              fieldUserIndex < fieldsUser.length - 1 || fieldsUser.length === 1
                                ? formatMessage({
                                    id: 'adminInvitationPage_registrationForm_inputEmail_errorMessage',
                                  })
                                : false,
                            onChange: (value) => onChangeManagerEmailInput(value, fieldUserIndex),
                          })}
                          size="small"
                          type="text"
                          placeholder={formatMessage({
                            id: 'adminInvitationPage_registrationForm_managedBy_placeholder',
                          })}
                          inputProps={{ 'aria-label': 'Managed By' }}
                        />
                        {renderManagedByEmailHelperText(fieldUserIndex)}
                      </FormControl>
                    </FormGroup>
                  </UserInputsWrapper>

                  {renderRemoveButton(fieldUserIndex)}
                </Stack>

                {fieldUserIndex !== fieldsUser.length - 1 && <UserDivider />}
              </div>
            );
          })}
        </UserSection>
      </StyledForm>
    </Card>
  );
};
